import { ToastrService } from 'ngx-toastr';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { GenerateShippingLabelComponent } from './generate-shipping-label/generate-shipping-label.component';
import { BoxesNeededForEachSplit, LabelRecord } from './../../Services/Object_Classes/PackingList/LabelRecord';
import { DowntimeDetailsComponent } from './downtime-details/downtime-details.component';
import { ScheduleTracker } from './../../Services/Object_Classes/Machine/Machine';
import { AddPOComponent } from './add-po/add-po.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { ShippingInformationComponent } from './shipping-information/shipping-information.component';
import { RawCheckOutInformationComponent } from './raw-check-out-information/raw-check-out-information.component';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from '../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { AngularFireDatabase } from '@angular/fire/database';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { MatPaginator, PageEvent, Sort } from '@angular/material';
import { OrdersComponent } from '../raw/PO/orders/orders.component';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { AddCustomerComponent } from './Customer/add-customer/add-customer.component';
import { AngularFireAuth } from '@angular/fire/auth';
import { UserInfoService } from 'src/app/Services/Utilities/user-info.service';
import { DetailPOPageComponent } from './detail-po/detail-po-page.component';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { PackingList_PO_List, PackingList, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { AddPackingComponent } from '../invoice/add-packing/add-packing.component';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { GenerateOldLabelComponent } from '../invoice/generate-old-label/generate-old-label.component';
import { GenerateQRCodeDialogComponent } from '../invoice/generate-qrcode-dialog/generate-qrcode-dialog.component';
import { UpdatePackingComponent } from '../invoice/update-packing/update-packing.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import jsPDF from 'jspdf';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';

@Component({
  selector: 'app-purchaseOrder',
  templateUrl: './purchaseOrder.component.html',
  styleUrls: ['./purchaseOrder.component.css']
})
export class PurchaseOrderComponent implements OnInit {

  POno: string;
  partNo: string;
  POquantity: number;
  CustomerDB_controller: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  email: string;

  POlist= [];
  ClonePOList= [];
  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: '',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  //Pagination Customer
  lengthcus = 0;
  pageSizecus = 10;
  pageSizeOptionscus: number[] = [10, 25, 50];
  pageIndexcus = 0;
  offsetcus = this.pageSizecus * this.pageIndexcus;
  searchcus: string;
  sorteducus = {
    active: '',
    direction: ''
  };
  Customer = [];
  CloneCustomer = [];
  @ViewChild('topPaginatorcus', { read: MatPaginator, static: false }) topPaginatorcus: MatPaginator;
  @ViewChild('bottomPaginatorcus', { read: MatPaginator, static: false }) bottomPaginatorcus: MatPaginator;


  //Pagination Machine job
  lengthJob = 0;
  pageSizeJob = 10;
  pageSizeOptionsJob: number[] = [10, 25, 50];
  pageIndexJob = 0;
  offsetJob = this.pageSizeJob * this.pageIndexJob;
  searchJob: string;
  sorteduJob = {
    active: '',
    direction: ''
  };
  Job= [];
  CloneJob = [];
  @ViewChild('topPaginatorJob', { read: MatPaginator, static: false }) topPaginatorJob: MatPaginator;
  @ViewChild('bottomPaginatorJob', { read: MatPaginator, static: false }) bottomPaginatorJob: MatPaginator;

  //Pagination Machine Label
  lengthLabel = 0;
  pageSizeLabel = 10;
  pageSizeOptionsLabel: number[] = [10, 25, 50];
  pageIndexLabel = 0;
  offsetLabel = this.pageSizeLabel * this.pageIndexLabel;
  searchLabel: string;
  sorteduLabel = {
    active: '',
    direction: ''
  };
  Label: LabelRecord[] = [];
  CloneLabel: LabelRecord[] = [];
  @ViewChild('topPaginatorLabel', { read: MatPaginator, static: false }) topPaginatorLabel: MatPaginator;
  @ViewChild('bottomPaginatorLabel', { read: MatPaginator, static: false }) bottomPaginatorLabel: MatPaginator;



  PODB_controller: PODB_controller = new PODB_controller(this.db);
  machineDB_controller = new MachineDB_controller(this.db);

  machinelist = []
  role: string;
  acccessRight: any[] = [];
  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private angularFireAuth: AngularFireAuth,
    private excelHelper: ExcelHelperService,
    private firestore: AngularFirestore,
    private userinfoSrv: UserInfoService,
    private toast: ToastrService,
    private partService: PartServices,
    private dateFormat: DateFormatService,

  ) {
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.userinfoSrv.currentRole.subscribe(role => {
      this.role = role
      if (this.userinfoSrv.checkPermission(this.role, 'PO Management', this.acccessRight)) {
        this.disableDelete = false;
      }
    });
    this.userinfoSrv.currentAccessRights.subscribe(access => {
      this.acccessRight = access
      if (this.userinfoSrv.checkPermission(this.role, 'PO Management', this.acccessRight)) {
        this.disableDelete = false;
      }
    });
  }
  disableDelete = true;


  ngOnInit() {
    this.initcus();
    this.initJob();
    this.initPO();
    // this.initPacking();
    // this.initRecord();
  }

  refresh(value) {
    switch (value) {
      case 'po': this.initPO(); break;
      case 'customer': this.initcus(); break;
      case 'job': this.initJob(); break;
    }
  }

  initRecord() {
    this.packing_controller.getRecord().then(data => {
      this.Label = data;
      this.lengthLabel = this.Label.length;
      this.CloneLabel = this.Label.slice();
      this.limitListLabel();
    });
  }

  poStatus: string = "";
  setup() {
    this.spinner.show();
    this.machinelist = [];
    const db = this.db.database.ref('Machine/');
    db.once('value', (snapshot) => {
      snapshot.forEach(v => {
        this.machinelist.push(v.key);
      });
    });
    this.spinner.hide()
  }

  initPO() {
    this.POlist = [];
    this.firestore.collection("PurchaseOrders", ref=> ref.orderBy('createdDate','desc')).get().forEach(po=>{
      if(!po.empty){
        po.forEach(popo=>{
          const data = {
            id: popo.id,
            ...popo.data(),
            createdDate: popo.data().createdDate.toDate(),
            updatedDate: popo.data().updatedDate.toDate(),
            parts: []
          }
          this.firestore.collection("PurchaseOrders").doc(popo.id).collection("Parts").get().forEach(part=>{
            if(!part.empty){
              part.forEach(partt=>{
                data.parts.push({
                  id: partt.id,
                  ...partt.data()
                })
              })
            }
          })
         
          this.POlist.push(data);
        })
        this.length = this.POlist.length;
        this.ClonePOList = this.POlist.slice();
        this.limitList();
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
    })
  }

  check(PO){
    if(PO.parts.find(e=>e.status !== 'Waiting'))
      return true;
    return false;
  }

  initcus() {
    this.Customer = [];
    this.firestore.collection("Customers", ref=> ref.where('isDeleted', '==', false).orderBy('customerName','asc')).get().forEach(part=>{
      if(!part.empty){
        part.forEach(partt=>{
          const data = {
            id: partt.id,
            ...partt.data(),
            createdDate: partt.data().createdDate.toDate(),
            updatedDate: partt.data().updatedDate.toDate()
          }
          this.Customer.push(data);
        })
        this.lengthcus = this.Customer.length;
        this.CloneCustomer = this.Customer.slice();
        this.limitListcus();
        this.spinner.hide();
      }
      else{
        this.spinner.hide();
      }
    })
  }

  initJob() {
    this.Job = [];
    this.firestore.collection("Machines").get().forEach(po=>{
      if(!po.empty){
        po.forEach(popo=>{
          this.firestore.collection("Machines").doc(popo.id).collection("Schedule_Done").get().forEach(part=>{
            if(!part.empty){
              part.forEach(partt=>{
                const data = {
                  id: partt.id,
                  ...partt.data(),
                  startDate: partt.data().startDate.toDate(),
                  endDate: partt.data().endDate.toDate(),
                }
                this.Job.push(data);
                this.lengthJob = this.Job.length;
                this.CloneJob = this.Job.slice();
                this.limitListJob();
                this.spinner.hide();
              })
            }
          })
        })
      
      }
      else{
        this.spinner.hide();
      }
    })

  }

  deletePO(POnumber) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this PO? This will affect the schedule';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection("PurchaseOrders").doc(POnumber.id).delete().then(()=>{
          for (const part of POnumber.parts) {
            this.firestore.collection("Machines").doc(part.machineNo).collection('Schedule_Queue').doc(part.id).delete();
          }
          this.toast.success(POnumber.jobOrderNo + " has been deleted successfully!", "Deleted Successfully")
        }).catch(error=>{
          this.toast.error(POnumber.jobOrderNo + " has been deleted failed! Please try to contact administrator.", "Deleted Failed")
        }).finally(async ()=>{
          this.setup();
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            deletedBy: this.email,
            jobOrderNo: POnumber.jobOrderNo
          };
          await this.firestore.collection('DeletePOLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('DeletePOLog').doc(dateFormat).collection('PurchaseOrders').add(info);
        });
      }
    })

  }

  filterStatus() {
    if (this.search)
      this.DS();
    else
      this.ClonePOList = this.POlist.slice();

    this.ClonePOList = this.ClonePOList.filter(u => {
      for (const part of u.parts) {
        if ((part.status.includes(this.poStatus)))
          return true;
      }
      return false
    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }

  fs() {
    this.ClonePOList = this.ClonePOList.filter(u => {
      for (const part of u.parts) {
        if ((part.status.includes(this.poStatus)))
          return true;
      }
      return false
    });
  }

  DynamicSearchPO(partNum: string): void {
    this.search = partNum;
    this.ClonePOList = this.POlist.filter(u => {
      const flag = String(u.jobOrderNo).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.parts) {
        if ((part.itemCode.toLowerCase().includes(this.search.toLowerCase()) 
         || (part.status.toLowerCase().includes(this.search.toLowerCase()))
         || (part.machineNo.toLowerCase().includes(this.search.toLowerCase()))
         || (part.reference.toLowerCase().includes(this.search.toLowerCase()))
         || (part.stockCode.toLowerCase().includes(this.search.toLowerCase()))
        ))
            return true;
      }
      return flag
    });
    this.length = this.ClonePOList.length;
    this.sortPOList();
    this.limitList();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }
  DS() {
    this.ClonePOList = this.POlist.filter(u => {
      const flag = String(u.jobOrderNo).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.parts) {
        if ((part.itemCode.toLowerCase().includes(this.search.toLowerCase()) 
         || (part.status.toLowerCase().includes(this.search.toLowerCase()))
         || (part.machineNo.toLowerCase().includes(this.search.toLowerCase()))
         || (part.reference.toLowerCase().includes(this.search.toLowerCase()))
         || (part.stockCode.toLowerCase().includes(this.search.toLowerCase()))
        ))
            return true;
      }
      return flag
    });
    this.length = this.ClonePOList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.ClonePOList = this.POlist.slice();
    }
    this.fs();
    this.sortPOList();
    this.limitList();
  }
  limitList() {
    this.ClonePOList = this.ClonePOList.slice(this.offset, (this.offset + this.pageSize));
  }

  sortPOList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.jobOrderNo, b.jobOrderNo, isAsc);
        case 'Created': return this.compareDate(a.createdDate, b.createdDate, isAsc);
        default: return 0;
      }
    });
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.ClonePOList = this.POlist.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.ClonePOList = this.POlist.slice();
      this.limitList();
      return;
    }

    this.ClonePOList = this.ClonePOList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNO': return this.compare(a.jobOrderNo, b.jobOrderNo, isAsc);
        case 'Created': return this.compareDate(a.createdDate, b.createdDate, isAsc);
        default: return 0;
      }
    });
    this.limitList();
  }
  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  ViewShippingInformation(part) {
    this.dialog.open(ShippingInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }
  ViewCheckOut(part) {
    this.dialog.open(RawCheckOutInformationComponent, {
      width: '80%',
      height: '80%',
      data: part
    });
  }



  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewOrder(po) {
    this.dialog.open(OrdersComponent, {
      width: '90%',
      height: '90%',
      disableClose: true,
      data: po
    });
  }

  DynamicSearchRawcus(cusname: string): void {
    this.searchcus = cusname;
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.customerName).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
    this.sortRawListcus();
    this.limitListcus();
    this.topPaginatorcus.firstPage()
    this.bottomPaginatorcus.firstPage()
  }
  DScus() {
    this.CloneCustomer = this.Customer.filter(u =>
      String(u.customerName).toLowerCase().includes(this.searchcus.toLowerCase()));
    this.lengthcus = this.CloneCustomer.length;
  }

  paginatorcus(pageEvent: PageEvent) {
    this.pageSizecus = pageEvent.pageSize;
    this.pageIndexcus = pageEvent.pageIndex;
    this.offsetcus = this.pageSizecus * this.pageIndexcus;
    if (this.topPaginatorcus.pageIndex < this.pageIndexcus) {
      this.topPaginatorcus.nextPage();
    } else if (this.topPaginatorcus.pageIndex > this.pageIndexcus) {
      this.topPaginatorcus.previousPage();
    }
    if (this.bottomPaginatorcus.pageIndex < this.pageIndexcus) {
      this.bottomPaginatorcus.nextPage();
    } else if (this.bottomPaginatorcus.pageIndex > this.pageIndexcus) {
      this.bottomPaginatorcus.previousPage();
    }
    if (this.searchcus) {
      this.DScus();
    } else {
      this.CloneCustomer = this.Customer.slice();
    }
    this.sortRawListcus();
    this.limitListcus();
  }
  limitListcus() {
    this.CloneCustomer = this.CloneCustomer.slice(this.offsetcus, (this.offsetcus + this.pageSizecus));
  }
  sortRawListcus() {
    if (!this.sorteducus.active || this.sorteducus.direction === '') {
      return;
    }
    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.customerName, b.customerName, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'currency': return this.compare(a.currency, b.currency, isAsc);
        case 'contact': return this.compare(a.phone, b.phone, isAsc);
        case 'email': return this.compare(a.email, b.email, isAsc);
        default: return 0;
      }
    });
  }
  sortDatacus(sort: Sort) {
    this.sorteducus = sort;
    this.CloneCustomer = this.Customer.slice();
    if (this.searchcus) {
      this.DScus();
    }
    if (!sort.active || sort.direction === '' && !this.searchcus) {
      this.CloneCustomer = this.Customer.slice();
      this.limitListcus();
      return;
    }

    this.CloneCustomer = this.CloneCustomer.sort((a, b) => {
      const isAsc = this.sorteducus.direction === 'asc';
      switch (this.sorteducus.active) {
        case 'name': return this.compare(a.customerName, b.customerName, isAsc);
        case 'address': return this.compare(a.address, b.address, isAsc);
        case 'currency': return this.compare(a.currency, b.currency, isAsc);
        case 'contact': return this.compare(a.phone, b.phone, isAsc);
        case 'email': return this.compare(a.email, b.email, isAsc);
        default: return 0;
      }
    });
    this.limitListcus();
  }

  ViewCustomer(Customer: any) {

    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,
      data: { ...Customer }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {

        result.updatedDate = new Date();
        result.updatedBy = this.email;
        this.spinner.show();
     
        this.firestore.collection("Customers").doc(result.id).update(result).then(() => {
          this.toast.success(result.customerName + " has been updated successfully!", "Updated Successfully")
        }).catch(error => {
          this.toast.error(result.customerName + " has been updated failed! Please try to contact administrator.", "Added Failed")
        }).finally(async () => {
          this.initcus();

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            content: JSON.stringify(result),
            customerName: result.customerName,
            addedBy: this.email,
          };
          await this.firestore.collection('UpdateCustomerLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('UpdateCustomerLog').doc(dateFormat).collection('Customer').add(info);
        });
      }
    });
  }


  AddCustomer() {
    const dialogRef = this.dialog.open(AddCustomerComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true
    });
    dialogRef.afterClosed().subscribe(result => {
       if (result) {
        result.createdDate = new Date();
        result.createdBy = this.email;
        result.updatedDate = new Date();
        result.updatedBy = this.email;
        result.isDeleted = false;
        this.spinner.show();
     
        this.firestore.collection("Customers").add(result).then(() => {
          this.toast.success(result.customerName + " has been added successfully!", "Added Successfully")
        }).catch(error => {
          this.toast.error(result.customerName + " has been added failed! Please try to contact administrator.", "Added Failed")
        }).finally(async () => {
          this.initcus();

          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            content: JSON.stringify(result),
            customerName: result.customerName,
            addedBy: this.email,
          };
          await this.firestore.collection('AddCustomerLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('AddCustomerLog').doc(dateFormat).collection('Customer').add(info);
        });
      }
    });
  }
  deletecus(cus: any) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    dialogConfig.data = 'Do you really want to delete this Customer? ' + cus.customerName;

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection("Customers").doc(cus.id).update({isDeleted: true}).then(()=>{
          this.toast.success(cus.customerName + " has been deleted successfully!", "Deleted Successfully")
        }).catch(error=>{
          this.toast.error(cus.customerName + " has been deleted failed! Please try to contact administrator.", "Deleted Failed")
        }).finally(async ()=>{
          this.initcus();
          const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
          const info = {
            date: new Date(),
            content:JSON.stringify(cus),
            deletedBy: this.email,
            customerName: cus.customerName
          };
          await this.firestore.collection('DeleteCustomerLog').doc(dateFormat).set({ Date: new Date() });
          await this.firestore.collection('DeleteCustomerLog').doc(dateFormat).collection('Customer').add(info);

      })
    }})
  }

  AddPO() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddPOComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    })
  }

  viewDetail(PO) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = PO;
    this.dialog.open(DetailPOPageComponent, dialogConfig).afterClosed().subscribe(result => {
      this.refresh('po');
    })
  }

  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();

    for (const po of this.POlist) {
      const info = {
        "PO": po.PO_No || "-",
        "Part List": po.PO_Part_List.map(a => a.PO_Part_No).join('  |  '),
        "Part List Name": po.PO_Part_List.map(a => a.PO_Part_Name).join(' |  '),
        "Production Quantity": po.PO_Part_List.map(a => a.PO_Part_Qty).join(' |  '),
        "PO Quantity": po.PO_Part_List.map(a => a.POQuantity).join(' |  '),
        "Reference": po.PO_Part_List.map(a => a.Reference).join(' |  '),
        "POS": po.PO_Part_List.map(a => a.POS).join(' |  '),
        "Status": po.PO_Part_List.map(a => a.PO_Status).join(' |  '),
        "Created Date": po.Created_Date
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO' + new Date().getTime());
    this.spinner.hide();

  }

  exportToExcelCustomer() {
    const exportInformation = [];
    this.spinner.show();
    const cuss = this.Customer.sort((a, b) => {
      return (a.Customer_Name < b.Customer_Name ? -1 : 1) * (true ? 1 : -1);
    }
    );
    for (const cus of cuss) {
      const info = {
        "Client name": cus.Customer_Name || "-",
        "Address": cus.Address || "-",
        "Contact": cus.Contact || "-",
        "Currency": cus.Currency || "-",
        "Email": cus.Email || "-",
        "Person In Charge": cus.Person_In_Charge || "-",
        "Created Date": cus.Created_Date || "-",
      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client' + new Date().getTime());
    this.spinner.hide();

  }

  msToTime(end, start) {
    let duration = parseFloat(end) - parseFloat(start);
    if (duration > 0) {
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      let hrs = (hours < 10) ? "0" + hours : hours;
      let min = (minutes < 10) ? "0" + minutes : minutes;
      let sec = (seconds < 10) ? "0" + seconds : seconds;
      return hrs + ":" + min + ":" + sec;
    }
    return 0;

  }

  hoursDisplay(hr) {
    if (hr > 0) {
      let min = hr - parseFloat(hr.toString().split('.')[0])
      min = min * 60
      let sec = min - parseFloat(min.toString().split('.')[0])
      sec = sec * 60;
      let hrs1 = (hr < 10) ? "0" + hr.toString().split('.')[0] : hr.toString().split('.')[0];
      let min1 = (min < 10) ? "0" + min.toString().split('.')[0] : min.toString().split('.')[0];
      let sec1 = (sec < 10) ? "0" + sec.toString().split('.')[0] : sec.toString().split('.')[0];
      return hrs1 + ":" + min1 + ":" + sec1;
    }
    return 0;
  }

  DynamicSearchJob(job: string): void {
    this.searchJob = job;
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.jobOrderNo).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.itemCode).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.cycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
    );
    this.lengthJob = this.CloneJob.length;
    this.sortJob();
    this.limitListJob();
    this.topPaginatorJob.firstPage()
    this.bottomPaginatorJob.firstPage()
  }
  DSJob() {
    this.CloneJob = this.Job.filter(u =>
      String(u.Machine_Number).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.jobOrderNo).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.itemCode).toLowerCase().includes(this.searchJob.toLowerCase())
      || String(u.cycleTime).toLowerCase().includes(this.searchJob.toLowerCase())
    );
    this.lengthJob = this.CloneJob.length;

  }
  paginatorJob(pageEvent: PageEvent) {
    this.pageSizeJob = pageEvent.pageSize;
    this.pageIndexJob = pageEvent.pageIndex;
    this.offsetJob = this.pageSizeJob * this.pageIndexJob;
    if (this.topPaginatorJob.pageIndex < this.pageIndexJob) {
      this.topPaginatorJob.nextPage();
    } else if (this.topPaginatorJob.pageIndex > this.pageIndexJob) {
      this.topPaginatorJob.previousPage();
    }
    if (this.bottomPaginatorJob.pageIndex < this.pageIndexJob) {
      this.bottomPaginatorJob.nextPage();
    } else if (this.bottomPaginatorJob.pageIndex > this.pageIndexJob) {
      this.bottomPaginatorJob.previousPage();
    }
    if (this.searchJob) {
      this.DSJob();
    } else {
      this.CloneJob = this.Job.slice();
    }
    this.sortJob();
    this.limitListJob();
  }
  limitListJob() {
    this.CloneJob = this.CloneJob.slice(this.offsetJob, (this.offsetJob + this.pageSizeJob));
  }
  sortJob() {
    if (!this.sorteduJob.active || this.sorteduJob.direction === '') {
      return;
    }
    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.jobOrderNo, b.jobOrderNo, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'PredefinedCycle': return this.compare(a.cycleTime, b.cycleTime, isAsc);
        case 'startTime': return this.compareDate(a.startDate, b.startDate, isAsc);
        case 'endTime': return this.compareDate(a.endDate, b.endDate, isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
        default: return 0;
      }
    });
  }
  sortDataJob(sort: Sort) {
    this.sorteduJob = sort;
    this.CloneJob = this.Job.slice();
    if (this.searchJob) {
      this.DSJob();
    }
    if (!sort.active || sort.direction === '' && !this.searchJob) {
      this.CloneJob = this.Job.slice();
      this.limitListJob();
      return;
    }

    this.CloneJob = this.CloneJob.sort((a, b) => {
      const isAsc = this.sorteduJob.direction === 'asc';
      switch (this.sorteduJob.active) {
        case 'po': return this.compare(a.jobOrderNo, b.jobOrderNo, isAsc);
        case 'machine': return this.compare(Number(a.Machine_Number.match(/(\d+)/g)[0]), Number(b.Machine_Number.match(/(\d+)/g)[0]), isAsc);
        case 'part': return this.compare(a.itemCode, b.itemCode, isAsc);
        case 'PredefinedCycle': return this.compare(a.cycleTime, b.cycleTime, isAsc);
        case 'startTime': return this.compareDate(a.startDate, b.startDate, isAsc);
        case 'endTime': return this.compareDate(a.endDate, b.endDate, isAsc);
        case 'downTime': return this.compare(a.TotalDownTime, b.TotalDownTime, isAsc);
       default: return 0;
      }
    });
    this.limitListJob();
  }

  exportToExcelJob() {
    const exportInformation = [];
    this.spinner.show();
    const jo = this.Job.sort((a, b) => {
      return (Number(a.Machine_Number.match(/(\d+)/g)[0]) - Number((b.Machine_Number.match(/(\d+)/g)[0])));
    })
    for (const j of jo) {
      const info = {
        "Purchase Order": j.Machine_Schedule_PO_No || "-",
        "Machine": j.Machine_Number || "-",
        "Part No": j.Machine_Schedule_Part_No || "-",
        "Part Name": j.PartName || "-",
        "Cycle Time": j.CycleTime || "-",
        "Start Time": j.DateFormatStart || "-",
        "End Time": j.DateFormatEnd || "-",
        "Presetting Time": this.msToTime(j.DatePresettingEnd, j.DatePresettingStart) || "-",
        "Downtime Time": this.hoursDisplay(j.TotalDownTime) || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'Client' + new Date().getTime());
    this.spinner.hide();

  }

  view(job: ScheduleTracker) {
    this.dialog.open(DowntimeDetailsComponent, {
      width: '80%',
      height: 'auto',
      data: job.DownTimeDetail,
      disableClose: true
    });

  }



  //Pagination
  lengthPL = 0;
  pageSizePL = 10;
  pageSizeOptionsPL: number[] = [10, 25, 50];
  pageIndexPL = 0;
  offsetPL = this.pageSizePL * this.pageIndexPL;
  searchPL: string;
  sorteduPL = {
    active: '',
    direction: ''
  };

  ClonePackingPOList: PackingList_PO_List[] = [];
  ClonePackingList: PackingList[] = [];

  @ViewChild('topPaginatorPL', { read: MatPaginator, static: false }) topPaginatorPL: MatPaginator;
  @ViewChild('bottomPaginatorPL', { read: MatPaginator, static: false }) bottomPaginatorPL: MatPaginator;

  packingFormat: any = /^PKG\d{3}-\d{6}/;
  tempPackingNo: string = "testing";
  packing_controller = new PackingListDB_controller(this.db,this.firestore,this.partService);
  packingPartList: PackingList_PO_Part_List[] = [];
  packingPOList: PackingList_PO_List[] = [];
  packingList: PackingList[] = [];

  addPacking() {
    this.generatePackingNumber();
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.tempPackingNo;
    this.dialog.open(AddPackingComponent, dialogConfig).afterClosed().subscribe(result => {
      this.setup();
    });
  }
  generatePackingNumber() {
    if (this.packingList.length > 0) {
      const clone = this.packingList.filter(p => p.PackingList_No.includes('PKG'));
      let result = null
      if (clone.length > 0) {
        result = clone[clone.length - 1].PackingList_No.match(this.packingFormat);
      }
      let mid3entries = "000";
      let last6entries = "000000";
      if (result) {
        let last6digits = parseInt(result[0].substring(7, 13));
        if (last6digits < 999999) {
          last6digits = last6digits + 1;
          if (last6digits < 10) {
            last6entries = "00000" + last6digits.toString();
          }
          else if (last6digits < 100) {
            last6entries = "0000" + last6digits.toString();
          }
          else if (last6digits < 1000) {
            last6entries = "000" + last6digits.toString();
          }
          else if (last6digits < 10000) {
            last6entries = "00" + last6digits.toString();
          }
          else if (last6digits < 100000) {
            last6entries = "0" + last6digits.toString();
          }
          else if (last6digits < 1000000) {
            last6entries = last6digits.toString();
          }

          let mid3digits = parseInt(result[0].substring(3, 6));
          if (last6digits == 999999) {
            if (mid3digits < 999) {
              mid3digits = mid3digits + 1;
              if (mid3digits < 10) {
                mid3entries = "00" + mid3digits.toString();
              }
              else if (mid3digits < 100) {
                mid3entries = "0" + mid3digits.toString();
              }
              else if (mid3digits < 1000) {
                mid3entries = mid3digits.toString();
              }
            }
          }
        }
        this.tempPackingNo = "PKG" + mid3entries + "-" + last6entries;
      }
      else {
        this.tempPackingNo = "PKG000-000000";
      }
    }
    else {
      this.tempPackingNo = "PKG000-000000";
    }
  }

  DynamicSearchPL(searchValue: string): void {
    this.searchPL = searchValue;

    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });


    this.lengthPL = this.ClonePackingList.length;
    this.sortPackingList();
    this.limitPackingList();
    this.topPaginatorPL.firstPage()
    this.bottomPaginatorPL.firstPage()
  }

  DS1() {
    this.ClonePackingList = this.packingList.filter(u => {
      const flag = String(u.PackingList_No).toLowerCase().includes(this.searchPL.toLowerCase())
      for (const po of u.PackingList_PO_List) {
        if (po.PO_No.toLowerCase().includes(this.searchPL.toLowerCase()))
          return true;
      }
      return flag
    });

    this.lengthPL = this.ClonePackingList.length;
  }

  paginator1(pageEvent: PageEvent) {
    this.pageSizePL = pageEvent.pageSize;
    this.pageIndexPL = pageEvent.pageIndex;
    this.offsetPL = this.pageSizePL * this.pageIndexPL;
    if (this.topPaginatorPL.pageIndex < this.pageIndexPL) {
      this.topPaginatorPL.nextPage();
    } else if (this.topPaginatorPL.pageIndex > this.pageIndexPL) {
      this.topPaginatorPL.previousPage();
    }
    if (this.bottomPaginatorPL.pageIndex < this.pageIndexPL) {
      this.bottomPaginatorPL.nextPage();
    } else if (this.bottomPaginatorPL.pageIndex > this.pageIndexPL) {
      this.bottomPaginatorPL.previousPage();
    }
    if (this.searchPL) {
      this.DS1();
    } else {
      this.ClonePackingList = this.packingList.slice();
    }
    this.sortPackingList();
    this.limitPackingList();
  }

  limitPackingList() {
    this.ClonePackingList = this.ClonePackingList.slice(this.offsetPL, (this.offsetPL + this.pageSizePL));
  }

  sortPackingList() {
    if (!this.sorteduPL.active || this.sorteduPL.direction === '') {
      return;
    }
    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.customerID, b.customerID, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
  }

  sortPackingData(sort: Sort) {
    this.sorteduPL = sort;
    this.ClonePackingList = this.packingList.slice();
    if (this.searchPL) {
      this.DS1();
    }
    if (!sort.active || sort.direction === '' && !this.searchPL) {
      this.ClonePackingList = this.packingList.slice();
      this.limitPackingList();
      return;
    }

    this.ClonePackingList = this.ClonePackingList.sort((a, b) => {
      const isAsc = this.sorteduPL.direction === 'asc';
      switch (this.sorteduPL.active) {
        case 'packingNo': return this.compare(a.PackingList_No, b.PackingList_No, isAsc);
        case 'shipDate': return this.compareDate(a.Shipping_Date, b.Shipping_Date, isAsc);
        case 'customername': return this.compare(a.Customer, b.Customer, isAsc);
        case 'destination': return this.compare(a.Destination, b.Destination, isAsc);
        case 'currency': return this.compare(a.Currency, b.Currency, isAsc);
        case 'pkgStatus': return this.compare(a.Status, b.Status, isAsc);

        default: return 0;
      }
    });
    this.limitPackingList();
  }

  exportToExcelPL() {
    const exportInformation = [];
    this.spinner.show();

    for (const p of this.packingList) {
      const info = {
        "PackingList No": p.PackingList_No || "-",
        "Customer": p.Customer || "-",
        "Destination": p.Destination || "-",
        "Shipping Date": p.Shipping_Date || "-",
        "Created By": p.Created_By,
        "Created Date": p.Created_Date,
        "Purchase Order List": p.PackingList_PO_List.map(a => a.PO_No + ' : ' + a.PackingList_PO_Part_List.map(b => b.Part_No).join(',')).join('   |   '),

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'PO' + new Date().getTime());
    this.spinner.hide();

  }

  GenerateLabel() {
    this.dialog.open(GenerateQRCodeDialogComponent, {
      width: '80%',
      height: '80%'
    });

  }

  GenerateShippingLabel() {
    this.dialog.open(GenerateShippingLabelComponent, {
      width: '80%',
      height: '80%',
      disableClose: true,
    }).afterClosed().subscribe(e=>this.initRecord());
  }

  GenerateOldLabel() {
    this.dialog.open(GenerateOldLabelComponent, {
      width: '80%',
      height: 'auto',
      disableClose: true,

    });

  }
  deletePacking(packing: PackingList) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Delete this Packing?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async confirm => {
      if (confirm) {
        this.packing_controller.delete_PackingList(packing);
        this.setup();
      }
    })

  }
  viewPackingDetail(packingNumber) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = {
      seePacking: packingNumber,
    };
    this.dialog.open(UpdatePackingComponent, dialogConfig).afterClosed().subscribe(result => {
      setTimeout(() => {
        this.refresh('packing');
      }, 1000);

    });
  }

  DynamicSearchLabel(Label: string): void {
    this.searchLabel = Label;
    this.CloneLabel = this.Label.filter(u =>
      String(u.PackingListNo).toLowerCase().includes(this.searchLabel.toLowerCase())
      || String(u.PurchaseOrderNo).toLowerCase().includes(this.searchLabel.toLowerCase())
      || String(u.PartNo).toLowerCase().includes(this.searchLabel.toLowerCase())

    );
    this.lengthLabel = this.CloneLabel.length;
    this.sortLabel();
    this.limitListLabel();
    this.topPaginatorLabel.firstPage()
    this.bottomPaginatorLabel.firstPage()
  }
  DSLabel() {
    this.CloneLabel = this.Label.filter(u =>
      String(u.PackingListNo).toLowerCase().includes(this.searchLabel.toLowerCase())
      || String(u.PurchaseOrderNo).toLowerCase().includes(this.searchLabel.toLowerCase())
      || String(u.PartNo).toLowerCase().includes(this.searchLabel.toLowerCase())
    );
    this.lengthLabel = this.CloneLabel.length;

  }
  paginatorLabel(pageEvent: PageEvent) {
    this.pageSizeLabel = pageEvent.pageSize;
    this.pageIndexLabel = pageEvent.pageIndex;
    this.offsetLabel = this.pageSizeLabel * this.pageIndexLabel;
    if (this.topPaginatorLabel.pageIndex < this.pageIndexLabel) {
      this.topPaginatorLabel.nextPage();
    } else if (this.topPaginatorLabel.pageIndex > this.pageIndexLabel) {
      this.topPaginatorLabel.previousPage();
    }
    if (this.bottomPaginatorLabel.pageIndex < this.pageIndexLabel) {
      this.bottomPaginatorLabel.nextPage();
    } else if (this.bottomPaginatorLabel.pageIndex > this.pageIndexLabel) {
      this.bottomPaginatorLabel.previousPage();
    }
    if (this.searchLabel) {
      this.DSLabel();
    } else {
      this.CloneLabel = this.Label.slice();
    }
    this.sortLabel();
    this.limitListLabel();
  }
  limitListLabel() {
    this.CloneLabel = this.CloneLabel.slice(this.offsetLabel, (this.offsetLabel + this.pageSizeLabel));
  }
  sortLabel() {
    if (!this.sorteduLabel.active || this.sorteduLabel.direction === '') {
      return;
    }
    this.CloneLabel = this.CloneLabel.sort((a, b) => {
      const isAsc = this.sorteduLabel.direction === 'asc';
      switch (this.sorteduLabel.active) {
        case 'po': return this.compare(a.PurchaseOrderNo, b.PurchaseOrderNo, isAsc);
        case 'packingNo': return this.compare(a.PackingListNo, b.PackingListNo, isAsc);
        case 'part': return this.compare(a.PartNo, b.PartNo, isAsc);
        case 'date': return this.compareDate(a.Date, b.Date, isAsc);
        default: return 0;
      }
    });
  }
  sortDataLabel(sort: Sort) {
    this.sorteduLabel = sort;
    this.CloneLabel = this.Label.slice();
    if (this.searchLabel) {
      this.DSLabel();
    }
    if (!sort.active || sort.direction === '' && !this.searchLabel) {
      this.CloneLabel = this.Label.slice();
      this.limitListLabel();
      return;
    }

    this.CloneLabel = this.CloneLabel.sort((a, b) => {
      const isAsc = this.sorteduLabel.direction === 'asc';
      switch (this.sorteduLabel.active) {
        case 'po': return this.compare(a.PurchaseOrderNo, b.PurchaseOrderNo, isAsc);
        case 'packingNo': return this.compare(a.PackingListNo, b.PackingListNo, isAsc);
        case 'part': return this.compare(a.PartNo, b.PartNo, isAsc);
        case 'date': return this.compareDate(a.Date, b.Date, isAsc);
        default: return 0;
      }
    });
    this.limitListLabel();
  }
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodes = [];
  async print(l: LabelRecord) {
    let z = this.packingList.find(e => e.PackingList_No === l.PackingListNo).PackingList_PO_List;
    let p = z.find(e => e.PO_No === l.PurchaseOrderNo).PackingList_PO_Part_List;
    let q = p.find(e => e.Part_No === l.PartNo);
    if (!q) {
      this.toast.error("Unexpected Error, please contact administator");
      return;
    }
    var counter = 1;
    for (const s of l.BoxesDetail) {

      for (let index = 0; index < s.NumberOfBox; index++) {
        var qr = {
          id: counter,
          po: l.PurchaseOrderNo,
          value: l.PurchaseOrderNo + '*' + l.PartNo + '*' + l.PackingListNo + '*' + (s.Quantities[index])
        }
        this.qrCodes.push(qr);
        counter++;
      }
    }
    this.spinner.show();
    try {

      setTimeout(async() => {
        await this.generateLabel(q, l.BoxesDetail, l.PurchaseOrderNo);
        this.spinner.hide();
      }, 5000);


    } catch (error) {
      this.toast.error(error)
      this.spinner.hide();
    }
  }



  generateLabel(info: any, BoxesDetail: BoxesNeededForEachSplit[], pono): Promise<void> {

    const doc = new jsPDF('portrait', 'mm', 'a5');
    var counter = 1;

    for (const boxes of BoxesDetail) {
      for (let index = 1; index <= boxes.NumberOfBox; index++) {
        const qrcode = document.getElementById(counter.toString());

        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 50);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.Photo;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.', 4, 45);
        doc.setFontType('unbold');
        doc.setFontSize(12);
        doc.text("PL NO: " + boxes.Label, 6, 55);

        if (pono) {
          const d = doc.splitTextToSize("PO: " + pono, 70);
          doc.text(d, 6, 62);
        }
        if (info.Reference) {
          const d = doc.splitTextToSize(info.Reference, 70);
          doc.text(d, 14, 69);
        }
        if (info.RevNO) {
          const d = doc.splitTextToSize("REV: " + info.RevNO, 70);
          doc.text(d, 6, 76);

        }
        if (info.POS) {
          const d = doc.splitTextToSize("POS: " + info.POS, 70);
          doc.text(d, 6, 83);
        }


        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);
        var crossWeight = (parseFloat(info.Part_Weight?info.Part_Weight:'0') * parseFloat(info.QuantityPerBox?info.QuantityPerBox:'0')+ parseFloat(info.Carton_Weight?info.Carton_Weight:'0'))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(index + ' of ' + boxes.NumberOfBox, 126, 88);


        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(22);
        const splt = doc.splitTextToSize(info.Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.text('(10) Quantity ', 4, 137);

        doc.text(boxes.Quantities[index - 1] + " PIECES", 5, 147);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);
        doc.addPage();
        counter++;
      }
    }
    var pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount)


    doc.save(pono + "_" + info.Part_No + '_ShippingLabel.pdf');
    this.qrCodes = [];

    return null;

  }

  getBase64Image(img) {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 125, 125);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    } catch (error) {
      throw new Error("No Image for part");
    }

  }

}

