import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  hidePassword = true;
  hidePasswordR = true;
  hideConfirmPasswordR = true;


  loginModel: any = {};
  registerModel: any = {};
  flag = true;
  validationsMessages = ' ';
  today = Date.now();
  breakpoint: boolean;

  constructor(
    private router: Router,
    private toast: ToastrService,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private userinfoSrv: UserInfoService,
    private fireStore: AngularFirestore,
  ) {

    this.spinner.show();
    this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.spinner.hide();
      } else {
        await this.fireStore.collection("Users").doc(authState.uid).get().forEach(doc => {
          if (doc.exists) {
            const role = doc.data().Role;
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if (role === 'operator') {
              this.router.navigate(['home/operator']);
              this.spinner.hide();
            }
            else if (role !== 'Admin') {
              doc.data().Access.forEach(e => {
                const info = {
                  module: e.key,
                  right: e.val(),
                }
                accessRights.push(info);
              })
            }
            this.userinfoSrv.setAccessRight(accessRights);
            this.router.navigate(['home']);
            this.spinner.hide();

          }
        });
      }
    });
  }


  ngOnInit() {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {

    this.breakpoint = (window.innerWidth <= 800) ? true : false;
  }

  changeForm() {
    this.flag = !this.flag;
  }

  login() {
    const email = this.loginModel.email;
    const password = this.loginModel.password;
    this.angularFireAuth.auth.signInWithEmailAndPassword(email, password)
      .then(async res => {
        this.toast.success('Welcome to MTIB-SIRIM', 'Login Successfully !', { timeOut: 3000 });
        const snapshot = await this.fireStore.collection("Users").doc(res.user.uid).get().forEach(doc => {
          if (doc.exists) {
            const role = doc.data().Role;
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if (role !== 'Admin') {
              doc.data().Access.forEach(e => {
                const info = {
                  module: e.key,
                  right: e.val(),
                }
                accessRights.push(info);
              })
            }
            this.userinfoSrv.setAccessRight(accessRights);
            this.router.navigate(['home']);
            this.spinner.hide();

          }
        });
      })
      .catch(err => {
        this.validationsMessages = 'Invalid email or password.';
      });
  }

}
