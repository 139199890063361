import { PartDB_controller } from './../../Services/DB_Controller/PartDB_controller';
import { SelectMachineComponent } from './select-machine/select-machine.component';
import { AddEventComponent } from './add-event/add-event.component';
import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Calendar, CalendarOptions, createElement, FullCalendarComponent } from '@fullcalendar/angular';
import interactionPlugin, { Draggable } from '@fullcalendar/interaction';
import { NgxSpinnerService } from 'ngx-spinner';
import { v4 as uuidv4 } from 'uuid';
import { UpdateScheduleComponent } from './update-schedule/update-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-scheduling',
  templateUrl: './scheduling.component.html',
  styleUrls: ['./scheduling.component.css']
})
export class SchedulingComponent implements AfterViewInit {

  constructor(
    private storage: AngularFireStorage,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    private spinner: NgxSpinnerService,
  ) {
    const name = Calendar.name;
  }

  machine: string;
  machinelist = [];
  eventlist = [];
  @ViewChild('draggable', { static: true }) draggableEl: ElementRef;
  @ViewChild('calendar', { static: true }) calendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    lazyFetching: true,
    contentHeight: 'auto',
    height: "100%",
    schedulerLicenseKey: 'CC-Attribution-NonCommercial-NoDerivatives',
    headerToolbar: {
      left: 'prev,next today',
      center: 'title',
      right: 'dayGridMonth, timeGridWeek, timeGridDay, listMonth'
    },
    dayMaxEvents: true,
    // eventClick: (info) => {
    //   const dialogConfig = new MatDialogConfig();
    //   dialogConfig.autoFocus = false;
    //   dialogConfig.height = '80%';
    //   dialogConfig.width = '80%';
    //   const position = {
    //     top: '5%'
    //   };
    //   dialogConfig.position = position;
    //   dialogConfig.disableClose = true;
    //   dialogConfig.data = {
    //     id: info.event.id,
    //     info: info.event.groupId,
    //   }
    //   this.dialog.open(UpdateScheduleComponent, dialogConfig).afterClosed().subscribe(result => {
    //     this.spinner.show();
    //     this.calendarComponent.getApi().removeAllEvents();
    //     this.getSchedule();
    //   });
    // },
    eventContent: (event, element) => {
      return element = {
        html: '<div data-toggle="tooltip" data-html="true" data-placement="top" title="' + event.event.extendedProps.description + '" class="col-12 row" >'
          + '<div style="width:25px"><img class="zoom" src="' + event.event.extendedProps.imageURL + '" width="25px" height="25px"></div>' + event.event.title +
          '</div>'
      };
    }

  };
  count = 0;
  ngOnInit(): void {

  }

  ngAfterViewInit(): void {

    this.firestore.collection('Machines').get().forEach(machine => {
      if (machine.size > 0) {
        machine.forEach(m => {
          const machine = { machineNumber: m.id, ...m.data(), schedules: [] };
          this.machinelist.push(machine);
        })
        this.machinelist = this.machinelist.sort((a, b) => {
          return (Number(a.machineNumber.match(/(\d+)/g)[0]) - Number((b.machineNumber.match(/(\d+)/g)[0])));
        })
        this.machine = this.machinelist[0].machineNumber;
        this.getSchedule();
      }
    })

  }

  getSchedule() {

    this.firestore.collection('Machines').doc(this.machine).collection('Schedule_Current').get().forEach(schedules => {
      schedules.forEach(d => {

        this.calendarComponent.getApi().addEvent({
          title: d.data().jobOrderNo + ', ' + d.data().itemCode + ', ' + d.data().productionQuantity,
          description: 'Job order no: ' + d.data().jobOrderNo +
            '\nItem Code: ' + d.data().itemCode +
            '\nQuantity: ' + d.data().productionQuantity +
            '\nStart Time: ' + new Date(d.data().startDate.toDate()) +
            '\nEnd Time: ' + new Date(d.data().endDate.toDate()),
          start: d.data().startDate.toDate(),
          end: d.data().endDate.toDate(),
          id: d.id,
          groupId: d.id,
        });
      })

    })
    this.firestore.collection('Machines').doc(this.machine).collection('Schedule_Queue').get().forEach(schedules => {
      schedules.forEach(d => {
        this.calendarComponent.getApi().addEvent({
          title: d.data().jobOrderNo + ', ' + d.data().itemCode + ', ' + d.data().productionQuantity,
          description: 'Job order no: ' + d.data().jobOrderNo +
            '\nItem Code: ' + d.data().itemCode +
            '\nQuantity: ' + d.data().productionQuantity +
            '\nStart Time: ' + new Date(d.data().startDate.toDate()) +
            '\nEnd Time: ' + new Date(d.data().endDate.toDate()),
          start: d.data().startDate.toDate(),
          end: d.data().endDate.toDate(),
          id: d.id,
          groupId: d.id,
        });

      })
    })

    this.spinner.hide();

  }


  AddEvent() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95vw';

    const position = {
      top: '1%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    this.dialog.open(AddEventComponent, dialogConfig).afterClosed().subscribe(result => {
      this.spinner.show();
      this.calendarComponent.getApi().removeAllEvents();
      this.getSchedule();

    });

  }

  selectMachine(value) {
    this.machine = value;
    this.spinner.show();
    this.calendarComponent.getApi().removeAllEvents();
    this.getSchedule();

  }

}
