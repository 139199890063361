import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Supplier } from './../../../../Services/Object_Classes/RawMaterial/Supplier';
import { SupplierDB_controller } from './../../../../Services/DB_Controller/SupplierDB_controller';
import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { FormGroup } from '@angular/forms';
import { FormControl } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';

@Component({
  selector: 'app-add-part',
  templateUrl: './add-part.component.html',
  styleUrls: ['./add-part.component.css']
})
export class AddPartComponent implements OnInit {

  part = {
    itemCode: '',
    clientName: '',
    species: '',
    grade: '',
    supplierName: '',
    width: '',
    height: '',
    length: '',
    pieces: '',
    tonnage: '',
    cycleTime: '30',
  }

  edit = false;
  ngOnInit() {
  }
  constructor(
    public dialogRefaddPart: MatDialogRef<AddPartComponent>,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data) {
    if (data) {
      this.part = data;
      this.edit = true;
    }
  }

  existingName = false;
  async nameValidation() {
    const name = this.part.itemCode.trim();
    this.firestore.collection("Parts", ref => ref.where('itemCode', '==', name)).get().forEach(e => {
      if (e.size > 0) {
        this.existingName = true;
      } else {
        this.existingName = false;
      }
    })
  }

  onNoClick(): void {
    this.dialogRefaddPart.close();
  }

}
