import { EmpInfoComponent } from './emp-info/emp-info.component';
import { Machine } from './../../Services/Object_Classes/Machine/Machine';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { data } from 'jquery';
import { QtyInformationComponent } from './qty-information/qty-information.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { PartsInfoComponent } from './parts-info/parts-info.component';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { getMatIconFailedToSanitizeLiteralError, MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Part } from '../../Services/Object_Classes/Part/Part';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { PartTracker, PurchaseOrder } from '../../Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { PODB_controller } from '../../Services/DB_Controller/PODB_controller';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { WeightingDB_controller } from '../../Services/DB_Controller/WeightingDB_controller';
import { Weighting } from '../../Services/Object_Classes/Weighting/Weighting';
import { AngularFireStorage } from '@angular/fire/storage';
import { firestore } from 'firebase';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';
import { QCInfoComponent } from './qcinfo/qcinfo.component';

@Component({
  selector: 'app-qc',
  templateUrl: './qc.component.html',
  styleUrls: ['./qc.component.css'],
})
export class QCComponent implements OnInit {
  poFlag = false;
  partNumFlag = false;
  accQtyFlag = false;
  rejQtyFlag = false;
  rejRemFlag = false;
  wDateFlag = false;
  myControl = new FormControl();
  options: string[] = [];
  filteredOptions: Observable<string[]>;
  myControl1 = new FormControl();
  options1: string[] = ['-', 'sink mark', 'short moulding', 'colour out', 'dimension out', 'pin broken', 'warping',
    'black dot', 'pin mark', 'moisture mark'];
  filteredOptions1: Observable<string[]>;
  currentPONo: string;
  currentPartNum: string;

  TotAccQty: number[] = [];
  TotRejQty: number[] = [];
  RejRemark: string[] = [];
  LastWeightDate: Date[] = [];
  POPartList: PartTracker[] = [];
  POList: PurchaseOrder[] = [];
  PartList: Part[] = [];
  podb_Controller = new PODB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  weighting_Controller = new WeightingDB_controller(this.db);
  machine_controller = new MachineDB_controller(this.db);


  //Pagination record
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: 'weightDate',
    direction: 'desc'
  };
  WeightingRecordList: Weighting[] = [];
  CloneWeightingRecordList: Weighting[] = [];
  @ViewChild('topPaginator', { read: MatPaginator, static: false }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: false }) bottomPaginator: MatPaginator;


  //Pagination from stock record
  stocklength = 0;
  stockpageSize = 10;
  stockpageSizeOptions: number[] = [10, 25, 50];
  stockpageIndex = 0;
  stockoffset = this.stockpageSize * this.stockpageIndex;
  stocksearch: string;
  stocksortedu = {
    active: '',
    direction: ''
  };
  stockWeightingRecordList: Weighting[] = [];
  stockCloneWeightingRecordList: Weighting[] = [];
  @ViewChild('stocktopPaginator', { read: MatPaginator, static: false }) stocktopPaginator: MatPaginator;
  @ViewChild('stockbottomPaginator', { read: MatPaginator, static: false }) stockbottomPaginator: MatPaginator;

  QClog = []
  Weightlog = []
  Shiplog = []

  qcLength = 10;
  weightLength = 10;
  shipLength = 10;

  stockIn = 0;
  stockOut = 0;
  datePicker: Date
  FromPicker: Date

  monthDate: Date;
  firstDayMonth: Date;
  LastDayMonth: Date;

  previousStockIn = 0;
  previousStockOut = 0;

  isFetching = true;

  EmployeeWeightingList = [];
  EmployeeQCList = [];

  constructor(
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private excelHelper: ExcelHelperService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private storage: AngularFireStorage,
    private firestore: AngularFirestore
  ) {
    this.monthDate = new Date();
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate() + totalDay - 1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
  }

  machineList: Machine[] = [];
  async ngOnInit() {
    this.datePicker = new Date();
    this.FromPicker = new Date(new Date().setMonth(this.datePicker.getMonth() - 1));
    this.db.database.ref('Machine').on('value', async (childSnapshot) => {
      this.machineList = await this.machine_controller.getMachineListForQC(childSnapshot);
      let temp = this.machineList.filter(m => !m.Machine_No.includes("@@"))

      for (const machine of temp) {
        let cloneMachines = this.machineList.filter(m => m.Machine_No.includes("@@" + machine.Machine_No + "_"));
        for (const cloneMachine of cloneMachines) {
          machine.Schedule_Track = machine.Schedule_Track.concat(cloneMachine.Schedule_Track)
        }
        machine.Schedule_Track = machine.Schedule_Track.sort((a, b) => {
          return this.compareDate(a.DateFormatStart, b.DateFormatStart, false);
        })
      }
      this.machineList = this.machineList.filter(m => !m.Machine_No.includes("@@"));
      this.machineList = this.machineList.sort((a, b) => {
        return this.compareDate(a.Machine_No, b.Machine_No, true);
      })
    })

    setTimeout(() => {
      this.isFetching = false;
    }, 2000);
    this.setup();
    this.getQCLog();
    this.getWeightLog();
    this.getShipLog();
    this.getDashboard();

  }

  NoJob(schedules) {
    return schedules.filter(s => s.QCDone).length === 0 ? true : false;
  }
  machineInfo = [];
  getDashboard() {
    this.getLog()
    this.getPreviousMonthLog()
    // this.getMachineLog();
  }

  refresh(status=null) {
    if(status === 'history'){
      this.getQCLog();
      this.getShipLog();
      this.getWeightLog();
    }
    else
      this.setup();
    
  }

  getMachineLog() {
    // const fromdate = firestore.Timestamp.fromDate(this.firstDayMonth);
    // const toDate = firestore.Timestamp.fromDate(this.LastDayMonth);
    // this.firestore.collection('QualityCheckLog',
    // ref => ref.where('Date','>=',fromdate)
    //         .where('Date','<=',toDate)).get().forEach(data => {
    //     data.forEach(e => {
    //     this.firestore.collection('QualityCheckLog').doc(e.id).
    //     collection('PO').get().forEach(poLog => {

    //       const logs = [];
    //       poLog.forEach(log => {

    //         let rej = []
    //         let rejQty = 0
    //         if(log.data()['Rejected Information']){
    //           rej = Object.values(log.data()['Rejected Information']);
    //         }
    //         rej.forEach(element => {
    //           rejQty += element;
    //         });
    //         const info ={
    //           date : new Date(log.data().Date),
    //           acceptyQty: log.data()['Accepted Quantity'],
    //           PO:  log.data().PO_ID,
    //           Part: log.data().Part_ID,
    //           rejQty: rejQty,
    //         }
    //         logs.push(info);
    //       });

    //       // logs.forEach(async e=>{
    //       //   var snapshot = await this.db.database.ref('Purchase Order/'+e.PO + '/Part List').once('value');
    //       //   if(snapshot.exists()){
    //       //     snapshot.forEach(childsnapshot=>{
    //       //       if(childsnapshot.child('Part No').val() === e.Part){
    //       //         if(childsnapshot.child('MachineNo').val()){
    //       //           const ind = this.machineList.findIndex(m=>childsnapshot.child('MachineNo').val().includes(m));

    //       //           if(ind !== -1){
    //       //             this.machineInfo[ind].acceptyQty += e.acceptyQty;
    //       //             this.machineInfo[ind].rejQty += e.rejQty;
    //       //           }
    //       //         }
    //       //       }
    //       //     })
    //       //   }
    //       // })
    //     });
    //   });
    // });
  }
  getLog() {
    this.stockIn = 0;
    this.stockOut = 0;
    const fromdate = firestore.Timestamp.fromDate(this.firstDayMonth);
    const toDate = firestore.Timestamp.fromDate(this.LastDayMonth);
    this.firestore.collection('WeightingLog',
      ref => ref.where('Date', '>=', fromdate)
        .where('Date', '<=', toDate)
    ).get().forEach(data => {
      data.forEach(e => {
        const d = new Date(e.data().Date)
        const currentD = new Date();
        let flag = (d.getFullYear() === currentD.getFullYear()) && (d.getMonth() === currentD.getMonth()) && (d.getDate() === currentD.getDate())
        if (e.data().totalStockIn && !flag) {
          this.stockIn += e.data().totalStockIn
          this.stockOut += e.data().totalStockOut
        } else {
          this.firestore.collection('WeightingLog').doc(e.id).
            collection('PO').get().forEach(poLog => {
              var totalStockIn = 0;
              var totalStockOut = 0;
              poLog.forEach(log => {
                if (log.data()['MadeToOrder'] || log.data()['MakeToStock']) {
                  if (parseFloat(log.data()['MadeToOrder']) !== 0) {
                    this.stockOut += parseFloat(log.data()['MadeToOrder'])
                    totalStockOut += parseFloat(log.data()['MadeToOrder'])
                  } else {
                    this.stockIn += parseFloat(log.data()['MakeToStock'])
                    totalStockIn += parseFloat(log.data()['MakeToStock'])
                  }
                }
              });
              const updateInfo = {
                totalStockIn,
                totalStockOut
              }
              this.firestore.collection('WeightingLog').doc(e.id).update(updateInfo);
            });
        }
      });
    });
  }

  getPreviousMonthLog() {
    this.previousStockIn = 0;
    this.previousStockOut = 0;
    const first = new Date(this.firstDayMonth);
    const last = new Date(this.LastDayMonth);
    first.setMonth(first.getMonth() - 1)
    last.setMonth(last.getMonth() - 1)

    const fromdate = firestore.Timestamp.fromDate(first);
    const toDate = firestore.Timestamp.fromDate(last);
    this.firestore.collection('WeightingLog',
      ref => ref.where('Date', '>=', fromdate)
        .where('Date', '<=', toDate)
    ).get().forEach(data => {
      data.forEach(e => {
        if (e.data().totalStockIn) {
          this.previousStockIn += e.data().totalStockIn
          this.previousStockOut += e.data().totalStockOut
        } else {
          this.firestore.collection('WeightingLog').doc(e.id).
            collection('PO').get().forEach(poLog => {
              var totalStockIn = 0;
              var totalStockOut = 0;
              poLog.forEach(log => {
                if (log.data()['MadeToOrder'] || log.data()['MakeToStock']) {
                  if (parseFloat(log.data()['MadeToOrder']) !== 0) {
                    this.previousStockOut += parseFloat(log.data()['MadeToOrder'])
                    totalStockOut += parseFloat(log.data()['MadeToOrder'])
                  } else {
                    this.previousStockIn += parseFloat(log.data()['MakeToStock'])
                    totalStockIn += parseFloat(log.data()['MakeToStock'])
                  }
                }
              });
              const updateInfo = {
                totalStockIn,
                totalStockOut
              }
              this.firestore.collection('WeightingLog').doc(e.id).update(updateInfo);
            });
        }

      });
    });
  }

  logs = [];
  searchLog() {
    this.getQCLog();
    this.getWeightLog();
    this.getShipLog();
  }
  getWeightLog() {
    this.Weightlog = [];
    this.EmployeeWeightingList = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('WeightingLog',
      ref => ref.where('Date', '>=', fromdate)
        .where('Date', '<=', toDate)
        .orderBy('Date', 'desc')).get().forEach(data => {
          data.forEach(e => {

            if (this.Weightlog.length > this.weightLength) {
              return;
            }
            this.firestore.collection('WeightingLog').doc(e.id).
              collection('PO', ref => ref.orderBy('Date', 'desc').limit(100)).get().forEach(poLog => {
                poLog.forEach(log => {
                  if (this.weightLength != 0)
                    if (this.Weightlog.length > this.weightLength) {
                      return;
                    }

                  const madeToOrder = parseFloat(log.data()["MadeToOrder"]) !== 0 ? true : false;

                  const info = {
                    date: new Date(log.data().Date),
                    packQty: log.data()['MadeToOrder'],
                    packQtyStock: log.data()['MakeToStock'],
                    PO: log.data().PO_ID,
                    Part: log.data().Part_ID,
                    // status: madeToOrder?"Made To Order":"Made To Stock",
                    RackingNo: log.data().RackingNo,
                    WarehouseLocation: log.data().WarehouseLocation,
                    UpdatedBy: log.data().UpdatedBy,
                    IsUpdated: log.data()['IsUpdated']

                  }
                  const weightInfo = {
                    name: info.UpdatedBy,
                    infos: []
                  }
                  weightInfo.infos.push(info);
                  let emp = null;
                  if (this.EmployeeWeightingList.length > 0) {
                    emp = this.EmployeeWeightingList.find(e => e.name === info.UpdatedBy);
                    if (emp != null)
                      emp.infos.push(info);
                    else
                      this.EmployeeWeightingList.push(weightInfo);
                  } else {
                    this.EmployeeWeightingList.push(weightInfo);
                  }

                  this.Weightlog.push(info);
                });
              });
          });
        });
  }
  getQCLog() {
    this.QClog = [];
    this.EmployeeQCList = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);
    this.firestore.collection('QualityCheckLog',
      ref => ref.where('Date', '>=', fromdate)
        .where('Date', '<=', toDate)
        .orderBy('Date', 'desc')).get().forEach(data => {
          data.forEach(e => {
            if (this.QClog.length > this.qcLength) {
              return;
            }
            this.firestore.collection('QualityCheckLog').doc(e.id).
              collection('PO', ref => ref.orderBy('Date', 'desc').limit(100)).get().forEach(poLog => {
                poLog.forEach(log => {
                  if (this.qcLength != 0)
                    if (this.QClog.length > this.qcLength) {
                      return;
                    }
                  let rej = []
                  let rejQty = 0
                  let rejInput = []
                  let rejQtyInput = 0

                  if (log.data()['Rejected Information']) {
                    rej = Object.values(log.data()['Rejected Information']);
                  }
                  rej.forEach(element => {
                    rejQty += element;
                  });

                  if (log.data()['Rejected Input']) {
                    rejInput = Object.values(log.data()['Rejected Input']);
                  }
                  rejInput.forEach(element => {
                    rejQtyInput += element;
                  });
                  const info = {
                    path: e.id,
                    path2: log.id,
                    date: new Date(log.data().Date),
                    acceptyQty: log.data()['Accepted Quantity']?log.data()['Accepted Quantity']:0,
                    inputQty: log.data()['Input']?log.data()['Input']:0,
                    rejQtyInput: rejQtyInput?rejQtyInput:0,
                    PO: log.data().PO_ID,
                    Part: log.data().Part_ID,
                    rejQty: rejQty?rejQty:0,
                    UpdatedBy: log.data()['Update By'],
                    IsUpdated: log.data()['IsOverride'],
                    rejInfo: log.data()['Rejected Information']
                  }

                  const qcInfo = {
                    name: info.UpdatedBy,
                    infos: []
                  }
                  qcInfo.infos.push(info);

                  let emp = null;
                  if (this.EmployeeQCList.length > 0) {
                    emp = this.EmployeeQCList.find(e => e.name === info.UpdatedBy);
                    if (emp != null)
                      emp.infos.push(info);
                    else
                      this.EmployeeQCList.push(qcInfo);
                  } else {
                    this.EmployeeQCList.push(qcInfo);
                  }
                  this.QClog.push(info);
                });
              });
          });
        });
  }

  getShipLog() {
    this.Shiplog = [];
    const from = new Date(this.FromPicker);
    from.setHours(0)
    from.setMinutes(0)
    from.setSeconds(0)
    const to = new Date(this.datePicker);
    to.setHours(23)
    to.setMinutes(59)
    to.setSeconds(59)
    const fromdate = firestore.Timestamp.fromDate(from);
    const toDate = firestore.Timestamp.fromDate(to);

    this.firestore.collection('ShippingLog',
      ref => ref.where('Date', '>=', fromdate)
        .where('Date', '<=', toDate)
        .orderBy('Date', 'desc')).get().forEach(data => {
          data.forEach(e => {
            if (this.Shiplog.length > this.shipLength) {
              return;
            }
            this.firestore.collection('ShippingLog').doc(e.id).
              collection('PO', ref => ref.orderBy('Date', 'desc').limit(100)).get().forEach(poLog => {
                poLog.forEach(log => {
                  if (this.shipLength != 0)
                    if (this.Shiplog.length > this.shipLength) {
                      return;
                    }

                  const info = {
                    date: new Date(log.data().Date),
                    qty: log.data()['Part Qty'],
                    PO: log.data().PO_ID,
                    Part: log.data()['Part Number'],
                    updatedBy: log.data().UpdateBy
                  }

                  this.Shiplog.push(info);
                });
              });
          });
        });
  }



  setup() {
    this.spinner.show();
    this.weighting_Controller.getWeightingList().then(data => {
      this.WeightingRecordList = data;
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
      this.length = this.WeightingRecordList.length;
      this.sortRecordList();
      this.limitList();
      this.spinner.hide();
    });

  }

  DynamicSearchRecord(RecordName: string): void {
    this.search = RecordName;
    this.CloneWeightingRecordList = this.WeightingRecordList.filter(u => {
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.Weight_Part_List) {
        if (part.Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.UpdateBy.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.CloneWeightingRecordList.length;
    this.sortRecordList();
    this.limitList();
    this.topPaginator.firstPage();
    this.bottomPaginator.firstPage();

  }
  DS() {
    this.CloneWeightingRecordList = this.WeightingRecordList.filter(u => {
      const flag = String(u.PO_No).toLowerCase().includes(this.search.toLowerCase())

      for (const part of u.Weight_Part_List) {
        if (part.Part_No.toLowerCase().includes(this.search.toLowerCase()) || (part.UpdateBy.toLowerCase().includes(this.search.toLowerCase())))
          return true;
      }
      return flag

    });
    this.length = this.CloneWeightingRecordList.length;
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DS();
    } else {
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
    }
    this.sortRecordList();
    this.limitList();
  }
  limitList() {
    this.CloneWeightingRecordList = this.CloneWeightingRecordList.slice(this.offset, (this.offset + this.pageSize));
  }
  sortRecordList() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.CloneWeightingRecordList = this.CloneWeightingRecordList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNo': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'partNo': return this.compare(a.Weight_Part_List[0].Part_No, b.Weight_Part_List[0].Part_No, isAsc);
        case 'acceptedQty': return this.compare(a.Weight_Part_List[0].Accept_Qty, b.Weight_Part_List[0].Accept_Qty, isAsc);
        case 'rejectedQty': return this.compare(a.Weight_Part_List[0].Reject_Qty, b.Weight_Part_List[0].Reject_Qty, isAsc);
        case 'working': return this.compare(a.Weight_Part_List[0].WorkingArea, b.Weight_Part_List[0].WorkingArea, isAsc);
        case 'updateBy': return this.compare(a.Weight_Part_List[0].UpdateBy, b.Weight_Part_List[0].UpdateBy, isAsc);
        case 'qcDate': return this.compareDate(a.Weight_Part_List[0].Weight_Date, b.Weight_Part_List[0].Weight_Date, isAsc);
        case 'weightDate': return this.compareDate(a.Weight_Part_List[0].WeightingInfo.Updated_Date, b.Weight_Part_List[0].WeightingInfo.Updated_Date, isAsc);
        default: return 0;
      }
    });
  }
  sortData(sort: Sort) {
    this.sortedu = sort;
    this.CloneWeightingRecordList = this.WeightingRecordList.slice();
    if (this.search) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.CloneWeightingRecordList = this.WeightingRecordList.slice();
      this.limitList();
      return;
    }

    this.CloneWeightingRecordList = this.CloneWeightingRecordList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'poNo': return this.compare(a.PO_No, b.PO_No, isAsc);
        case 'partNo': return this.compare(a.Weight_Part_List[0].Part_No, b.Weight_Part_List[0].Part_No, isAsc);
        case 'acceptedQty': return this.compare(a.Weight_Part_List[0].Accept_Qty, b.Weight_Part_List[0].Accept_Qty, isAsc);
        case 'rejectedQty': return this.compare(a.Weight_Part_List[0].Reject_Qty, b.Weight_Part_List[0].Reject_Qty, isAsc);
        case 'working': return this.compare(a.Weight_Part_List[0].UpdateBy, b.Weight_Part_List[0].UpdateBy, isAsc);
        case 'updateBy': return this.compare(a.Weight_Part_List[0].WorkingArea, b.Weight_Part_List[0].WorkingArea, isAsc);
        case 'qcDate': return this.compareDate(a.Weight_Part_List[0].Weight_Date, b.Weight_Part_List[0].Weight_Date, isAsc);
        case 'weightDate': return this.compareDate(a.Weight_Part_List[0].WeightingInfo.Updated_Date, b.Weight_Part_List[0].WeightingInfo.Updated_Date, isAsc);

        default: return 0;
      }
    });
    this.limitList();
  }



  compare(a: number | string, b: number | string, isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  viewParts(record) {
    this.dialog.open(PartsInfoComponent, {
      width: '80%',
      height: '80%',
      data: record
    });
  }

  GetActivity(value) {
    this.qcLength = value;
    this.getQCLog();
  }

  GetActivity2(value) {
    this.weightLength = value;
    this.getWeightLog();
  }

  GetActivity3(value) {
    this.shipLength = value;
    this.getShipLog();
  }


  detectChanges(event) {
    this.monthDate = event;
    var totalDay = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth() + 1, 0).getDate();
    const temp = new Date(this.monthDate.getFullYear(), this.monthDate.getMonth(), 1);
    this.firstDayMonth = new Date(temp);
    this.firstDayMonth.setHours(0)
    this.firstDayMonth.setMinutes(0)
    this.firstDayMonth.setSeconds(0)
    this.LastDayMonth = new Date(temp.setDate(temp.getDate() + totalDay - 1));
    this.LastDayMonth.setHours(23)
    this.LastDayMonth.setMinutes(59)
    this.LastDayMonth.setSeconds(59)
    this.getDashboard();
  }
  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }


  exportToExcel() {
    const exportInformation = [];
    this.spinner.show();
    const weight = this.WeightingRecordList.sort((a, b) => {
      return (a.PO_No < b.PO_No ? -1 : 1) * (true ? 1 : -1);
    }
    );
    for (const w of weight) {
      for (const part of w.Weight_Part_List) {
        const info = {
          "PO Number": w.PO_No || "-",
          "Part Number": part.Part_No || "-",
          "Accepted Quantity": part.Accept_Qty || 0,
          "Made to Order": part.WeightingInfo ? part.WeightingInfo.MadeToOrder : "Not Weighting Yet",
          "Make to Stock": part.WeightingInfo ? part.WeightingInfo.MakeToStock : "Not Weighting Yet",
          "Rejected Quantity": part.Reject_Qty || 0,
          "Rejected Reason": part.RejectedInfos.map(v => v.Code).join(' ,'),
          "Working Area": part.WorkingArea || "",
          "Updated By": part.UpdateBy || "-",
          "QC Date": part.Weight_Date || "-",
        }
        exportInformation.push(info)
      }
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'QC' + new Date().getTime());
    this.spinner.hide();
  }


  view(part, statement) {
    var info;
    switch (statement) {
      case 'accept':
        info = {
          title: 'Information',
          data: [{
            'label': 'Made To Order',
            'value': part.MadeToOrder,
            'size': 'col-lg-12'
          },
          {
            'label': 'Make To Stcck',
            'value': part.MakeToStock,
            'size': 'col-lg-12'
          },
          ]
        }
        break;
      case 'reject':
        info = {
          title: 'Information',
          data: [],
        }
        for (const rejectInfo of part) {
          let i = {
            'label': rejectInfo.Code,
            'value': rejectInfo.Quantity,
            'size': 'col-lg-12'
          }
          info.data.push(i);
        }
        break;
    }
    this.dialog.open(QtyInformationComponent, {
      width: '50%',
      height: 'auto',
      data: info
    });

  }
  viewAll(machine: Machine) {
    this.dialog.open(QCInfoComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: machine.Machine_No + " All Completed PO",
        tableData: machine.Schedule_Track.filter(e => e.QCDone)
      }
    });
  }

  viewAllNotYet(machine: Machine) {
    this.dialog.open(QCInfoComponent, {
      width: '80%',
      height: '80%',
      data: {
        title: machine.Machine_No + " All Pending PO",
        tableData: machine.Schedule_Track.filter(e => !e.QCDone)
      }
    });
  }

  viewWeighting(name) {
    let emp = this.EmployeeWeightingList.find(e => e.name === name);

    emp.infos = emp.infos.sort((a, b) => a.PO < b.PO ? -1 : 1);
    let infos = [];
    let info = [];
    let index = 0;
    for (const e of emp.infos) {
      if (index === 0)
        info.push(e)
      else if (info.find(s => s.PO === e.PO && s.Part === e.Part)) {
        info.push(e)
      } else {
        infos.push(info)
        info = [];
        info.push(e);
      }
      index++;
      if (index === emp.infos.length)
        infos.push(info);
    }

    for (var i of infos) {
      i = i.sort((a, b) => this.compareDate(a.date, b.date, true));
    }

    let totals1 = []
    let totals2 = []
    let totalIndex = 0;
    for (var i of infos) {
      totals1.push(0);
      totals2.push(0);
      for (const ii of i) {
        if (ii.IsUpdated) {
          totals1[totalIndex] = parseFloat(ii.packQty);
          totals2[totalIndex] = parseFloat(ii.packQtyStock);
        }
        else {
          totals1[totalIndex] += parseFloat(ii.packQty);
          totals2[totalIndex] += parseFloat(ii.packQtyStock);
        }
      }
      totalIndex++;
    }


    this.dialog.open(EmpInfoComponent, {
      width: '90vw',
      height: '90vh',
      data: {
        name: name,
        columns: ['Purchase Order', 'Part Number', 'Type', 'Warehouse Location', 'Rack No', 'Packed Quantity (Order)', 'Packed Quantity (Stock)'],
        infos: infos,
        total1: totals1,
        total2: totals2,
      },

    });
  }


  viewQC(name) {
    // this.getQC();
    let emp = this.EmployeeQCList.find(e => e.name === name);

    emp.infos = emp.infos.sort((a, b) => a.PO < b.PO ? -1 : 1);
    let infos = [];
    let info = [];
    let index = 0;
    for (const e of emp.infos) {
      if (index === 0)
        info.push(e)
      else if (info.find(s => s.PO === e.PO && s.Part === e.Part)) {
        info.push(e)
      } else {
        infos.push(info)
        info = [];
        info.push(e);
      }
      index++;
      if (index === emp.infos.length)
        infos.push(info);
    }

    for (var i of infos) {
      i = i.sort((a, b) => this.compareDate(a.date, b.date, true));
    }

    let totals1 = []
    let totals2 = []
    let totalIndex = 0;
    for (var i of infos) {
      totals1.push(0);
      totals2.push(0);
      for (const ii of i) {
        if (ii.IsUpdated) {
          totals1[totalIndex] = parseFloat(ii.acceptyQty);
          totals2[totalIndex] = parseFloat(ii.rejQty);
        }
        else {
          totals1[totalIndex] += parseFloat(ii.inputQty);
          if(ii.rejQty == parseFloat(ii.rejQtyInput)){
            if(totals2[totalIndex] === ii.rejQty)
              ii.rejQtyInput = 0;
            totals2[totalIndex] = ii.rejQty;
          }
          else
             totals2[totalIndex] += parseFloat(ii.rejQtyInput);
        }
      }
      totalIndex++;
    }

    this.dialog.open(EmpInfoComponent, {
      width: '90vw',
      height: '90vh',
      data: {
        name: name,
        columns: ['Purchase Order', 'Part Number', 'Type', 'Accepted Quantity', 'Rejected Quantity'],
        infos: infos,
        total1: totals1,
        total2: totals2,
      }

    });
  }


  getQC() {
    this.QClog = this.QClog.sort((a, b) => a.PO < b.PO ? -1 : 1)

    let infos = [];
    let info = [];
    let index = 0;

    for (const e of this.QClog) {
      if (index === 0)
        info.push(e)
      else if (info.find(s => s.PO === e.PO && s.Part === e.Part)) {
        info.push(e)
      } else {
        infos.push(info)
        info = [];
        info.push(e);
      }
      index++;
      if (index === this.QClog.length)
        infos.push(info);
    }

    for (var i of infos) {
      i = i.sort((a, b) => this.compareDate(a.date, b.date, true));
    }

    console.log(infos);

     for (let index = 0; index < infos.length; index++) {
       let latestQuantity = 0;
      for (let j = 0; j < infos[index].length; j++) {
       if( j=== 0 && infos[index][j].IsUpdated)
          break;

        if(!infos[index][j].IsUpdated){
          if(infos[index][j].Input)
            infos[index][j].Skip = true;
          infos[index][j].Input = parseFloat(infos[index][j].acceptyQty)
          latestQuantity = parseFloat(infos[index][j].acceptyQty);
          infos[index][j].IsOverride = false;
        }
        else{
          if(infos[index][j].Input)
            infos[index][j].Skip = true;

          infos[index][j].Input = parseFloat(infos[index][j].acceptyQty) - latestQuantity
          latestQuantity = parseFloat(infos[index][j].acceptyQty);
          infos[index][j].IsOverride = false;

          if(infos[index][j].Input <= 0){
            infos[index][j].IsOverride = true;
            infos[index][j].Input = parseFloat(infos[index][j].acceptyQty);
          }
        }
      }
     }

    // for (let index = 0; index < infos.length; index++) {
    //   let latestRej = null;
    //   for (let j = 0; j < infos[index].length; j++) {
    //     let res = this.WeightingRecordList.find(e => e.PO_No === infos[index][j].PO)
    //     if (res) {
    //       let r = res.Weight_Part_List.find(e => e.Part_No === infos[index][j].Part)
    //       if (r) {
    //         let info = {}
    //         let q = 0;
    //         for (const rej of r.RejectedInfos) {
    //           info[rej.Code] = rej.Quantity;
    //           q += rej.Quantity
    //         }
    //         infos[index][j].rejInfo = info;
    //         infos[index][j].rejQty = q;
    //       }
    //     }

    //     if (Object.keys(infos[index][j].rejInfo).length > 0) {
    //       if (latestRej === null) {
    //         infos[index][j].rejInput = infos[index][j].rejInfo
    //         latestRej = infos[index][j].rejInfo
    //       }
    //       else {
    //         infos[index][j].rejInput = {}
    //         let flag = false;
    //         for (const [key, value] of Object.entries(infos[index][j].rejInfo)) {
    //           if (latestRej[key]) {
    //             infos[index][j].rejInput[key] = infos[index][j].rejInfo[key] - latestRej[key]
    //           } else {
    //             flag = true;
    //             infos[index][j].rejInput[key] = infos[index][j].rejInfo[key]
    //           }
    //         }
    //         for (const [key, value] of Object.entries(latestRej)) {
    //           if (!infos[index][j].rejInput[key] && infos[index][j].rejInput[key] != 0) {
    //             infos[index][j].rejInput[key] = value
    //           }
    //         }

    //         for (const [key, value] of Object.entries(infos[index][j].rejInput)) {
    //           if (!infos[index][j].rejInfo[key]) {
    //             infos[index][j].rejInput[key] = 0
    //           }
    //         }
    //         let flag2 = false;
    //         for (const [key, value] of Object.entries(infos[index][j].rejInput)) {
    //           if (!infos[index][j].rejInfo[key]) {
    //             flag2 = true;
    //             break
    //           }
    //         }

    //         if (flag2)
    //           infos[index][j].rejInput = infos[index][j].rejInfo



    //         latestRej = infos[index][j].rejInfo
    //       }

    //     }

    //   }
    // }

    // for (const iii of infos) {
    //   for (const ii of iii) {
    //     if (ii.rejQty) {
    //       this.firestore.collection('QualityCheckLog').doc(ii.path).collection('PO').doc(ii.path2).update({
    //         "Rejected Input": ii.rejInput,
    //         "Rejected Information": ii.rejInfo,

    //       })
    //     }
    //   }
    // }


     for (const iii of infos) {
      for (const ii of iii) {
            if(!ii.Skip && ii.Input){
              console.log(ii);
              this.firestore.collection('QualityCheckLog').doc(ii.path).collection('PO').doc(ii.path2).update({
                Input:ii.Input,
                IsOverride: ii.IsOverride
              })

            }


      }
     }




  }

}
