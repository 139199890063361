import { OldStock } from 'src/app/Services/Object_Classes/RawMaterial/OldStock';
import { RawMaterialNeeded } from 'src/app/Services/Object_Classes/Machine/Machine';
import { RawMaterialOrder, ReceivedDetails } from './../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { ToastrService } from 'ngx-toastr';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';

@Component({
  selector: 'app-available-stock',
  templateUrl: './available-stock.component.html',
  styleUrls: ['./available-stock.component.css']
})
export class AvailableStockComponent implements OnInit {

  rawMaterialNeeded:RawMaterialNeeded[] =  [];
  availableRawPO:RawMaterialOrder[]=[];
  oldStock:OldStock[]=[];
  s: any[] = [];
  availableStocks = [];


  constructor(
    private dialogRef: MatDialogRef<AvailableStockComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
  ) {
    this.rawMaterialNeeded  = data.rawMaterialNeeded
    this.availableRawPO  = data.availableRawPO
    this.oldStock  = data.oldStock

    this.s = this.availableRawPO.map(e=>e.ReceivedDetailsList);
    this.s = this.s.reduce((a,b) =>a.concat(b),[]);

    this.availableStocks = [];
    console.log(this.availableRawPO);

    for (const stocks of this.s) {
      let found = this.availableStocks.find(e=>e.Lot_Number === stocks.Lot_Number && e.MaterialID === stocks.MaterialID);
      if(found != null){
        found.Received_Quantity = (parseFloat(found.Received_Quantity) + parseFloat(stocks.Received_Quantity)).toString();

        if(!found.DeductedQuantity)
          found.DeductedQuantity = '0';
        if(!stocks.DeductedQuantity)
          stocks.DeductedQuantity = '0';

        found.DeductedQuantity = (parseFloat(found.DeductedQuantity) + parseFloat(stocks.DeductedQuantity)).toString();
        found.AvailableQuantity =  parseFloat(found.Received_Quantity) - parseFloat(found.DeductedQuantity)

        if(!found.Rack_Number.includes(stocks.Rack_Number)){
          found.Rack_Number += found.Rack_Number + ', ' + stocks.Rack_Number;
        }

      }else{
        let info ={
          Lot_Number: stocks.Lot_Number,
          MaterialID: stocks.MaterialID,
          MaterialName: stocks.MaterialName,
          DeductedQuantity: stocks.DeductedQuantity,
          Received_Quantity: stocks.Received_Quantity,
          Rack_Number: stocks.Rack_Number,
          AvailableQuantity: parseFloat(stocks.Received_Quantity) - parseFloat(stocks.DeductedQuantity)
        }
        this.availableStocks.push(info);
      }
    }


  }

  ngOnInit(){

  }
  cancel() {
    this.dialogRef.close(false);
  }

  parFloat(value){
    return parseFloat(value);
  }

  iNaN(value){
    return isNaN(value);
  }

}
