import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { StocksComponent } from '../../parts/PopUpModal/stocks/stocks.component';

@Component({
  selector: 'app-downtime-details',
  templateUrl: './downtime-details.component.html',
  styleUrls: ['./downtime-details.component.css']
})
export class DowntimeDetailsComponent implements OnInit {


  InterruptCodeList = [
    {
      value: 'PB',
      ViewValue: 'Pin Broken',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'PS',
      ViewValue: 'Part Stuck',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'DE',
      ViewValue: 'Dented',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'FLH',
      ViewValue: 'Flashing',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'GHL',
      ViewValue: 'Gate Hair Line',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'GB',
      ViewValue: 'Runner / Gate Broken',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'IB',
      ViewValue: 'Insert Broken',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'DO',
      ViewValue: 'Dimension Out',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'EPM',
      ViewValue: 'Ejector Pin Mark',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'SG',
      ViewValue: 'Sprue Gate',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'SP',
      ViewValue: 'Slider Problem',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'MC',
      ViewValue: 'Mold Change',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'BM',
      ViewValue: 'Burn Mark',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'EJM',
      ViewValue: 'Ejector Dirty Mark',
      Color: '#ffcc99',
      Category: "MOLD"
    },
    {
      value: 'MOLD_OTH',
      ViewValue: 'Mold Others',
      Color: '#ffcc99',
      Category: "MOLD"
    },

    {
      value: 'MUM',
      ViewValue: 'Machine Under Maintainance',
      Color: '#8c9396',
      Category: "MACHINE"
    },

    {
      value: 'CP',
      ViewValue: 'Contactor Problem',
      Color: '#8c9396',
      Category: "MACHINE"
    },

    {
      value: 'HP',
      ViewValue: 'Heater Problem',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'NP',
      ViewValue: 'Nozel Problem',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'BP',
      ViewValue: 'Barrel Problem',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'SBP',
      ViewValue: 'Screw Barrel Problem',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'BTH',
      ViewValue: 'Barrel Temperature High',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'WL',
      ViewValue: 'Oil / Water Leaking',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'MM',
      ViewValue: 'Moisture Mark',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'SM',
      ViewValue: 'Sink Mark',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'SMD',
      ViewValue: 'Short Moulding',
      Color: '#8c9396',
      Category: "MACHINE"
    },

    {
      value: 'BUB',
      ViewValue: 'Bubble',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'NL',
      ViewValue: 'Nozel Leaking',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'MAC_OTH',
      ViewValue: 'Machine Others',
      Color: '#8c9396',
      Category: "MACHINE"
    },
    {
      value: 'NJO',
      ViewValue: 'No Job Order',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'NMP',
      ViewValue: 'No Man Power',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'BT',
      ViewValue: 'Break Time',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'CS',
      ViewValue: 'Change Shift',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'SUN',
      ViewValue: 'Sunday',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'PH',
      ViewValue: 'Public Holiday',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'MAN_OTH',
      ViewValue: 'Man Others',
      Color: '#E0ec68',
      Category: "MAN"
    },
    {
      value: 'CD',
      ViewValue: 'Colour Different',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'BD',
      ViewValue: 'Black Dot',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'NM',
      ViewValue: 'No Material',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'MUP',
      ViewValue: 'Material Under Preheat',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'MS',
      ViewValue: 'Material Stuck',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'MAT_OTH',
      ViewValue: 'Material Others',
      Color: '#ffffff',
      Category: "MATERIAL"
    },
    {
      value: 'RAP',
      ViewValue: 'Robotic Arm Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'HP',
      ViewValue: 'Hopper Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'HRP',
      ViewValue: 'Hot Runner Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'COMP_P',
      ViewValue: 'Compressure Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'CTP',
      ViewValue: 'Cooling Tower Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'WP',
      ViewValue: 'Hot Oil / Water Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'ALP',
      ViewValue: 'Auto Loader Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'WI',
      ViewValue: 'Wifi Issue',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'SCP',
      ViewValue: 'Screen Problem',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'HMII',
      ViewValue: 'HMI Issue',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },
    {
      value: 'EO',
      ViewValue: 'Equipment Others',
      Color: '#6896ec',
      Category: "EQUIPMENT"
    },

  ]

  job: any[] = [];
  constructor(
    private dialogRef: MatDialogRef<StocksComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private changeDef: ChangeDetectorRef

  ) {
    this.job = data;
  }
  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }

  getCode(value) {
    return this.InterruptCodeList.find(i => i.value === value)?this.InterruptCodeList.find(i => i.value === value).ViewValue: 'Others';
  }



}
