import { reduce, startWith } from 'rxjs/operators';
import { ChangeDetectorRef } from '@angular/core';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { DetailsComponent } from '../details/details.component';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
const cloneDeep = require('lodash.clonedeep')
import jsPDF from 'jspdf';

@Component({
  selector: 'app-stocks',
  templateUrl: './stocks.component.html',
  styleUrls: ['./stocks.component.css']
})
export class StocksComponent implements OnInit {
  qrCodes = [];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;

  stock: any[] = [];
  reservedStock = [];
  type : any;
  notQc: any = [];
  cloneStock: any[] = []
  part: Part
  constructor(
    private dialogRef: MatDialogRef<StocksComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService,
    private changeDef: ChangeDetectorRef

  ) {
    this.type = data.type;
    this.part = data.part;
    if (data.type === 'stock') {
      this.db.database.ref('Part/' + data.Part_No).once('value').then(datasnapshot => {
        datasnapshot.child('Stock').forEach(e => {
          const info = {
            id: e.key,
            rackingNo:e.key.split('@@')[2] || '',
            warehouseLocation:e.key.split('@@')[3] || '',
            value: e.val()
          }
          this.db.database.ref('QC/'+e.key.split('@@')[0]).once('value').then(qc=>{
            if(qc.exists()){
              qc.forEach(child=>{
                if(child.key === data.Part_No){
                  info.rackingNo = child.child('Weighting').val()['RackingNo'];
                  info.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];
                  this.stock.push(info)
                  this.cloneStock = cloneDeep(this.stock);
                }
              })
            }else{
              this.stock.push(info)
              this.cloneStock = cloneDeep(this.stock);
            }
          })
        })
        this.spinner.hide();
      })
    }
    else if (data.type === 'reserved') {
      this.reservedStock = data.stock;
      this.notQc = data.notQc;
      for (const stk of this.stock) {
        this.db.database.ref('QC/'+stk.id.split('@@')[0]).once('value').then(qc=>{
          if(qc.exists()){
            qc.forEach(child=>{
              if(child.key === data.Part_No){
                stk.rackingNo = child.child('Weighting').val()['RackingNo'];
                stk.warehouseLocation = child.child('Weighting').val()['WarehouseLocation'];

              }
            })
          }
        })
      }
      this.spinner.hide();

    }

  }

  back(){
    this.display = false;
  }

  display = false;
  viewDetail(data){
    this.stock = this.reservedStock.filter(e=> e.poNumber === data.poNumber).slice();
    this.display = true;
  }

  findDetail(data){
    return this.reservedStock.find(e=> e.poNumber === data.poNumber)? true:false;
  }

  returnColor(data){
    const sto = this.reservedStock.filter(e=> e.poNumber === data.poNumber).slice();
    let value = 0;
    for (const s of sto) {
      value += s.value;
    }
    if(value === parseFloat(data.value))
      return 'green';
    else
      return 'red';
  }

  ngOnInit() {
    this.spinner.show();
  }
  cancel() {
    this.dialogRef.close(false);
  }

  update(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm update old stock information?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        for (const s of this.cloneStock) {
          await this.db.database.ref('Part/' + s.id.split('@@')[1] + '/Stock/' + s.id).set(null);
        }

        for (const s of this.stock) {
          let path = s.id.split('@@')[0] + '@@' +  s.id.split('@@')[1] + '@@' + s.rackingNo + '@@' + s.warehouseLocation;
          this.db.database.ref('Part/' + s.id.split('@@')[1] + '/Stock/' + path).set(s.value);
        }
        this.spinner.hide();
        this.dialogRef.close(true);
      }
    });

  }

  getBase64Image(img) {
    const canvas = document.createElement('canvas');
    canvas.width = img.width;
    canvas.height = img.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(img, 0,0, 125,   125);

    const dataURL = canvas.toDataURL('image/png');
    return dataURL;
  }

  confirm(quantity, label) {
    this.qrCodes = [];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate label?';

    var numberOfLabel = Math.ceil(quantity / this.part.Box_Quantity);
    var qty = quantity;

    for(let i =1; i<=numberOfLabel+1;i++){

      if (qty < this.part.Box_Quantity) {
        const qr = {
          id:i,
          partDetail:this.part,
          value:  label + '*'+ this.part.Part_No+'*'+qty
        }
        this.qrCodes.push(qr);
      } else {
        const qr = {
          id:i,
          partDetail:this.part,
          value:   label + '*'+ this.part.Part_No+'*'+this.part.Box_Quantity
        }
        this.qrCodes.push(qr);
      }
      qty -= this.part.Box_Quantity;
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        await this.generateLabel(this.part, quantity,numberOfLabel);
        this.qrCodes = [];
        this.spinner.hide();
      }
    });
  }

  generateLabel(info: Part, qty,numberOfLabel): Promise<void> {
    const doc = new jsPDF('portrait','mm','a5');
    var remaining = qty;

    for (let index = 1; index <= numberOfLabel; index++) {
      const qrcode = document.getElementById(index.toString());
      const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
      var r = remaining;
      remaining -= info.Box_Quantity;
      doc.setFontSize(12);
      doc.setLineWidth(0.2);
      doc.line(2, 2, 145, 2);    //horizontal line
      doc.line(2, 2, 2, 190);    //vertical line
      doc.line(145, 2, 145, 190);
      doc.line(70, 2, 70, 190);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(1) Receiver', 4, 7);
      doc.setFontType('unbold');
      doc.setFontSize(10);
      const text = info.Customer_Name + "\n" + info.Customer_Address;
      const splitTitle = doc.splitTextToSize(text, 60);
      doc.text(splitTitle, 4, 12);
      doc.setFontType('bold');
      doc.text('(2) Product Photo', 72, 7);
      const photo = document.createElement('img');
      photo.src = info.PhotoURL;
      doc.addImage(photo, 'PNG', 90, 7, 30, 30);
      doc.line(2, 39 , 145, 39);
      doc.setFontType('bold');
      doc.text('(3) Invoice / Packing List No.' , 4, 45);
      doc.setFontType('unbold');
      doc.setFontSize(12);
      doc.setFontSize(10);
      doc.setFontType('bold');
      doc.text('(4) Supplier Address', 72, 45);
      doc.setFontType('unbold');
      doc.setFontSize(7);
      doc.setFontType('Tunga');
      doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
      doc.text('NO 12 & 14 LORONG PERINDUSTRIAN BUKIT MINYAK 5,', 72, 55);
      doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 60);
      doc.text('14100 BUKIT MERTAJAM, PULAU PINANG, WEST MALAYSIA', 72, 65);
      doc.line(70, 68, 145, 68);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(5) Net WT', 72, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.line(91, 68, 91, 78);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(6) Gross WT', 92, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.line(120, 68, 120, 78);
      doc.setFontType('bold');
      doc.setFontSize(10);
      doc.text('(7) NO Boxes', 122, 72);
      doc.setFontType('unbold');
      doc.setFontSize(9);
      doc.text( index + ' of ' + numberOfLabel, 126, 76);
      doc.line(2, 78 , 145, 78);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(8) QR Code', 4, 83);
      doc.addImage(imageData, 'JPG', 10, 85);
      doc.text('(9) Customer Part NO (P)', 72, 83);
      doc.setFontType('bold');
      doc.setFontSize(18);
      const splt = doc.splitTextToSize(info.Part_No, 60);
      doc.text(splt, 75, 100);
      doc.line(2, 120 , 145, 120);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(10) Quantity ' , 4, 125);
      doc.setFontType('unbold');
      doc.setFontSize(14);
      if(remaining > 0)
        doc.text(info.Box_Quantity + " PIECES", 5, 135);
      else
        doc.text(r + " PIECES", 5, 135);

      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(11) Description', 72, 125);
      doc.setFontSize(14);
      doc.setFontType('unbold');
      const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
      doc.text(splitTitle2, 75, 135);
      doc.line(2, 145 , 145, 145);
      doc.setFontType('bold');
      doc.setFontSize(12);
      doc.text('(12) QC / OPERATOR NAME', 4, 150);
      doc.text('(14) Production Date', 72, 150);
      doc.text('Date', 75, 160);
      doc.text('Month', 87, 160);
      doc.text('Year', 105, 160);
      doc.text('M/C', 120, 160);
      doc.line(75, 165, 130, 165);
      doc.line(75, 165, 75, 180);
      doc.line(88, 165, 88, 180);
      doc.line(100, 165, 100, 180);
      doc.line(118, 165, 118, 180);
      doc.line(130, 165, 130, 180);
      doc.line(75, 180, 130, 180);
      doc.line(2, 190, 145, 190);

      if(index < numberOfLabel){
        doc.addPage();
      }
    }
    doc.save(info.Part_No+ 'OldLabel.pdf');
    return null;

  }

}
