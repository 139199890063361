import { MaintenanceReport, ConditionReport } from './../../../Services/Object_Classes/Mold/Mold';
import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-report-info',
  templateUrl: './report-info.component.html',
  styleUrls: ['./report-info.component.css']
})
export class ReportInfoComponent implements OnInit {

  info: any;
  imageList: any;
  currencyList = Currency;
  imageUrls:any;
  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<ReportInfoComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {

    this.info = data.data;
    this.imageList = data.imageList;


  }

  close(): void {
    this.dialogRef.close();
  }

}
