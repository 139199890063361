import { Component, Inject, OnInit } from '@angular/core';
import { NgModel } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
import { Currency } from 'src/app/Shared/Currency';

@Component({
  selector: 'app-add-customer',
  templateUrl: './add-customer.component.html',
  styleUrls: ['./add-customer.component.css']
})
export class AddCustomerComponent implements OnInit {

  Customer={
    customerName: '',
    pic: '',
    address: '',
    email: '',
    currency: '',
    phone: '',
  };
  currencyList = Currency;

  ngOnInit() {
  }
  constructor(
    public dialogRef: MatDialogRef<AddCustomerComponent>,
    @Inject(MAT_DIALOG_DATA) public data) {
      if(data){
        this.Customer = data;
      }
    }

  close(): void {
    this.dialogRef.close();
  }

  confirm(){
    this.dialogRef.close(this.Customer);
  }

  emailFlag= false;
  phoneFlag= false;

  emailChange(event, model: NgModel) {
    if (!(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(model.value))){
      model.control.setErrors({ invalid: true });
      this.emailFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

  phoneChange(event,model:NgModel){

    if ((!(/^\d+$/.test(model.value)))){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else if (model.value.length < 8 || model.value.length > 13){
      model.control.setErrors({ invalid: true });
      this.phoneFlag = true;
    }else{
      this.emailFlag = false;
    }
  }

}
