import { RawMaterialUsed } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Machine } from './../Object_Classes/Machine/Machine';
import { TakeFromStock } from './../Object_Classes/PackingList/PackingList';
import { BoxShippingInfo, CheckOut, RawCheckOut } from './../Object_Classes/PurchaseOrder/PurchaseOrder';
import { AngularFireDatabase } from '@angular/fire/database';
import { PurchaseOrder, PartTracker } from '../Object_Classes/PurchaseOrder/PurchaseOrder';
export class PODB_controller {
  constructor(private db: AngularFireDatabase) {
  }
  /************** Purchase Order ****************/
  async getPOList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();

        var part_trackList: PartTracker[] = [];
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          let part_track = new PartTracker;
          var boxList: BoxShippingInfo[] = [];
          var checkout: CheckOut[] = [];
          childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
            let box = new BoxShippingInfo;
            box.Box_No = childSnapshot3.key;
            box.Box_Status = childSnapshot3.child('Box Status').val();
            box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
            box.Status = childSnapshot3.child('Status').val();
            box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
            box.Updated_Date = new Date(childSnapshot3.child('Date').val());
            boxList.push(box);
          });
          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              out.Raw_PO = childSnapshot3.key;
              r.Raw_ID = childSnapshot4.child('RawMaterialID').val();
              r.Raw_Name = childSnapshot4.child("RawMaterialName").val();
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              r.LotNumber = childSnapshot4.child("LotNum").val();

              if(!r.Raw_ID){
                childSnapshot4.child("Boxes").forEach(childSnapshot5=>{
                  r.Raw_ID = childSnapshot5.child('RawMaterialID').val();
                  r.LotNumber = childSnapshot5.child('LotNum').val();
                  r.Raw_Name = childSnapshot5.child("RawMaterialName").val();
                })
              }
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });

          childSnapshot2.child("RawMaterials").forEach(childSnapshot3=>{
            let raws = new RawMaterialUsed;
            raws.MaterialID = childSnapshot3.child("ID").val();
            raws.MaterialName = childSnapshot3.child("Name").val();
            raws.Quantity = childSnapshot3.child("Quantity Needed").val();
            raws.Type = childSnapshot3.child("Type").val();
            part_track.RawMaterialsUsed.push(raws);
          })
          childSnapshot2.child("Box").forEach(childSnapshot3=>{
            let raws = new RawMaterialUsed;
            raws.MaterialID = childSnapshot3.child("ID").val();
            raws.MaterialName = childSnapshot3.child("Name").val();
            raws.Quantity = childSnapshot3.child("Quantity Needed").val();
            raws.Type = childSnapshot3.child("Type").val();
            part_track.RawMaterialsUsed.push(raws);
          })


          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_No = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.Reference = childSnapshot2.child("Reference").val();
          part_track.POS = childSnapshot2.child("POS").val();
          part_track.PIC = childSnapshot2.child("PIC").val();
          part_track.MachineNo = childSnapshot2.child("MachineNo").val() || "";
          part_track.MachineNo = part_track.MachineNo.replace('@@','');
          part_track.MachineNo = part_track.MachineNo.replace('_1','');
          part_track.MachineNo = part_track.MachineNo.replace('_2','');
          part_track.subFrom = childSnapshot2.child("SubFrom").val();
          part_track.BoxInfo_List = boxList;

          this.getPartInfo(part_track);
          part_trackList.push(part_track);

        });

        for (let i = 0; i < part_trackList.length; i++) {
          if(part_trackList[i].subFrom){
            const result = part_trackList.find(e=> e.ID === part_trackList[i].subFrom);
            if(result){
              result.subPart.push(part_trackList[i]);
              part_trackList.splice(i,1)
              i = i-1;
            }
          }

        }

        PO_item.PO_Part_List = part_trackList;
        PO_list.push(PO_item);
      });
    }
    return PO_list;
  }

  async getPOListForPackingList(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    var snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((childSnapshot) => {
        let PO_item = new PurchaseOrder();
        PO_item.PO_No = childSnapshot.key;
        PO_item.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        PO_item.Created_By = childSnapshot.child('_Created_By').val();
        PO_item.Updated_Date = new Date(childSnapshot.child('_Updated_Date').val());
        PO_item.Updated_By = childSnapshot.child('_Updated_By').val();

        var part_trackList: PartTracker[] = [];
        childSnapshot.child("Part List").forEach((childSnapshot2) => {
          let part_track = new PartTracker;
          var boxList: BoxShippingInfo[] = [];
          var checkout: CheckOut[] = [];
          childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
            let box = new BoxShippingInfo;
            box.Box_No = childSnapshot3.key;
            box.Box_Status = childSnapshot3.child('Box Status').val();
            box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
            box.Status = childSnapshot3.child('Status').val();
            box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
            box.Updated_Date = new Date(childSnapshot3.child('Date').val());
            boxList.push(box);
          });
          childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
            let out = new CheckOut;
            var raw: RawCheckOut[] = [];
            childSnapshot3.forEach(childSnapshot4 => {
              let r = new RawCheckOut;
              out.Raw_PO = childSnapshot4.key;
              r.Raw_ID = childSnapshot4.child('RawMaterialID').val();
              r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
              r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
              raw.push(r);
            })
            out.RawCheckOutList = raw;
            checkout.push(out);
          });
          part_track.ID = childSnapshot2.key;
          part_track.CheckOut_RawList = checkout;
          part_track.PO_Part_No = childSnapshot2.child("Part No").val();
          part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
          part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
          part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
          part_track.PO_Status = childSnapshot2.child("Status").val();
          part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
          part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
          part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
          part_track.Reference = childSnapshot2.child("Reference").val();
          part_track.POS = childSnapshot2.child("POS").val();
          part_track.PIC = childSnapshot2.child("PIC").val();
          part_track.MachineNo = childSnapshot2.child("MachineNo").val() || "";
          part_track.MachineNo = part_track.MachineNo.replace('@@','');
          part_track.MachineNo = part_track.MachineNo.replace('_1','');
          part_track.MachineNo = part_track.MachineNo.replace('_2','');
          part_track.subFrom = childSnapshot2.child("SubFrom").val();
          part_track.BoxInfo_List = boxList;

          this.getPartInfo(part_track);
          part_trackList.push(part_track);
        });

        PO_item.PO_Part_List = part_trackList;
        PO_list.push(PO_item);
      });
    }
    return PO_list;
  }
  async addPO(_newPO: any, email) {
    for (const data of _newPO.parts) {
      let updates = {};
      if (data.partNumber2) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity2,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber2,
          'Part Name': data.partName2,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_1' + '/Schedule/' + data.scheduleID2).set(info)

        updates['LinkedPart2'] = data.partNumber2;
        updates['LinkedPart2ScheduleID'] = data.scheduleID2;
      }
      if (data.partNumber3) {
        const info = {
          'Accumulate Quantity': 0,
          'Expected Quantity': data.quantity,
          'PO Quantity': data.poquantity3,
          'Code': '-',
          'PO No': _newPO.POName,
          'Part No': data.partNumber3,
          'Part Name': data.partName3,
          'Schedule Status': 'Waiting',
          'startAt': data.startTime.toISOString(),
          'endAt': data.endTime.toISOString(),
          'presetStart': '-',
          'presetEnd': '-',
          'downStart': 0,
          'downEnd': 0,
          'Total Down Time': 0,
          'Availability': 0,
          'Performance': 0,
          'Effectiveness': 0,
        }
        await this.db.database.ref('Machine/@@' + data.machineChosen + '_2' + '/Schedule/' + data.scheduleID3).set(info)
        updates['LinkedPart3'] = data.partNumber3;
        updates['LinkedPart3ScheduleID'] = data.scheduleID3;
      }
      updates['Accumulate Quantity'] = 0;
      updates['Expected Quantity'] = data.quantity;
      updates['PO Quantity'] = data.poquantity;
      updates['Code'] = '-';
      updates['PO No'] = _newPO.POName;
      updates['Part No'] = data.partNumber;
      updates['Schedule Status'] = 'Waiting';
      updates['startAt'] = data.startTime.toISOString();
      updates['endAt'] = data.endTime.toISOString();
      updates['presetStart'] = '-';
      updates['presetEnd'] = '-';
      updates['downStart'] = 0;
      updates['downEnd'] = 0;
      updates['Total Down Time'] = 0;
      updates['Availability'] = 0;
      updates['Performance'] = 0;
      updates['Effectiveness'] = 0;
      await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID).set(updates, async () => {

        for (const element of data.raw.Raw_Material) {
          if (element.check) {
            this.db.database.ref('RawMaterial/' + element.Material_ID).once('value').then(datasnap => {
              let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());

              quantity += parseFloat(element.quantityNeeded);
              this.db.database.ref('RawMaterial/' + element.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
            })
            const info = {}
            info['ID'] = element.Material_ID;
            info['Name'] = element.Material_Name;
            info['Quantity Needed'] = element.quantityNeeded;
            info['Type'] = element.Raw_Type;
            await this.db.database.ref('Machine/' + data.machineChosen + '/Schedule/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(info)
          }
        }
      })
    }
    let newPO = {};

    newPO['_Created_Date'] = new Date().toISOString();
    newPO['_Updated_Date'] = new Date().toISOString();
    newPO['_Created_By'] = email;
    newPO['_Updated_By'] = email;
    await this.db.database.ref('Purchase Order/' + _newPO.POName).set(newPO);

    for (const data of _newPO.parts) {
      if (data.partNumber2) {
        const info = {}
        info['Part No'] = data.partNumber2;
        info['Part Name'] = data.partName2;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity2;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_1';
        info['POS'] = data.pos2;
        info['Reference'] = data.reference2;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID2).set(info);

      }
      if (data.partNumber3) {
        const info = {}
        info['Part No'] = data.partNumber3;
        info['Part Name'] = data.partName3;
        info['Part Quantity'] = data.quantity;
        info['PO Quantity'] = data.poquantity3;
        info['Status'] = "Waiting";
        info['_Completed_Date'] = "";
        info['Added to packing'] = false;
        info['Sub'] = true;
        info['SubFrom'] = data.scheduleID;
        info['MachineNo'] = '@@' + data.machineChosen + '_2';
        info['POS'] = data.pos3;
        info['Reference'] = data.reference3;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID3).set(info);

      }
      const info = {}
      info['Part No'] = data.partNumber;
      info['Part Name'] = data.partName;
      info['Part Quantity'] = data.quantity;
      info['PO Quantity'] = data.poquantity;
      info['Status'] = "Waiting";
      info['_Completed_Date'] = "";
      info['Added to packing'] = false;
      info['MachineNo'] = data.machineChosen;
      info['POS'] = data.pos;
      info['Reference'] = data.reference;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID).set(info);

      for (const element of data.raw.Raw_Material) {
        if (element.check) {
          const inf = {}
          inf['ID'] = element.Material_ID;
          inf['Name'] = element.Material_Name;
          inf['Quantity Needed'] = element.quantityNeeded;
          inf['Type'] = element.Raw_Type;
          await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/RawMaterials/' + element.Material_ID).set(inf);
        }
      }

      if (data.raw.innerCheck) {
        const innerBox = {}
        innerBox['ID'] = data.raw.InnerBox.Material_ID;
        innerBox['Name'] = data.raw.InnerBox.Material_Name;
        innerBox['Type'] = data.raw.InnerBox.Raw_Type;
        innerBox['Quantity Needed'] = data.raw.innerBoxNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/InnerBox').set(innerBox);

        this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.innerBoxNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.InnerBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      if (data.raw.polyCheck) {
        const polybox = {}
        polybox['ID'] = data.raw.PolyBag.Material_ID;
        polybox['Name'] = data.raw.PolyBag.Material_Name;
        polybox['Type'] = data.raw.PolyBag.Raw_Type;
        polybox['Quantity Needed'] = data.raw.polyBagNeeded;
        await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/PolyBag').set(polybox);
        this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID).once('value').then(datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity += parseFloat(data.raw.polyBagNeeded);
          this.db.database.ref('RawMaterial/' + data.raw.PolyBag.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }
      const cartonbox = {}

      cartonbox['ID'] = data.raw.CartonBox.Material_ID;
      cartonbox['Name'] = data.raw.CartonBox.Material_Name;
      cartonbox['Type'] = data.raw.CartonBox.Raw_Type;
      cartonbox['Quantity Needed'] = data.raw.cartonNeeded;
      cartonbox['Carton Weight'] = data.raw.CartonBox.Carton_Weight;
      await this.db.database.ref('Purchase Order/' + _newPO.POName + '/Part List/' + data.scheduleID + '/Box/Carton').set(cartonbox);

      await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID).once('value').then(async datasnap => {
        let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
        quantity += parseFloat(data.raw.cartonNeeded);
        await this.db.database.ref('RawMaterial/' + data.raw.CartonBox.Material_ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
      })
    }


  }

  addPOWithoutSchedule(_newPO: any, email) {
    let updates1 = {};
    _newPO.parts.forEach(data => {
      updates1[_newPO.POName + '/Part List/' + data.id + '/Part No'] = data.partNumber;
      updates1[_newPO.POName + '/Part List/' + data.id + '/Part Name'] = data.partName;
      updates1[_newPO.POName + '/Part List/' + data.id + '/Part Quantity'] = 0;
      updates1[_newPO.POName + '/Part List/' + data.id + '/PO Quantity'] = data.poquantity;
      updates1[_newPO.POName + '/Part List/' + data.id + '/Status'] = "Waiting";
      updates1[_newPO.POName + '/Part List/' + data.id + '/_Completed_Date'] = "";
      updates1[_newPO.POName + '/Part List/' + data.id + '/Added to packing'] = false;
      updates1[_newPO.POName + '/Part List/' + data.id + '/POS'] = data.pos;
      updates1[_newPO.POName + '/Part List/' + data.id + '/Reference'] = data.reference;
    });
    updates1[_newPO.POName + '/_Created_Date'] = new Date();
    updates1[_newPO.POName + '/_Updated_Date'] = new Date();
    updates1[_newPO.POName + '/_Created_By'] = email;
    updates1[_newPO.POName + '/_Updated_By'] = email;

    this.db.database.ref('Purchase Order/').update(updates1);
  }
  async search_PO(PO_No: string): Promise<PurchaseOrder> {
    let searched_PO = new PurchaseOrder;
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let get_PO_No = childSnapshot.key;
        if (PO_No.match(get_PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          let part_trackList: PartTracker[] = [];
          childSnapshot.child("Part List").forEach((function (childSnapshot2) {
            let part_track = new PartTracker;
            var boxList: BoxShippingInfo[] = [];
            var checkout: CheckOut[] = [];
            childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
              let box = new BoxShippingInfo;
              box.Box_No = childSnapshot3.key;
              box.Box_Status = childSnapshot3.child('Box Status').val();
              box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box.Status = childSnapshot3.child('Status').val();
              box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box.Updated_Date = new Date(childSnapshot3.child('Date').val());
              boxList.push(box);
            });
            childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
              let out = new CheckOut;
              var raw: RawCheckOut[] = [];
              out.Raw_PO = childSnapshot3.key;
              childSnapshot3.forEach(childSnapshot4 => {
                let r = new RawCheckOut;
                r.Raw_ID = childSnapshot4.key
                r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
                r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
                raw.push(r);
              })
              out.RawCheckOutList = raw;
              checkout.push(out);
            });
            part_track.CheckOut_RawList = checkout;
            part_track.PO_Part_No = childSnapshot2.child("Part No").val();
            part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
            part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
            part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
            part_track.PO_Status = childSnapshot2.child("Status").val();
            part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
            part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.BoxInfo_List = boxList;
            part_trackList.push(part_track);
          }));
          searched_PO.PO_Part_List = part_trackList;
        }
      }));
    }
    return searched_PO;
  }
  async search_Partial_PO(PO_No: string): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach((function (childSnapshot) {
        let searched_PO = new PurchaseOrder;
        let get_PO_No = childSnapshot.key;
        if (get_PO_No.includes(PO_No)) {
          searched_PO.PO_No = childSnapshot.key;
          let part_trackList: PartTracker[] = [];
          childSnapshot.child("Part List").forEach((function (childSnapshot2) {
            let part_track = new PartTracker;
            var boxList: BoxShippingInfo[] = [];
            var checkout: CheckOut[] = [];
            childSnapshot2.child("Shipping Information").forEach(childSnapshot3 => {
              let box = new BoxShippingInfo;
              box.Box_No = childSnapshot3.key;
              box.Box_Status = childSnapshot3.child('Box Status').val();
              box.Packaging_Quantity = childSnapshot3.child('Packaging Qty').val();
              box.Status = childSnapshot3.child('Status').val();
              box.Weighting_Status = childSnapshot3.child('Weighting Status').val();
              box.Updated_Date = new Date(childSnapshot3.child('Date').val());
              boxList.push(box);
            });
            childSnapshot2.child("Raw Materials Used").forEach(childSnapshot3 => {
              let out = new CheckOut;
              var raw: RawCheckOut[] = [];
              out.Raw_PO = childSnapshot3.key;
              childSnapshot3.forEach(childSnapshot4 => {
                let r = new RawCheckOut;
                r.Raw_ID = childSnapshot4.key
                r.CheckOut_Quantity = childSnapshot4.child('Quantity Checkout').val();
                r.CheckOut_Date = new Date(childSnapshot4.child('Checkout Date').val());
                raw.push(r);
              })
              out.RawCheckOutList = raw;
              checkout.push(out);
            });
            part_track.CheckOut_RawList = checkout;
            part_track.PO_Part_No = childSnapshot2.child("Part No").val();
            part_track.PO_Part_Name = childSnapshot2.child("Part Name").val();
            part_track.PO_Part_Qty = childSnapshot2.child("Part Quantity").val();
            part_track.POQuantity = childSnapshot2.child("PO Quantity").val();
            part_track.PO_Status = childSnapshot2.child("Status").val();
            part_track.Completed_Date = childSnapshot2.child("_Completed_Date").val();
            part_track.PO_Shipping_Date = childSnapshot2.child("Shipping_Date").val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.BoxInfo_List = boxList;
            part_trackList.push(part_track);
          }));
          searched_PO.PO_Part_List = part_trackList;
          PO_list.push(searched_PO);
        }
      }));
    }
    return PO_list;
  }
  delete_PO(PO_No: string, machinelist) {
    const de = [];
    this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value').then(async snapshot => {
      snapshot.forEach(deleteSchedule => {
        if (deleteSchedule.child('RawMaterials').val()) {
          deleteSchedule.child('RawMaterials').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }
        if (deleteSchedule.child('Box').val()) {
          deleteSchedule.child('Box').forEach(e => {
            const info = {
              ID: e.child('ID').val(),
              value: parseFloat(e.child('Quantity Needed').val())
            }
            if (de.length > 0) {
              const result = de.findIndex(e => e.ID === info.ID);
              if (result !== -1) {
                de[result].value += parseFloat(e.child('Quantity Needed').val());
              } else {
                de.push(info);
              }
            } else {
              de.push(info);
            }
          })
        }

        machinelist.forEach(element => {
          this.db.database.ref('/Machine/' + element + '/Schedule/' + deleteSchedule.key).set(null);
        });
      })
    }).finally(() => {
      this.db.database.ref('/Purchase Order/' + PO_No).set(null);
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

    })
  }
  //Temporary
  async delete_Schedule(PO_No, machine, id, schedule) {
    const de = [];

    if(schedule.LinkedPart2ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart2ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_1/Schedule/' + schedule.LinkedPart2ScheduleID).set(null);
      });
    }
    if(schedule.LinkedPart3ScheduleID){
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + schedule.LinkedPart3ScheduleID).set(null).then(async result => {
        await this.db.database.ref('/Machine/@@' + machine + '_2/Schedule/' + schedule.LinkedPart3ScheduleID).set(null);
      });
    }

    await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }

      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })


  }

  async delete_ScheduleFromPO(PO_No, machine, id, subpart) {
    const de = [];

    for (const part of subpart) {
      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + part.ID).set(null).then(async result => {
        await this.db.database.ref('/Machine/'+ part.MachineNo+'/Schedule/' + part.ID).set(null);
      });
    }


    await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).once('value').then(async snapshot => {
      if (snapshot.child('RawMaterials').val()) {
        snapshot.child('RawMaterials').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
      if (snapshot.child('Box').val()) {
        snapshot.child('Box').forEach(e => {
          const info = {
            ID: e.child('ID').val(),
            value: parseFloat(e.child('Quantity Needed').val())
          }
          if (de.length > 0) {
            const result = de.findIndex(e => e.ID === info.ID);
            if (result !== -1) {
              de[result].value += parseFloat(e.child('Quantity Needed').val());
            } else {
              de.push(info);
            }
          } else {
            de.push(info);
          }
        })
      }
    }).finally(() => {
      for (const e of de) {
        this.db.database.ref('RawMaterial/' + e.ID).once('value').then(async datasnap => {
          let quantity = parseFloat(datasnap.child('_Quantity_Reserved').val());
          quantity -= parseFloat(e.value);
          if (isNaN(quantity)) {
            quantity = 0;
          }
          await this.db.database.ref('RawMaterial/' + e.ID + '/_Quantity_Reserved').set(quantity.toFixed(2));
        })
      }




      this.db.database.ref('/Purchase Order/' + PO_No + '/Part List/' + id).set(null).then(async result => {
        await this.db.database.ref('/Machine/' + machine + '/Schedule/' + id).set(null);
        const snapshot = await this.db.database.ref('/Purchase Order/' + PO_No + '/Part List').once('value')
        if (!snapshot.exists()) {
          await this.db.database.ref('/Purchase Order/' + PO_No).set(null)
        }
      });
    })


  }
  async search_PO_withStatusCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrder;
        searched_PO.PO_No = childSnapshot.key;
        searched_PO.Created_Date = new Date(childSnapshot.child('_Created_Date').val());
        let part_trackList: PartTracker[] = [];
        childSnapshot.child('Part List').forEach(childSnapshot2 => {
          let part_track = new PartTracker;
          if (childSnapshot2.child('Added to packing').val() === true) {
            part_track.PO_Part_No = childSnapshot2.child('Part No').val();
            part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
            part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
            part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
            // part_track.Completed_Date = new Date(childSnapshot2.child('_Completed_Date').val());
            childSnapshot2.child("Take From Stocks").forEach(stock => {
              const sto = new TakeFromStock();
              sto.BoxNumber = stock.key;
              sto.DeductedQuantity = stock.val();
              part_track.TakeFromStocks.push(sto);
            })
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.CartonBoxNeeded = childSnapshot2.child("Box/Carton/Quantity Needed").val();
            if(childSnapshot2.child('Sub').val()){
              part_track.Carton_Weight = childSnapshot.child("Part List/"+childSnapshot2.child('SubFrom').val()+"/Box/Carton/Carton Weight").val();
            }else{
              part_track.Carton_Weight = childSnapshot2.child("Box/Carton/Carton Weight").val();
            }
            part_track.Reference = childSnapshot2.child("Reference").val();
            part_track.POS = childSnapshot2.child("POS").val();
            this.getPartInfo(part_track);
            this.getInvoice(searched_PO.PO_No, part_track);
            part_trackList.push(part_track);
          }
        });
        searched_PO.PO_Part_List = part_trackList;
        if (searched_PO.PO_Part_List.length > 0)
          PO_list.push(searched_PO);
      });
    }
    return PO_list;
  }

  async search_PO_withStatusNotCompleted(): Promise<PurchaseOrder[]> {
    let PO_list: PurchaseOrder[] = [];
    let snapshot = await this.db.database.ref('Purchase Order').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        let searched_PO = new PurchaseOrder;
        searched_PO.PO_No = childSnapshot.key;

        let part_trackList: PartTracker[] = [];
        childSnapshot.child('Part List').forEach(childSnapshot2 => {
          let part_track = new PartTracker;
          part_track.ID = childSnapshot2.key;
          if (childSnapshot2.child('Added to packing').val() === false) {
            part_track.PO_Part_No = childSnapshot2.child('Part No').val();
            part_track.PO_Part_Name = childSnapshot2.child('Part Name').val();
            part_track.PO_Part_Qty = childSnapshot2.child('Part Quantity').val();
            part_track.POQuantity = childSnapshot2.child('PO Quantity').val();
            part_track.Added_TO_Packing = childSnapshot2.child("Added to packing").val();
            part_track.CartonBoxNeeded = childSnapshot2.child("Box/Carton/Quantity Needed").val();
            part_track.Carton_Weight = childSnapshot2.child("Box/Carton/Carton Weight").val();
            part_track.Reference = childSnapshot2.child("Reference").val();
            part_track.POS = childSnapshot2.child("POS").val();
            this.getPartInfo(part_track);
            part_trackList.push(part_track);
          }
        });
        searched_PO.PO_Part_List = part_trackList;
        if (searched_PO.PO_Part_List.length > 0)
          PO_list.push(searched_PO);
      });
    }
    return PO_list;
  }
  async getPartInfo(pt: PartTracker): Promise<void> {
    if(pt.PO_Part_No){
      var snapshot = await this.db.database.ref('Part').child(pt.PO_Part_No).once('value');
      if (snapshot.exists()) {
        pt.Part_Weight = snapshot.child('Part Weight').val();
        pt.PartPhoto = snapshot.child('PhotoURL').val();
        pt.QuantityPerBox = snapshot.child('Packaging/Box/Quantity in Box').val();
        pt.InStock = snapshot.child('Stock Quantity').val();
        pt.RevNO = snapshot.child('RevNO').val()
        var snapshot2 = await this.db.database.ref('RawMaterial').child(snapshot.child('Packaging/Box/Box Size').val()).once('value');
        if(snapshot2.exists())
          pt.Carton_Weight = snapshot2.child('_Carton_Weight').val();
  
        const id = snapshot.child('CustomerID').val()
        if (id) {
          this.getCustomerInfo(id, pt);
        }
      }
    }
   
  }

  async getCustomerInfo(id, pt: PartTracker): Promise<void> {
    var cusSnapshot = await this.db.database.ref('Customer').child(id).once('value');
    if (cusSnapshot.exists()) {
      pt.CustomerName = cusSnapshot.child('_Customer_Name').val();
      pt.CustomerAddress = cusSnapshot.child('_Address').val();
      pt.partCurrency = cusSnapshot.child('_Currency').val();
    }
  }
  async getInvoice(poNumber, pt: PartTracker): Promise<void> {
    var snapshot = await this.db.database.ref('PackingList').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(chilsnapshot => {
        if (chilsnapshot.child('POList').child(poNumber).exists()) {
          pt.PackingListNo = chilsnapshot.key;
          // pt.PackingListNo = chilsnapshot.child('Inv_PackingListNo').val();
        }
      })
    }
  }
}
