import { ReportInfoComponent } from './report-info/report-info.component';
import { MoldDB_Controller } from './../../Services/DB_Controller/MoldDB_Controller';
import { ConditionReport, MaintenanceReport } from './../../Services/Object_Classes/Mold/Mold';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog, MatDialogConfig, MatPaginator, PageEvent, Sort } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExcelHelperService } from 'src/app/Services/Utilities/excel-helper.service';

@Component({
  selector: 'app-mold',
  templateUrl: './mold.component.html',
  styleUrls: ['./mold.component.css']
})
export class MoldComponent implements OnInit {

  email: string;

  conditionReportList: ConditionReport[] = [];
  cloneConditionReportList: ConditionReport[] = [];
  //Pagination
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  search: string;
  sortedu = {
    active: 'createdDate',
    direction: ''
  };
  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;


  maintenanceReportList: MaintenanceReport[] = [];
  cloneMaintenanceReportList: MaintenanceReport[] = [];
  //Pagination
  lengthMat = 0;
  pageSizeMat = 10;
  pageSizeOptionsMat: number[] = [10, 25, 50];
  pageIndexMat = 0;
  offsetMat = this.pageSizeMat * this.pageIndexMat;
  searchMat: string;
  sorteduMat = {
    active: 'createdDate',
    direction: ''
  };
  @ViewChild('topPaginatorMat', { read: MatPaginator, static: true }) topPaginatorMat: MatPaginator;
  @ViewChild('bottomPaginatorMat', { read: MatPaginator, static: true }) bottomPaginatorMat: MatPaginator;

  moldDB_Controller: MoldDB_Controller = new MoldDB_Controller(this.db, this.firestore);


  constructor(
    public dialog: MatDialog,
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private excelHelper: ExcelHelperService,

  ) {

  }
  ngOnInit(): void {
    this.setup();
  }

  setup() {
    this.spinner.show();
    this.initMaintenanceReport();
    this.initConditionReport();
  }

  refresh(type){
    switch(type){
      case 'Condition': this.initConditionReport();break;
      case 'Maintenance': this.initMaintenanceReport(); break;
    }
  }

  initConditionReport(){
    this.moldDB_Controller.getMouldConditionReport().then(data=>{
      this.conditionReportList = data;
      this.length = this.conditionReportList.length;
      this.cloneConditionReportList = this.conditionReportList.slice();
      this.sortCondition();
      this.limitListCondition();
      this.spinner.hide();
    })
  }

  initMaintenanceReport(){
    this.moldDB_Controller.getMouldMaintenanceReport().then(data=>{
      this.maintenanceReportList = data;
      this.lengthMat = this.maintenanceReportList.length;
      this.cloneMaintenanceReportList = this.maintenanceReportList.slice();
      this.sortMat();
      this.limitListMaintenance();
      this.spinner.hide();
    })
  }

  DynamicSearchCondition(Condition: string): void {
    this.search = Condition;
    this.cloneConditionReportList = this.conditionReportList.filter(u =>
      String(u.machineNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.createdBy).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.partNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.mouldNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.mouldTemperature).toLowerCase().includes(this.search.toLowerCase())
      );
    this.length = this.cloneConditionReportList.length;
    this.sortCondition();
    this.limitListCondition();
    this.topPaginator.firstPage()
    this.bottomPaginator.firstPage()
  }
  DSCondition() {
    this.cloneConditionReportList = this.conditionReportList.filter(u =>
      String(u.machineNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.createdBy).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.partNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.mouldNumber).toLowerCase().includes(this.search.toLowerCase())
      ||String(u.mouldTemperature).toLowerCase().includes(this.search.toLowerCase())
      );
      this.length = this.cloneConditionReportList.length;
  }
  paginatorCondition(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    if (this.search) {
      this.DSCondition();
    } else {
      this.cloneConditionReportList = this.conditionReportList.slice();
    }
    this.sortCondition();
    this.limitListCondition();
  }
  limitListCondition() {
    this.cloneConditionReportList = this.conditionReportList.slice(this.offset, (this.offset + this.pageSize));
  }
  sortCondition() {

    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.cloneConditionReportList = this.cloneConditionReportList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'mould': return this.compare(a.mouldNumber, b.mouldNumber, isAsc);
        case 'machine': return this.compare(a.machineNumber, b.machineNumber, isAsc);
        case 'temperature': return this.compare(a.mouldTemperature, b.mouldTemperature, isAsc);
        case 'part': return this.compare(a.partNumber, b.partNumber, isAsc);
        case 'createdBy': return this.compare(a.createdBy, b.createdBy, isAsc);
        case 'createdDate': return this.compareDate(a.createdDate, b.createdDate, isAsc);

        default: return 0;
      }
    });
  }
  sortDataCondition(sort: Sort) {
    this.sortedu = sort;
    this.cloneConditionReportList = this.conditionReportList.slice();
    if (this.search) {
      this.DSCondition();
    }
    if (!sort.active || sort.direction === '' && !this.search) {
      this.cloneConditionReportList = this.conditionReportList.slice();
      this.limitListCondition();
      return;
    }

    this.cloneConditionReportList = this.cloneConditionReportList.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'mould': return this.compare(a.mouldNumber, b.mouldNumber, isAsc);
        case 'machine': return this.compare(a.machineNumber, b.machineNumber, isAsc);
        case 'temperature': return this.compare(a.mouldTemperature, b.mouldTemperature, isAsc);
        case 'part': return this.compare(a.partNumber, b.partNumber, isAsc);
        case 'createdBy': return this.compare(a.createdBy, b.createdBy, isAsc);
        case 'createdDate': return this.compareDate(a.createdDate, b.createdDate, isAsc);
         default: return 0;
      }
    });
    this.limitListCondition();
  }
  exportToExcelCondition() {
    const exportInformation = [];
    this.spinner.show();

    for (const j of this.conditionReportList) {
      const info ={
        "Mould Number":j.mouldNumber || "-",
        "Machine Number":j.machineNumber || "-",
        "Mould Temperature":j.mouldTemperature || "-",
        "Part Number":j.partNumber || "-",
        "Created By ":j.createdBy || "-",
        "Created Date":j.createdDate || "-",
        "F10 Fast Set Setting ":j.f10SettingUrl || "-",
        "F9 Other Setting Function Time": j.f9SettingUrl || "-",
        "F1 Modify Record Cycle Time":j.f1RecordUrl || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'MouldConditionReport');
    this.spinner.hide();

  }

  DynamicSearchMat(Mat: string): void {
    this.searchMat = Mat;
    this.cloneMaintenanceReportList = this.maintenanceReportList.filter(u =>
      String(u.createdBy).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldNumber).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldPlate).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldSize).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.remark).toLowerCase().includes(this.searchMat.toLowerCase())
      );
    this.lengthMat = this.cloneMaintenanceReportList.length;
    this.sortMat();
    this.limitListMaintenance();
    this.topPaginatorMat.firstPage()
    this.bottomPaginatorMat.firstPage()
  }
  DSMat() {
    this.cloneMaintenanceReportList = this.maintenanceReportList.filter(u =>
      String(u.createdBy).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldNumber).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldPlate).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.mouldSize).toLowerCase().includes(this.searchMat.toLowerCase())
      ||String(u.remark).toLowerCase().includes(this.searchMat.toLowerCase())
      );
      this.lengthMat = this.cloneMaintenanceReportList.length;

  }

  paginatorMat(pageEvent: PageEvent) {
    this.pageSizeMat = pageEvent.pageSize;
    this.pageIndexMat = pageEvent.pageIndex;
    this.offsetMat = this.pageSizeMat * this.pageIndexMat;
    if (this.topPaginatorMat.pageIndex < this.pageIndexMat) {
      this.topPaginatorMat.nextPage();
    } else if (this.topPaginatorMat.pageIndex > this.pageIndexMat) {
      this.topPaginatorMat.previousPage();
    }
    if (this.bottomPaginatorMat.pageIndex < this.pageIndexMat) {
      this.bottomPaginatorMat.nextPage();
    } else if (this.bottomPaginatorMat.pageIndex > this.pageIndexMat) {
      this.bottomPaginatorMat.previousPage();
    }
    if (this.searchMat) {
      this.DSMat();
    } else {
      this.cloneMaintenanceReportList = this.maintenanceReportList.slice();
    }
    this.sortMat();
    this.limitListMaintenance();
  }
  limitListMaintenance() {
    this.cloneMaintenanceReportList = this.cloneMaintenanceReportList.slice(this.offsetMat, (this.offsetMat + this.pageSizeMat));
  }
  sortMat() {
    if (!this.sorteduMat.active || this.sorteduMat.direction === '') {
      return;
    }
    this.cloneMaintenanceReportList = this.cloneMaintenanceReportList.sort((a, b) => {
      const isAsc = this.sorteduMat.direction === 'asc';
      switch (this.sorteduMat.active) {
        case 'mould': return this.compare(a.mouldNumber, b.mouldNumber, isAsc);
        case 'plate': return this.compare(a.mouldPlate, b.mouldPlate, isAsc);
        case 'size': return this.compare(a.mouldSize, b.mouldSize, isAsc);
        case 'createdBy': return this.compare(a.createdBy, b.createdBy, isAsc);
        case 'remark': return this.compare(a.remark, b.remark, isAsc);
        case 'createdDate': return this.compareDate(a.createdDate, b.createdDate, isAsc);
        default: return 0;
      }
    });
  }
  sortDataMat(sort: Sort) {
    this.sorteduMat = sort;
    this.cloneMaintenanceReportList = this.maintenanceReportList.slice();
    if (this.searchMat) {
      this.DSMat();
    }
    if (!sort.active || sort.direction === '' && !this.searchMat) {
      this.cloneMaintenanceReportList = this.maintenanceReportList.slice();
      this.limitListMaintenance();
      return;
    }

    this.cloneMaintenanceReportList = this.cloneMaintenanceReportList.sort((a, b) => {
      const isAsc = this.sorteduMat.direction === 'asc';
      switch (this.sorteduMat.active) {
        case 'mould': return this.compare(a.mouldNumber, b.mouldNumber, isAsc);
        case 'plate': return this.compare(a.mouldPlate, b.mouldPlate, isAsc);
        case 'size': return this.compare(a.mouldSize, b.mouldSize, isAsc);
        case 'createdBy': return this.compare(a.createdBy, b.createdBy, isAsc);
        case 'remark': return this.compare(a.remark, b.remark, isAsc);
        case 'createdDate': return this.compareDate(a.createdDate, b.createdDate, isAsc);
         default: return 0;
      }
    });
    this.limitListMaintenance();
  }

  exportToExcelMat() {
    const exportInformation = [];
    this.spinner.show();

    for (const j of this.maintenanceReportList) {
      const info ={
        "Mould Number":j.mouldNumber || "-",
        "Mould Plate":j.mouldPlate || "-",
        "Mould Size":j.mouldSize || "-",
        "Remark Changed Part":j.remark || "-",
        "Created By ":j.createdBy || "-",
        "Created Date":j.createdDate || "-",
        "Core And Cavity After Services":j.coreServiceAfterUrl || "-",
        "Core And Cavity Before Services":j.coreServiceBeforeUrl || "-",
        "Top Side After Services":j.topSideAfterUrl || "-",
        "Top Side Before Services":j.topSideBeforeUrl || "-",

      }
      exportInformation.push(info)
    }
    this.excelHelper.exportAsExcelFile(exportInformation, 'MouldMaintenanceReport');
    this.spinner.hide();

  }

  compare(a: number | string , b: number | string , isAsc: boolean) {
    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }
  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  ViewCondition(c: ConditionReport){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    let info;
    info = {
      imageUrls:[c.f10SettingUrl,c.f9SettingUrl,c.f1RecordUrl],
      imageList: [
        {
          url: c.f10SettingUrl,
          caption: 'F10 Fast Set Setting'

        },
        {
          url: c.f9SettingUrl,
          caption: 'F9 Other Setting Function Time'

        },
        {
          url: c.f1RecordUrl,
          caption: 'F1 Modify Record Cycle Time'
        }],
      data: [{
        'label': 'Mould Number ',
        'value': c.mouldNumber,
        'size': 'col-lg-6'

      },
      {
        'label': 'Part Number',
        'value': c.partNumber,
        'size': 'col-lg-6'

      },

      {
        'label': 'Mould Temperature',
        'value': c.mouldTemperature,
        'size': 'col-lg-6'

      },
      {
        'label': 'Machine Number',
        'value': c.machineNumber,
        'size': 'col-lg-6'

      },
      {
        'label': 'Created By',
        'value': c.createdBy,
        'size': 'col-lg-6'

      },
      {
        'label': 'Created Date',
        'value':  c.createdDate,
        'size': 'col-lg-6'
      },
      ]
    }

    dialogConfig.data = info;
    this.dialog.open(ReportInfoComponent, dialogConfig)
  }

  ViewMat(c: MaintenanceReport){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    let info;
    info = {
      imageUrls:[c.coreServiceBeforeUrl,c.coreServiceAfterUrl,c.topSideBeforeUrl,c.topSideAfterUrl],
      imageList: [
        {
          url: c.coreServiceBeforeUrl,
          caption: 'Core And Cavity Before Services'

        },
        {
          url: c.coreServiceAfterUrl,
          caption: 'Core And Cavity After Services'

        },
        {
          url: c.topSideBeforeUrl,
          caption: 'Top Side Before Services'
        },
        {
          url: c.topSideAfterUrl,
          caption: 'Top Side After Services'
        }],
      data: [{
        'label': 'Mould Number ',
        'value': c.mouldNumber,
        'size': 'col-lg-6'

      },
      {
        'label': 'Mould Plate',
        'value': c.mouldPlate,
        'size': 'col-lg-6'

      },

      {
        'label': 'Mould Size',
        'value': c.mouldSize,
        'size': 'col-lg-6'

      },
      {
        'label': 'Remark',
        'value': c.remark,
        'size': 'col-lg-6'

      },
      {
        'label': 'Created By',
        'value': c.createdBy,
        'size': 'col-lg-6'

      },
      {
        'label': 'Created Date',
        'value':  c.createdDate,
        'size': 'col-lg-6'
      },
      ]
    }

    dialogConfig.data = info;
    this.dialog.open(ReportInfoComponent, dialogConfig)
  }


}
