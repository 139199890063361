import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormControl, Validators, FormBuilder, FormArray } from '@angular/forms';
import { ThemePalette, MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { MachineDB_controller } from 'src/app/Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Machine } from 'src/app/Services/Object_Classes/Machine/Machine';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { PurchaseOrder, PartTracker } from 'src/app/Services/Object_Classes/PurchaseOrder/PurchaseOrder';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { ViewRawMaterialsComponent } from 'src/app/Shared/view-raw-materials/view-raw-materials.component';
import { SelectMachineSlotComponent } from '../../scheduling/add-event/select-machine-slot/select-machine-slot.component';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-add-po',
  templateUrl: './add-po.component.html',
  styleUrls: ['./add-po.component.css']
})
export class AddPOComponent implements OnInit {
  poNo: any;
  totalPart: any;
  partNumber: any;
  quantity: any;
  part: Part = new Part();
  part_controller: PartDB_controller = new PartDB_controller(this.db,this.storage,this.firestore);
  po_controller: PODB_controller = new PODB_controller(this.db);
  partlist: Part[] = [];
  matSelectPart: Part[] = [];
  clone = [];
  runningScheduleList = [];
  estimateTotalTime = [];
  timeslots = [];
  dateslots = [];
  addForm: FormGroup;
  newPO: PurchaseOrder = new PurchaseOrder();
  newPO_PartList: PartTracker[] = [];
  searchPart: any;
  email: string;
  @ViewChild('picker', {static: true}) picker: any;

  public date: moment.Moment;
  public minDate: moment.Moment;
  public maxDate: moment.Moment;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';

  public formGroup = new FormGroup({
    date: new FormControl(null, [Validators.required]),
    date2: new FormControl(null, [Validators.required])
  });

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  constructor(
    private dialogRef: MatDialogRef<AddPOComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private fb: FormBuilder,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private storage: AngularFireStorage,
    private dialog: MatDialog,
    private angularFireAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private spinner : NgxSpinnerService

  ) {
    this.addForm = this.fb.group({
      name: '',
      parts: this.fb.array([]) ,
    });
    this.angularFireAuth.authState.subscribe(auth=>{
      this.email = auth.email;
    })
    this.addParts();

  }

  existingName = false
  async nameValidation() {
    const name = this.addForm.get('name').value.trim();
    if (name.includes('/') || name.includes('@') || name.includes('[')  || name.includes(']')  || name.includes('.')  || name.includes('#')  || name.includes('$') || name.includes('*')) {
      this.existingName = true
    }else{
      var snapshot = await this.db.database.ref('Purchase Order/' + name).once('value');
      if (snapshot.exists()) {
        this.existingName = true;
      }
      else
        this.existingName = false;
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.part_controller.getPartList().then(data => {
      this.matSelectPart = data;
      this.clone = this.matSelectPart.slice();
    this.spinner.hide();

    });
  }


  parts(): FormArray {
    return this.addForm.get('parts') as FormArray;
  }
  check = false;

  newParts(): FormGroup {

    const partControl = new FormControl();
    const partfilterControl = new FormControl();

    partfilterControl.valueChanges.subscribe(() => {
      this.findPart(partfilterControl);
      if (this.clone) {
        this.check = true;

      }

    });
    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        this.searchPart = partControl.value;
        this.check = true;
      }
    });

    return this.fb.group({
      partControl,
      partfilterControl,
      partNumber: '',
      availableStock: '',
      poquantity: '',
      pos:'',
      reference:'',
    });
  }

  findPart(part) {
    if (!this.clone) { return; }
    const search = part.value;
    this.matSelectPart = this.clone.filter(p => p.Part_No.toLowerCase().includes(search.toLowerCase()));
  }

  addParts() {
    this.parts().push(this.newParts());
    this.partlist.push(null);
  }

  removeParts(i: number) {
    this.parts().removeAt(i);
    this.partlist.splice(i, 1);

  }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm() {
    if(this.existingName){
      this.toast.error('Please fix PO Name', 'Please change!');
      return;
    }
    const addPOModel = {
      POName : this.addForm.get('name').value.trim(),
      parts: []
    };
    const parts = this.addForm.get('parts').value;
    parts.forEach((element, index) => {
      const info = {
        partNumber: element.partControl,
        pos: element.pos,
        reference: element.reference,
        partName: this.partlist[index].Part_Name,
        id: uuidv4(),
        poquantity: parseFloat(element.poquantity),
      };
      if(info.poquantity > this.partlist[index].Stock_Quantity){
        this.toast.error('Stock not enough (' + (index + 1) + ')', 'Please enter again');
        return;
      }
      if ( !info.poquantity|| !info.partNumber || !info.partName  ) {
        this.toast.error('Part information(' + (index + 1) + ') not completed!', 'Please fill in');
        return;
      }
      addPOModel.parts.push(info);
    });

    if (addPOModel.parts.length === 0) {
      this.toast.error('Please add at least one part', 'Add part');
      return;
    }
    if(addPOModel.parts.length !== this.partlist.length){
      this.toast.error('Form not completed', 'Form');
      return;
    }
    if (!addPOModel.POName) {
      this.toast.error('Please fill in the PO name ', 'Please fill in');
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm add this PO?';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
          this.po_controller.addPOWithoutSchedule(addPOModel,this.email);
        this.dialogRef.close(addPOModel);
      }
    });

  }

 getRandomString(length) {
    let randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    for ( let i = 0; i < length; i++ ) {
        result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
    }
    return result;
}
choosingPart= [];

getPart(part,formindex){
  const samePart = this.choosingPart.find(c=>c.part === part && c.index !==formindex);
  if(samePart){
    this.toast.warning("Same part number has been ordered, please choose others", "Warning");
    return;
  }
  if (part) {
    this.part_controller.search_Part(part).then(data => {
      this.partlist[formindex] = data;
      const info = {
        part:part,
        index: formindex,
      }
      this.choosingPart.push(info);

    });


  }
}



parFloat(value){
  return parseFloat(value);
}

}
