import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'app-refill',
  templateUrl: './refill.component.html',
  styleUrls: ['./refill.component.css']
})
export class RefillComponent implements OnInit {

  rawMaterial:RawMaterialInfo
  email;
  location;
  quantity;

  constructor(
    private dialogRef: MatDialogRef<RefillComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private angularFireAuth: AngularFireAuth,
    private spinner: NgxSpinnerService
  ) {
    this.rawMaterial = data;
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }



  ngOnInit() {}

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(){
    if(parseFloat(this.quantity) > this.rawMaterial.PreDeduct){
      this.toast.warning("Quantity has been more than the system deducted");
      return;
    }

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Confirm?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        this.spinner.show();
        this.rawMaterial.PreDeduct = this.rawMaterial.PreDeduct - parseFloat(this.quantity)
        this.db.database.ref('RawMaterial').child(this.rawMaterial.Material_ID)
        .update({'PreDeduct':this.rawMaterial.PreDeduct})

        let info = {
          quantity:parseFloat(this.quantity),
          location:this.location,
          date:new Date()
        }
        this.db.database.ref('RawMaterial').child(this.rawMaterial.Material_ID).child('Restock')
        .child(uuidv4()).update({
          'Quantity':info.quantity,
          'Location':info.location,
          'Date': info.date
        })

        this.rawMaterial.restock.push(info)
        this.toast.success(this.rawMaterial.Material_Name + "has been restock back with quantity : " + this.quantity);
        this.spinner.hide();

        this.dialogRef.close(this.rawMaterial);

      }
    });
  }





}
