export class MaintenanceReport{
  public coreServiceAfterUrl: string;
  public coreServiceBeforeUrl: string;
  public createdBy: string;
  public createdDate: Date;
  public mouldNumber: string;
  public mouldPlate: string;
  public mouldSize: string;
  public remark: string;
  public topSideAfterUrl: string;
  public topSideBeforeUrl: string;

}

export class ConditionReport{
  public f10SettingUrl: string;
  public f9SettingUrl: string;
  public f1RecordUrl: string;
  public createdBy: string;
  public createdDate: Date;
  public machineNumber: string;
  public mouldNumber: string;
  public mouldTemperature: string;
  public partNumber: string;

}
