import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormControl, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { OldStock } from 'src/app/Services/Object_Classes/RawMaterial/OldStock';
import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-old-stock-record',
  templateUrl: './old-stock-record.component.html',
  styleUrls: ['./old-stock-record.component.css']
})
export class OldStockRecordComponent implements OnInit {

  filter:string ="All";
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    qrCodes = [];

    email:string;
    oldStock: OldStock[];
    stockInfo : OldStock;
    clone = [];
    constructor(
      private dialogRef: MatDialogRef<OldStockRecordComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService
    ) {
      this.oldStock = data;
      console.log(this.oldStock);
      this.clone = this.oldStock.slice();

    }

    ngOnInit() {

    }
    filterRecord(word){
      this.filter = word;
      this.oldStock = this.clone.slice();
      if(word === 'Current stock')
        this.oldStock = this.oldStock.filter(o=> (o.Quantity - o.DeductedQuantity !== 0))

    }


    cancel() {
      this.dialogRef.close(false);
    }

    generate(oldstock: OldStock){
      this.qrCodes = [];
      const value = {
        type:"OLDSTOCK",
        oldStockID: oldstock.ID,
        quantity: oldstock.Quantity,
        date: oldstock._Created_Date.getTime(),
      };
      const qr = {
        id: 1,
        value: JSON.stringify(value)
      };
      this.qrCodes.push(qr);
      this.spinner.show();
      setTimeout(() => {
        const doc = new jsPDF();
        const qrcode = document.getElementById("1");
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
        doc.text('Material Name:' + oldstock.MaterialName, 20, 10);
        doc.text('Quantity:' + oldstock.Quantity, 20, 18);
        doc.text('Location:' + oldstock.Location, 20, 26);
        doc.addImage(imageData, 'JPG', 20, 30);
        doc.save(oldstock.MaterialName+'OLDQR.pdf');
      this.spinner.hide();

      }, 1000);

    }

    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    }


    view(stock){
      this.stockInfo = stock
    }

}
