import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-emp-info',
  templateUrl: './emp-info.component.html',
  styleUrls: ['./emp-info.component.css']
})
export class EmpInfoComponent implements OnInit {

  columns=[];
  infos=[];
  name:string;
  total1:any;
  total2:any;


  constructor(
      private dialogRef: MatDialogRef<EmpInfoComponent>,
      @Inject(MAT_DIALOG_DATA) data,
    ) {
      this.name = data.name;
      this.columns = data.columns;
      this.infos = data.infos;
      this.total1 = data.total1;
      this.total2 = data.total2;
    }

  ngOnInit() {
  }
  cancel() {
    this.dialogRef.close(false);
  }



}

