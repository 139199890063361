import { BoxInfo, RejectedInfo, TakenbyPO } from './../Object_Classes/Weighting/Weighting';
import { AngularFireDatabase } from '@angular/fire/database';
import { Weighting, WeightPartList } from '../Object_Classes/Weighting/Weighting';
import moment from 'moment';


export class WeightingDB_controller {
  constructor(private db: AngularFireDatabase) {
  }


  /************** Weighting ****************/
  async getWeightingList(): Promise<Weighting[]> {
    let WeightList: Weighting[] = [];
    var snapshot = await this.db.database.ref('QC').once('value');

    if (snapshot.exists()) {
      snapshot.forEach( (childSnapshot) =>{

        let weight = new Weighting();
        weight.PO_No = childSnapshot.key;

        var part_trackList: WeightPartList[] = [];
        childSnapshot.forEach( (childSnapshot2)=> {
          let weight_track = new WeightPartList;

          let box_info = new BoxInfo;
          box_info.Status = childSnapshot2.child('Weighting/Status').val();
          box_info.Updated_Date = new Date(childSnapshot2.child('Weighting/Date').val()) || null;
          box_info.MadeToOrder = childSnapshot2.child("Weighting/MadeToOrder").val();
          box_info.MakeToStock = childSnapshot2.child("Weighting/MakeToStock").val();
          box_info.RackingNo = childSnapshot2.child("Weighting/RackingNo").val();
          box_info.WarehouseLocation = childSnapshot2.child("Weighting/WarehouseLocation").val();

          box_info.Category = parseFloat(box_info.MadeToOrder) === 0? "Made To Order": "Made To Stock";

          childSnapshot2.child('Weighting/Taken by PO').forEach(e=>{
            var t = new TakenbyPO();
            t.name = e.key
            t.quantity = e.val();
            box_info.Packaging_Quantity = (parseFloat(box_info.Packaging_Quantity) + t.quantity).toString();
            box_info.TakenbyPO.push(t);
          })

          var rejectedQty = 0;
          childSnapshot2.child('Rejected Part').forEach((childSnapshot3)=>{
            let rej = new RejectedInfo();
            rej.Code = childSnapshot3.key;
            rej.Quantity = childSnapshot3.val();
            rejectedQty += rej.Quantity;
            weight_track.RejectedInfos.push(rej);
          })

          weight_track.Part_No = childSnapshot2.key;
          weight_track.Accept_Qty = childSnapshot2.child("Accepted Qty").val();

          weight_track.Weight_Date = new Date(moment(childSnapshot2.child("QC Date").val()).format());
          // weight_track.Reject_Remark = childSnapshot2.child("Remark").val();
          weight_track.Reject_Qty = rejectedQty;
          weight_track.WorkingArea = childSnapshot2.child("Working Area").val();
          weight_track.UpdateBy = childSnapshot2.child("Update_by").val();

          weight_track.WeightingInfo = box_info;
          part_trackList.push(weight_track);
        });
        weight.Weight_Part_List = part_trackList;
        WeightList.push(weight);
      });
    }
    return WeightList;
  }

  delete_Weight(PO_No: string, Part_No: string) {
    this.db.database.ref('/Weighting/' + PO_No + '/' + Part_No).set(null);
  }


}
