import { PackingList, PackingList_PO_List, PackingList_PO_Part_List } from 'src/app/Services/Object_Classes/PackingList/PackingList';
import { PackingListDB_controller } from 'src/app/Services/DB_Controller/PackingListDB_controller';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { PODB_controller } from 'src/app/Services/DB_Controller/PODB_controller';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';
import jsPDF from 'jspdf';
import { v4 as uuidv4 } from 'uuid';
import { PartServices } from 'src/app/Services/Utilities/part.service';

@Component({
  selector: 'app-generate-shipping-label',
  templateUrl: './generate-shipping-label.component.html',
  styleUrls: ['./generate-shipping-label.component.css']
})
export class GenerateShippingLabelComponent implements OnInit {

  POController: PODB_controller = new PODB_controller(this.db);
  PartDB_controller: PartDB_controller = new PartDB_controller(this.db, this.storage, this.firestore);
  PackingListController: PackingListDB_controller = new PackingListDB_controller(this.db,this.firestore,this.partService);

  selectedPO: any = [];
  selectedPart = [[]];
  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  qrCodes = [];
  detailsPO = [];
  dateFrom: Date;
  dateTo: Date;
  maxDate: Date;
  form: FormGroup;
  PackingList: PackingList[] = []
  matSelectPackingList: PackingList[] = []
  searchPackingListNo: any
  numberOfLabel = 0;
  email: string;
  constructor(
    private dialogRef: MatDialogRef<GenerateShippingLabelComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private toast: ToastrService,
    private db: AngularFireDatabase,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private storage: AngularFireStorage,
    private angularFireAuth: AngularFireAuth,
    private fb: FormBuilder,
    private spinner: NgxSpinnerService,
    private dateService: DateFormatService,
    private partService: PartServices
    ) {

    this.spinner.show();
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
  }

  selectPO: PackingList_PO_List[] = [];
  selectPart: PackingList_PO_Part_List[] = [];

  enable = false;


  ngOnInit() {
    this.PackingListController.getPackingPOList().then(data => {
      this.PackingList = data;
      this.matSelectPackingList = this.PackingList.slice();
      this.spinner.hide();
    });

    const packingListControl = new FormControl();

    const packingListfilterControl = new FormControl();

    packingListfilterControl.valueChanges.subscribe(() => {
      this.findPart(packingListfilterControl);
    });
    packingListControl.valueChanges.subscribe(() => {
      if (packingListControl.value) {
        this.searchPackingListNo = packingListControl.value;
        this.selectPO = this.PackingList.find(e => e.PackingList_No === this.searchPackingListNo).PackingList_PO_List || []
        this.selectPart = [];
        poQuantity.setValue('');
        prodQuantity.setValue('');
        stockQuantity.setValue('');
        this.enable = false;

      }
    });

    const poControl = new FormControl();
    const partControl = new FormControl();

    poControl.valueChanges.subscribe(() => {
      if (poControl.value) {
        let r = this.PackingList.find(e => e.PackingList_No === this.searchPackingListNo).PackingList_PO_List || []
        this.selectPart = r.find(e => e.PO_No === poControl.value).PackingList_PO_Part_List || []
        poQuantity.setValue('');
        prodQuantity.setValue('');
        stockQuantity.setValue('');
        this.enable = false;

      }
    });

    const poQuantity = new FormControl();
    const prodQuantity = new FormControl();
    const stockQuantity = new FormControl();



    partControl.valueChanges.subscribe(() => {
      if (partControl.value) {
        let r = this.PackingList.find(e => e.PackingList_No === this.searchPackingListNo).PackingList_PO_List || []
        let p = r.find(e => e.PO_No === poControl.value).PackingList_PO_Part_List
        let q = p.find(e => e.Part_No === partControl.value);

        poQuantity.setValue(q.POQuantity);
        prodQuantity.setValue(q.Part_Quantity);
        stockQuantity.setValue(parseFloat(q.POQuantity) - q.Part_Quantity > 0 ? parseFloat(q.POQuantity) - q.Part_Quantity : 0);
        this.enable = true;

      }
    });

    this.form = this.fb.group({
      packingListControl,
      packingListfilterControl,
      poControl,
      partControl,
      poQuantity,
      prodQuantity,
      stockQuantity,
      splitPart: this.fb.array([]),

    });
  }

  findPart(part) {
    if (!this.PackingList) { return; }
    const search = part.value;
    this.matSelectPackingList = this.PackingList.filter(p => p.PackingList_No.toLowerCase().includes(search.toLowerCase()));

  }
  cancel() {
    this.dialogRef.close(false);
  }

  split() {
    this.splits().push(this.fb.group({
      quantity: '',
      label: ''
    }));
  }

  splits(): FormArray {
    return this.form.get('splitPart') as FormArray;
  }

  remove(i) {
    this.splits().removeAt(i);

  }

  confirm() {

    const formValue = this.form.getRawValue();

    if (formValue.splitPart.length < 0) {
      this.toast.error("No split");
      return;
    }

    let total = 0;
    for (let index = 0; index < formValue.splitPart.length; index++) {
      if (!formValue.splitPart[index].quantity || !formValue.splitPart[index].label) {
        this.toast.error("In complete information for number " + (index + 1));
        return;
      }
      total = total + parseFloat(formValue.splitPart[index].quantity);
    }

    let quantityUsed = parseFloat(formValue.poQuantity);
    if (total > quantityUsed || total < quantityUsed) {
      this.toast.error("The total of quantity must be equal to : " + quantityUsed + ". Please correct!");
      return;
    }

    let z = this.PackingList.find(e => e.PackingList_No === this.searchPackingListNo).PackingList_PO_List;
    let p = z.find(e => e.PO_No === formValue.poControl).PackingList_PO_Part_List;
    let q = p.find(e => e.Part_No === formValue.partControl);

    if (!q) {
      this.toast.error("Unexpected Error, please contact administator");
      return;
    }

    this.qrCodes = [];

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Generate label?';

    let BoxesNeededForEachSplit = []

    var counter = 1;
    for (const s of formValue.splitPart) {
      let numberOfBox = Math.ceil(parseFloat(s.quantity) / parseInt(q.QuantityPerBox))
      let totalQuantity = parseFloat(s.quantity);
      var qty = totalQuantity;
      let quantities = [];
      for (let index = 0; index < numberOfBox; index++) {
        let remain = qty;
        qty -= parseInt(q.QuantityPerBox);
        if (qty > 0) {
          var qr = {
            id: counter,
            po: formValue.poControl,
            partDetail: q,
            value: formValue.poControl + '*' + q.Part_No + '*' + this.searchPackingListNo + '*' + (q.QuantityPerBox)
          }
          quantities.push(q.QuantityPerBox)

          this.qrCodes.push(qr);
        } else {
          var qr = {
            id: counter,
            po: formValue.poControl,
            partDetail: q,
            value: formValue.poControl + '*' + q.Part_No + '*' + this.searchPackingListNo + '*' + (remain)
          }
          quantities.push(remain)
          this.qrCodes.push(qr);
        }
        counter++;
      }
      BoxesNeededForEachSplit.push({
        numberOfBox,
        label: s.label,
        quantities
      });
    }


    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {

        this.spinner.show();
        try {
          await this.generateLabel(q, BoxesNeededForEachSplit, formValue.poControl);
          this.db.database.ref('LabelRecord').child(uuidv4()).update({
            PackingListNo: this.searchPackingListNo,
            PurchaseOrderNo: formValue.poControl,
            PartNo: formValue.partControl,
            Detail: formValue.splitPart,
            BoxesNeededForEachSplit,
            Date: new Date()
          }).then(e=>{
            this.spinner.hide();
            this.toast.success("Success generate");
            this.dialogRef.close(false);
          });



        } catch (error) {
          this.toast.error(error)
          this.spinner.hide();
        }
        this.qrCodes = [];
      }
    });

  }

  generateLabel(info: any, BoxesNeededForEachSplit, pono): Promise<void> {

    const doc = new jsPDF('portrait', 'mm', 'a5');
    var counter = 1;

    for (const boxes of BoxesNeededForEachSplit) {
      for (let index = 1; index <= boxes.numberOfBox; index++) {
        const qrcode = document.getElementById(counter.toString());
        const imageData = this.getBase64Image(qrcode.firstChild.firstChild);

        doc.setFontSize(12);
        doc.setLineWidth(0.2);
        doc.line(2, 2, 145, 2);    //horizontal line
        doc.line(2, 2, 2, 205);    //vertical line
        doc.line(145, 2, 145, 205);
        doc.line(70, 2, 70, 205);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(1) Receiver', 4, 7);
        doc.setFontType('unbold');
        doc.setFontSize(10);
        const text = info.CustomerName + "\n" + info.CustomerAddress;
        const splitTitle = doc.splitTextToSize(text, 50);
        doc.text(splitTitle, 4, 12);
        doc.setFontType('bold');
        doc.text('(2) Product Photo', 72, 7);
        const photo = document.createElement('img');
        photo.src = info.Photo;
        doc.addImage(photo, 'PNG', 90, 7, 30, 30);
        doc.line(2, 39, 145, 39);
        doc.setFontType('bold');
        doc.text('(3) Invoice / Packing List No.', 4, 45);
        doc.setFontType('unbold');
        doc.setFontSize(12);
        doc.text("PL NO: " + boxes.label, 6, 55);

        if (pono) {
          const d = doc.splitTextToSize("PO: " + pono, 70);
          doc.text(d, 6, 62);
        }
        if (info.Reference) {
          const d = doc.splitTextToSize(info.Reference, 70);
          doc.text(d, 14, 69);
        }
        if (info.RevNO) {
          const d = doc.splitTextToSize("REV: " + info.RevNO, 70);
          doc.text(d, 6, 76);

        }
        if (info.POS) {
          const d = doc.splitTextToSize("POS: " + info.POS, 70);
          doc.text(d, 6, 83);
        }


        doc.setFontSize(10);
        doc.setFontType('bold');
        doc.text('(4) Supplier Address', 72, 45);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.setFontType('Tunga');
        doc.text('SPEN INDUSTRIES SDN BHD', 72, 50);
        doc.text('NO 12 & 14 LORONG PERINDUSTRIAN', 72, 55);
        doc.text('BUKIT MINYAK 5,', 72, 60);
        doc.text('TAMAN PERINDUSTRIAN BUKIT MINYAK,', 72, 65);
        doc.text('14100 BUKIT MERTAJAM,', 72, 70);
        doc.text('PULAU PINANG, WEST MALAYSIA', 72, 75);

        doc.line(70, 80, 145, 80);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(5) Net WT', 72, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(info.Part_Weight + ' GM', 77, 88);
        doc.line(91, 80, 91, 90);
        var crossWeight = (parseFloat(info.Part_Weight?info.Part_Weight:'0') * parseFloat(info.QuantityPerBox?info.QuantityPerBox:'0')+ parseFloat(info.Carton_Weight?info.Carton_Weight:'0'))/1000

        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(6) Gross WT', 92, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(crossWeight.toFixed(2) + ' KG', 97, 88);
        doc.line(120, 80, 120, 90);
        doc.setFontType('bold');
        doc.setFontSize(10);
        doc.text('(7) NO Boxes', 122, 84);
        doc.setFontType('unbold');
        doc.setFontSize(9);
        doc.text(index + ' of ' + boxes.numberOfBox, 126, 88);


        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.line(2, 90, 145, 90);
        doc.text('(8) QR Code', 4, 95);
        doc.addImage(imageData, 'JPG', 10, 97);
        doc.text('(9) Customer Part NO (P)', 72, 95);
        doc.setFontType('bold');
        doc.setFontSize(22);
        const splt = doc.splitTextToSize(info.Part_No, 60);
        doc.text(splt, 75, 105);
        doc.line(2, 132, 145, 132);
        doc.setFontType('bold');
        doc.setFontSize(12);

        doc.text('(10) Quantity ', 4, 137);

        doc.text(boxes.quantities[index - 1] + " PIECES", 5, 147);

        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(11) Description', 72, 137);
        doc.setFontSize(14);
        doc.setFontType('unbold');
        const splitTitle2 = doc.splitTextToSize(info.Part_Name, 60);
        doc.text(splitTitle2, 75, 147);
        doc.line(2, 157, 145, 157);
        doc.setFontType('bold');
        doc.setFontSize(12);
        doc.text('(12) QC / OPERATOR NAME', 4, 162);
        doc.text('(14) Production Date', 72, 162);
        doc.text('Date', 75, 172);
        doc.text('Month', 87, 172);
        doc.text('Year', 105, 172);
        doc.text('M/C', 120, 172);
        doc.line(75, 177, 130, 177);
        doc.line(75, 177, 75, 192);
        doc.line(88, 177, 88, 192);
        doc.line(100, 177, 100, 192);
        doc.line(118, 177, 118, 192);
        doc.line(130, 177, 130, 192);
        doc.line(75, 192, 130, 192);
        doc.line(2, 205, 145, 205);
        doc.addPage();
        counter++;
      }
    }
    var pageCount = doc.internal.getNumberOfPages();
    doc.deletePage(pageCount)


    doc.save(pono + "_" + info.Part_No + '_ShippingLabel.pdf');
    return null;

  }

  getBase64Image(img) {
    try {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0, 125, 125);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    } catch (error) {
      throw new Error("No Image for part");
    }

  }
}
