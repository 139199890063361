import { RawMaterialOrder } from './../../../Services/Object_Classes/RawMaterial/PORawMaterial';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormBuilder } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import jsPDF from 'jspdf';

@Component({
  selector: 'app-po-stock-record',
  templateUrl: './po-stock-record.component.html',
  styleUrls: ['./po-stock-record.component.css']
})
export class PoStockRecordComponent implements OnInit {

  filter:string ="All";
    elementType = NgxQrcodeElementTypes.URL;
    correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
    qrCodes = [];

    email:string;
    POlist: any[];
    poInfo : RawMaterialOrder;
    clone = [];
    constructor(
      private dialogRef: MatDialogRef<PoStockRecordComponent>,
      @Inject(MAT_DIALOG_DATA) data,
      private toast: ToastrService,
      private db: AngularFireDatabase,
      private dialog: MatDialog,
      private firestore: AngularFirestore,
      private angularFireAuth: AngularFireAuth,
      private fb: FormBuilder,
      private spinner: NgxSpinnerService
    ) {
      this.POlist = data.slice();

      this.clone = this.POlist.slice();


    }

    ngOnInit() {

    }
    filterRecord(word){
      this.filter = word;
      this.POlist = this.clone.slice();
      if(word === 'Current stock')
        this.POlist = this.POlist.filter(po=> parseFloat(po.RawMaterial.PO_RawMaterial_Qty)
                                            - parseFloat(po.RawMaterial.Deducted_Quantity)
                                            !== 0 )
    }


    cancel() {
      this.dialogRef.close(false);
    }

    generate(stock: RawMaterialOrder){
      this.qrCodes = [];
      let counter = 0;
      for (const e of stock.ReceivedDetailsList) {
        const value = {
          poID: this.poName,
          rawOrderIDs: stock.PO_Order_ID,
          materialIDs: stock.PO_RawMaterial_ID,
          boxID: e.ID
        };
        const qr = {
          id: counter,
          po: this.poName,
          raw: stock.PO_RawMaterial_Name,
          detail:e,
          quantity: e.Received_Quantity,
          value: JSON.stringify(value)
        };
        this.qrCodes.push(qr);
        counter++;
      }

      this.spinner.show();
      setTimeout(() => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        let d = 0;
        this.qrCodes.forEach((e, i) => {
          const qrcode = document.getElementById(i.toString());
          const imageData = this.getBase64Image(qrcode.firstChild.firstChild);
          if ((i + 1) % (2 + d) === 0) {
            doc.text('PO Name :' + e.po, 20, 100);
            doc.text('Material Name :' + e.raw, 20, 105);
            doc.text('Lot Number :' + e.detail.Lot_Number, 20, 110);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 115);
            doc.text('Quantity :' + e.quantity, 20, 120);
            doc.addImage(imageData, 'JPG', 20, 120);
          } else if ((i + 1) % (3 + d) === 0) {
            doc.text('PO Name :' + e.po, 20, 190);
            doc.text('Material Name :' + e.raw, 20, 195);
            doc.text('Lot Number :' + e.detail.Lot_Number, 20, 200);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 205);
            doc.text('Quantity :' + e.quantity, 20, 210);
            doc.addImage(imageData, 'JPG', 20, 210);
            d = d + 3;
            if (this.qrCodes.length !== i + 1)
              doc.addPage();
          } else {
            doc.text('PO Name :' + e.po, 20, 10);
            doc.text('Material Name :' + e.raw, 20, 15);
            doc.text('Lot Number :' +  e.detail.Lot_Number, 20, 20);
            doc.text('Rack Number :' + e.detail.Rack_Number, 20, 25);
            doc.text('Quantity :' + e.quantity, 20, 30);
            doc.addImage(imageData, 'JPG', 20, 30);
          }
        });
        doc.save('QR.pdf');
        doc.save(stock.PO_RawMaterial_Name+'QR.pdf');
        this.spinner.hide();

      }, 1000);

    }

    getBase64Image(img) {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      return dataURL;
    }

    poName: string;
    view(stock){
      this.poInfo = stock.RawMaterial
      this.poName = stock.PONumber
    }


}
