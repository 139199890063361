export class PackingList {

  private _PackingList_No: string;
  private _Packing_PO_List: PackingList_PO_List[];
  private _Created_By: string;
  private _Created_Date: Date;
  private _Updated_By: string;
  private _Updated_Date: Date;
  private _Destination: string;
  private _Currency: string;
  public customerID: string;
  public Customer: string;
  public Status: string;
  public AddedToInvoice: boolean;
  public Shipping_Date: Date;

  get PackingList_No(): string {
    return this._PackingList_No;
  }

  set PackingList_No(value: string) {
    this._PackingList_No = value;
  }

  get PackingList_PO_List(): PackingList_PO_List[] {
    return this._Packing_PO_List;
  }

  set PackingList_PO_List(value: PackingList_PO_List[]) {
    this._Packing_PO_List = value;
  }

  get Created_Date(): Date {
    return this._Created_Date;
  }
  set Created_Date(value: Date) {
    this._Created_Date = value;
  }
  get Updated_Date(): Date {
    return this._Updated_Date;
  }
  set Updated_Date(value: Date) {
    this._Updated_Date = value;
  }

  get Updated_By(): string {
    return this._Updated_By;
  }
  set Updated_By(value: string) {
    this._Updated_By = value;
  }
  get Created_By(): string {
    return this._Created_By;
  }
  set Created_By(value: string) {
    this._Created_By = value;
  }

  get Destination(): string {
    return this._Destination;
  }
  set Destination(value: string) {
    this._Destination = value;
  }



  get Currency(): string {
    return this._Currency;
  }
  set Currency(value: string) {
    this._Currency = value;
  }

}


export class PackingList_PO_List {
  private _PO_No: string;
  private _PackingList_PO_Part_List: PackingList_PO_Part_List[];
  // private _Price: string;

  get PO_No(): string {
    return this._PO_No;
  }
  set PO_No(value: string) {
    this._PO_No = value;
  }

  get PackingList_PO_Part_List(): PackingList_PO_Part_List[] {
    return this._PackingList_PO_Part_List;
  }
  set PackingList_PO_Part_List(value: PackingList_PO_Part_List[]) {
    this._PackingList_PO_Part_List = value;
  }

  // set Price(value: string) {
  //   this._Price = value;
  // }

  // get Price(): string {
  //   return this._Price;
  // }
}

export class PackingList_PO_Part_List {
  private _Part_No: string;
  private _Part_Quantity: number;
  private _Shipping_Date: Date;
  private _Part_Price: string;
  private _Part_Mould_Price: string;
  private _Part_Remark: string;
  public POQuantity: string;
  public TakeFromStocks: TakeFromStock[] =[];
  public Photo: string;
  public Part_Name: string;
  public CartonBoxNeeded: string;
  public Carton_Weight: string;
  public Reference : string;
  public POS: string;
  public Part_Weight: any;
  public QuantityPerBox: any;
  public InStock: any;
  public RevNO: string;
  public id: string;
  public CustomerName: string;
  public CustomerAddress: string;
  public partCurrency: string;
  public Status: string


  get Part_No(): string {
    return this._Part_No;
  }
  set Part_No(value: string) {
    this._Part_No = value;
  }

  get Part_Quantity(): number {
    return this._Part_Quantity;
  }
  set Part_Quantity(value: number) {
    this._Part_Quantity = value;
  }



  get Shipping_Date(): Date {
    return this._Shipping_Date;
  }
  set Shipping_Date(value: Date) {
    this._Shipping_Date = value;
  }

  get Part_Price(): string {
    return this._Part_Price;
  }
  set Part_Price(value: string) {
    this._Part_Price = value;
  }

  get Part_Mould_Price(): string {
    return this._Part_Mould_Price;
  }
  set Part_Mould_Price(value: string) {
    this._Part_Mould_Price = value;
  }

  get Part_Remark(): string {
    return this._Part_Remark;
  }

  set Part_Remark(value: string) {
    this._Part_Remark = value;
  }
}


export class TakeFromStock {
  public BoxNumber: string;
  public DeductedQuantity: number;
  public OriginalQuantity: number;

}
