import { RawMaterialInfo } from 'src/app/Services/Object_Classes/RawMaterial/RawMaterial';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Part } from 'src/app/Services/Object_Classes/Part/Part';
import { AngularFireDatabase } from '@angular/fire/database';
import { AngularFirestore } from '@angular/fire/firestore';
import { FormGroup, FormBuilder, FormArray, FormControl, Validators } from '@angular/forms';
import { RawDB_controller } from 'src/app/Services/DB_Controller/RawDB_controller';
import { SupplierDB_controller } from 'src/app/Services/DB_Controller/SupplierDB_controller';
import { Supplier } from 'src/app/Services/Object_Classes/RawMaterial/Supplier';
import { CustomerDB_controller } from 'src/app/Services/DB_Controller/CustomerDB_controller';
import { Customer } from 'src/app/Services/Object_Classes/Customer/Customer';
const cloneDeep = require('lodash.clonedeep')

@Component({
  selector: 'app-update-part',
  templateUrl: './update-part.component.html',
  styleUrls: ['./update-part.component.css']
})
export class UpdatePartComponent implements OnInit {


  part = new Part();
  searchRaw: any;
  clone = [];
  matSelectRaw: RawMaterialInfo[] = [];
  counter = 0;

  searchBatch: any;
  cloneBatch = [];
  matSelectBatch: RawMaterialInfo[] = [];

  searchPigment: any;
  clonePigment = [];
  matSelectPigment: RawMaterialInfo[] = [];

  searchCarton: any;
  cloneCarton = [];
  matSelectCarton: RawMaterialInfo[] = [];

  searchPoly: any;
  clonePolyBag = [];
  matSelectPolyBag: RawMaterialInfo[] = [];

  searchInner: any;
  cloneInnerBag = [];
  matSelectInnerBag : RawMaterialInfo[] = [];



  packagingForm: FormGroup;


  RawDbController: RawDB_controller = new RawDB_controller(this.db, this.firestore);
  rawForm: FormGroup;

  SupplierDbController: SupplierDB_controller = new SupplierDB_controller(this.db, this.firestore);
  searchSupplier: any;
  cloneSupplier : Supplier[] = [];
  matSelectSupplier : Supplier[] = [];
  secondaryForm: FormGroup;

  CustomerDbController: CustomerDB_controller = new CustomerDB_controller(this.db, this.firestore);
  customerForm: FormGroup;
  searchCustomer: any;
  cloneCustomer : Customer[] = [];
  matSelectCustomer : Customer[] = [];

  ngOnInit() {
  }
  constructor(
    public dialogRefaddPart: MatDialogRef<UpdatePartComponent>,
    private db: AngularFireDatabase,
    private firestore: AngularFirestore,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA) public data) {
    this.part =  cloneDeep(data);

    this.rawForm = this.fb.group({
      name: '',
      raws: this.fb.array([]),
    });
    this.part.Raw_Material.forEach((e,index)=>{
      this.raws().push(this.preRaws(e));
    })
    this.RawDbController.getRawList().then(data => {
      this.matSelectRaw = data.filter(r=>r.Raw_Type === 'Raw Material');
      this.clone = this.matSelectRaw.slice();
      this.matSelectBatch = data.filter(r=>r.Raw_Type === 'Material Batch');
      this.cloneBatch = this.matSelectBatch.slice();
      this.matSelectPigment = data.filter(r=>r.Raw_Type === 'Pigment');
      this.clonePigment = this.matSelectPigment.slice();
      this.matSelectCarton = data.filter(r=>r.Raw_Type === 'Carton');
      this.cloneCarton = this.matSelectCarton.slice();
      this.matSelectPolyBag = data.filter(r=>r.Raw_Type === 'Poly Bag');
      this.clonePolyBag = this.matSelectPolyBag.slice();
      this.matSelectInnerBag = data.filter(r=>r.Raw_Type === 'Inner Box');
      this.cloneInnerBag = this.matSelectInnerBag.slice();
    });

    this.SupplierDbController.getSupplierList().then(data => {
      this.matSelectSupplier = data;
      this.cloneSupplier = this.matSelectSupplier.slice();
    });
    this.CustomerDbController.getCustomerList().then(data=>{
      this.matSelectCustomer = data;
      this.cloneCustomer = this.matSelectCustomer.slice();
    })
    this.setPackaging();
    this.setSecondaryProcess();

    this.setCustomer();


  }

  setPackaging(){
    const cartonControl = new FormControl();
    const cartonfilterControl = new FormControl();
    if(this.part.CartonBox.Material_Name){
      cartonControl.setValue(this.part.CartonBox.Material_Name);
    }
    else{
      cartonControl.setValue('Empty');
    }
    cartonfilterControl.valueChanges.subscribe(() => {
      this.findCarton(cartonfilterControl);
    });
    cartonControl.valueChanges.subscribe(() => {
      if (cartonControl.value) {
        this.searchCarton = cartonControl.value;
      }
    });

    const PolyControl = new FormControl();
    const PolyFilterControl = new FormControl();
    if(this.part.PolyBag.Material_Name){
      PolyControl.setValue(this.part.PolyBag.Material_Name);
    }
    else{
      PolyControl.setValue('Empty');
    }
    PolyFilterControl.valueChanges.subscribe(() => {
      this.findPoly(PolyFilterControl);
    });
    PolyControl.valueChanges.subscribe(() => {
      if (PolyControl.value) {
        this.searchPoly = PolyControl.value;
      }
    });

    const InnerControl = new FormControl();
    const InnerFilterControl = new FormControl();
    if(this.part.InnerBox.Material_Name){
      InnerControl.setValue(this.part.InnerBox.Material_Name);
    }
    else{
      InnerControl.setValue('Empty');
    }

    InnerFilterControl.valueChanges.subscribe(() => {
      this.findInner(InnerFilterControl);
    });
    InnerControl.valueChanges.subscribe(() => {
      if (InnerControl.value) {
        this.searchInner = InnerControl.value;
      }
    });

    const supplier1Control = new FormControl();
    const supplier1FilterControl = new FormControl();
    if(this.part.Supplier1){
      supplier1Control.setValue(this.part.Supplier1);
    }
    else{
      supplier1Control.setValue('Empty');
    }
    supplier1FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier1FilterControl);
    });
    supplier1Control.valueChanges.subscribe(() => {
      if (supplier1Control.value) {
        this.searchSupplier = supplier1Control.value;
      }
    });

    const supplier6Control = new FormControl();
    const supplier6FilterControl = new FormControl();
    if(this.part.Supplier6){
      supplier6Control.setValue(this.part.Supplier6);
    }
    else{
      supplier6Control.setValue('Empty');
    }
    supplier6FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier6FilterControl);
    });
    supplier6Control.valueChanges.subscribe(() => {
      if (supplier6Control.value) {
        this.searchSupplier = supplier6Control.value;
      }
    });



    this.packagingForm = this.fb.group({
      cartonControl: cartonControl,
      cartonfilterControl: cartonfilterControl,
      InnerControl: InnerControl,
      InnerFilterControl: InnerFilterControl,
      PolyControl: PolyControl,
      PolyFilterControl: PolyFilterControl,
      supplier1Control: supplier1Control,
      supplier1FilterControl: supplier1FilterControl,
      supplier6Control: supplier6Control,
      supplier6FilterControl: supplier6FilterControl,
      qtypoly: new FormControl(this.part.Polybag_Quantity, Validators.required),
      qtybox: new FormControl(this.part.Box_Quantity, Validators.required),
      qtyinner: new FormControl(this.part.Quantity_InnerBox, Validators.required),
    });


  }

  setSecondaryProcess(){
    const supplier4Control = new FormControl();
    const supplier4FilterControl = new FormControl();
    if(this.part.Supplier4){
      supplier4Control.setValue(this.part.Supplier4);
    }
    else{
      supplier4Control.setValue('Empty');
    }
    supplier4FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier4FilterControl);
    });
    supplier4Control.valueChanges.subscribe(() => {
      if (supplier4Control.value) {
        this.searchSupplier = supplier4Control.value;
      }
    });

    const supplier5Control = new FormControl();
    const supplier5FilterControl = new FormControl();
    if(this.part.Supplier5){
      supplier5Control.setValue(this.part.Supplier5);
    }
    else{
      supplier5Control.setValue('Empty');
    }
    supplier5FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier5FilterControl);
    });
    supplier5Control.valueChanges.subscribe(() => {
      if (supplier5Control.value) {
        this.searchSupplier = supplier5Control.value;
      }
    });

    this.secondaryForm = this.fb.group({
      supplier4Control: supplier4Control,
      supplier4FilterControl: supplier4FilterControl,
      supplier5Control: supplier5Control,
      supplier5FilterControl: supplier5FilterControl,
      sec1: new FormControl(this.part.Sec_Pro_1, Validators.required),
      sec1_Cycle: new FormControl(this.part.Sec_Pro_1_Cycle_Time, Validators.required),
      sec2: new FormControl(this.part.Sec_Pro_2, Validators.required),
      sec2_Cycle: new FormControl(this.part.Sec_Pro_2_Cycle_Time, Validators.required),
      usage1: new FormControl(this.part.Usage1, Validators.required),
      usage2: new FormControl(this.part.Usage2, Validators.required),
      cycleTime: new FormControl(this.part.Sec_Cycle_Time, Validators.required),


    });
  }

  setCustomer(){
    const customerControl = new FormControl();
    const customerfilterControl = new FormControl();
    if(this.part.Customer_Name){
      customerControl.setValue(this.part.Customer_Name);
    }
    else{
      customerControl.setValue('Empty');
    }
    customerfilterControl.valueChanges.subscribe(() => {
      this.findCustomer(customerfilterControl);
    });
    customerControl.valueChanges.subscribe(() => {
      if (customerControl.value) {
        this.searchCustomer = customerControl.value;
      }
    });

    this.customerForm = this.fb.group({
      customerControl: customerControl,
      customerfilterControl: customerfilterControl,
      po: new FormControl(this.part.PO_No, Validators.required),
      pplMold: new FormControl(this.part.PPL_MOLD, Validators.required),
      pplCus: new FormControl(this.part.PPL_CUS, Validators.required),
      Remark: new FormControl(this.part.Remarks, Validators.required),
    });
  }


  findSupplier(supplier) {
    if (!this.cloneSupplier) { return; }
    const search = supplier.value;
    this.matSelectSupplier = this.cloneSupplier.filter(p => p.Supplier_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findRaw(raw) {
    if (!this.clone) { return; }
    const search = raw.value;
    this.matSelectRaw = this.clone.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }

  findBatch(raw) {
    if (!this.cloneBatch) { return; }
    const search = raw.value;
    this.matSelectBatch = this.cloneBatch.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findPigment(raw) {
    if (!this.clonePigment) { return; }
    const search = raw.value;
    this.matSelectPigment = this.clonePigment.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findCarton(raw) {
    if (!this.cloneCarton) { return; }
    const search = raw.value;
    this.matSelectCarton = this.cloneCarton.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findPoly(raw) {
    if (!this.clonePolyBag) { return; }
    const search = raw.value;
    this.matSelectPolyBag = this.clonePolyBag.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findInner(raw) {
    if (!this.cloneInnerBag) { return; }
    const search = raw.value;
    this.matSelectInnerBag = this.cloneInnerBag.filter(p => p.Material_Name.toLowerCase().includes(search.toLowerCase()));
  }
  findCustomer(cus){
    if (!this.cloneCustomer) { return; }
    const search = cus.value;
    this.matSelectCustomer = this.cloneCustomer.filter(p => p.Customer_Name.toLowerCase().includes(search.toLowerCase()));

  }

  raws(): FormArray {
    return this.rawForm.get('raws') as FormArray;
  }

  newRaws(choice): FormGroup {
    const rawControl = new FormControl();
    const rawfilterControl = new FormControl();

    rawfilterControl.valueChanges.subscribe(() => {
      this.findRaw(rawfilterControl);
    });
    rawControl.valueChanges.subscribe(() => {
      if (rawControl.value) {
        this.searchRaw = rawControl.value;
      }
    });

    const batchControl = new FormControl();
    const batchFilterControl = new FormControl();

    batchFilterControl.valueChanges.subscribe(() => {
      this.findBatch(batchFilterControl);
    });
    batchControl.valueChanges.subscribe(() => {
      if (batchControl.value) {
        this.searchBatch = batchControl.value;
      }
    });

    const pigmentControl = new FormControl();
    const pigmentFilterControl = new FormControl();

    pigmentFilterControl.valueChanges.subscribe(() => {
      this.findPigment(pigmentFilterControl);
    });
    pigmentControl.valueChanges.subscribe(() => {
      if (pigmentControl.value) {
        this.searchPigment = pigmentControl.value;
      }
    });

    const supplier3Control = new FormControl();
    const supplier3FilterControl = new FormControl();
    supplier3Control.setValidators(Validators.required);

    supplier3FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier3FilterControl);
    });
    supplier3Control.valueChanges.subscribe(() => {
      if (supplier3Control.value) {
        this.searchSupplier = supplier3Control.value;
      }
    });

    this.counter++;
    return this.fb.group({
      type: choice,
      rawControl: rawControl,
      rawfilterControl: rawfilterControl,
      batchControl: batchControl,
      batchFilterControl: batchFilterControl,
      pigmentControl: pigmentControl,
      pigmentFilterControl: pigmentFilterControl,
      supplier3Control: supplier3Control,
      supplier3FilterControl: supplier3FilterControl,
      material: new FormControl('', Validators.required),
      spen: new FormControl('', Validators.required),
      ral: new FormControl('', Validators.required),
      matratio: new FormControl('', Validators.required),
    });
  }

  preRaws(e: RawMaterialInfo): FormGroup {
    const rawControl = new FormControl();
    if(e.Raw_Type === 'Raw Material')
      rawControl.setValue(e.Material_Name);


    const rawfilterControl = new FormControl();
    rawfilterControl.valueChanges.subscribe(() => {
      this.findRaw(rawfilterControl);
    });
    rawControl.valueChanges.subscribe(() => {
      if (rawControl.value) {
        this.searchRaw = rawControl.value;
      }
    });


    const batchControl = new FormControl();
    const batchFilterControl = new FormControl();
    if(e.Raw_Type === 'Material Batch')
      batchControl.setValue(e.Material_Name);


    batchFilterControl.valueChanges.subscribe(() => {
      this.findBatch(batchFilterControl);
    });
    batchControl.valueChanges.subscribe(() => {
      if (batchControl.value) {
        this.searchBatch = batchControl.value;
      }
    });

    const pigmentControl = new FormControl();
    const pigmentFilterControl = new FormControl();
    if(e.Raw_Type === 'Pigment')
      pigmentControl.setValue(e.Material_Name);

    pigmentFilterControl.valueChanges.subscribe(() => {
      this.findPigment(pigmentFilterControl);
    });
    pigmentControl.valueChanges.subscribe(() => {
      if (pigmentControl.value) {
        this.searchPigment = pigmentControl.value;
      }
    });

    const supplier3Control = new FormControl();
    const supplier3FilterControl = new FormControl();
    supplier3Control.setValidators(Validators.required);
    supplier3Control.setValue(e.Supplier);

    supplier3FilterControl.valueChanges.subscribe(() => {
      this.findSupplier(supplier3FilterControl);
    });
    supplier3Control.valueChanges.subscribe(() => {
      if (supplier3Control.value) {
        this.searchSupplier = supplier3Control.value;
      }
    });

    this.counter++;
    return this.fb.group({
      type: e.Raw_Type,
      rawControl: rawControl,
      rawfilterControl: rawfilterControl,
      batchControl: batchControl,
      batchFilterControl: batchFilterControl,
      pigmentControl: pigmentControl,
      pigmentFilterControl: pigmentFilterControl,
      material: new FormControl(e.Customer_Material, Validators.required),
      spen: new FormControl(e.SPEN_Material, Validators.required),
      ral: new FormControl(e.Customer_RAL, Validators.required),
      matratio: new FormControl(e.Mat_Ratio, Validators.required),
      supplier3Control: supplier3Control,
      supplier3FilterControl: supplier3FilterControl,
      });
  }

  onNoClick(): void {
    this.dialogRefaddPart.close();
  }

  addRawMaterial(choice) {
    const form = this.newRaws(choice);
    this.part.Raw_Material.push(new RawMaterialInfo());
    this.raws().push(form);
    this.cd.detectChanges();
  }

  removeRaws(i: number) {
    this.raws().removeAt(i);
    this.part.Raw_Material.splice(i, 1);
  }


  saveRaw(){
    this.raws().controls.forEach( (e, i) => {

      switch(e.get('type').value){
        case 'Raw Material':
        this.part.Raw_Material[i].Material_Name = e.get('rawControl').value;
        this.part.Raw_Material[i].Material_ID = this.matSelectRaw.find(r=>r.Material_Name === e.get('rawControl').value).Material_ID;
        this.part.Raw_Material[i].Part_Material_Name = this.matSelectRaw.find(r=>r.Material_Name === e.get('rawControl').value).Material_Name;
        this.part.Raw_Material[i].Raw_Type = 'Raw Material'

        break;
        case 'Material Batch':
        this.part.Raw_Material[i].Material_Name = e.get('batchControl').value;
        this.part.Raw_Material[i].Material_ID = this.matSelectBatch.find(r=>r.Material_Name === e.get('batchControl').value).Material_ID;
        this.part.Raw_Material[i].Part_Material_Name = this.matSelectBatch.find(r=>r.Material_Name === e.get('batchControl').value).Material_Name;
        this.part.Raw_Material[i].Raw_Type = 'Material Batch'

        break;
        case 'Pigment':
        this.part.Raw_Material[i].Material_Name = e.get('pigmentControl').value;
        this.part.Raw_Material[i].Material_ID = this.matSelectPigment.find(r=>r.Material_Name === e.get('pigmentControl').value).Material_ID;
        this.part.Raw_Material[i].Part_Material_Name = this.matSelectPigment.find(r=>r.Material_Name === e.get('pigmentControl').value).Material_Name;
        this.part.Raw_Material[i].Raw_Type = 'Pigment'
        break;
      }

      this.part.Raw_Material[i].Customer_Material = e.get('material').value;
      this.part.Raw_Material[i].SPEN_Material = e.get('spen').value;
      this.part.Raw_Material[i].Customer_RAL = e.get('ral').value;
      this.part.Raw_Material[i].Mat_Ratio = e.get('matratio').value;
      // this.part.Raw_Material[i].Pigment =  this.matSelectPigment.find(r=>r.Material_Name === e.get('pigmentControl').value).Material_ID;
      // this.part.Raw_Material[i].M_Batch = this.matSelectBatch.find(r=>r.Material_Name === e.get('batchControl').value).Material_ID;
      // if(e.get('pigmentControl').value === 'None'){
      //   this.part.Raw_Material[i].Pigment = 'None';
      // }else{
      //   this.part.Raw_Material[i].Pigment =  this.matSelectPigment.find(r=>r.Material_Name === e.get('pigmentControl').value).Material_ID;
      // }
      // if(e.get('batchControl').value === 'None'){
      //   this.part.Raw_Material[i].M_Batch = 'None';
      // }else{
      //   this.part.Raw_Material[i].M_Batch = this.matSelectBatch.find(r=>r.Material_Name === e.get('batchControl').value).Material_ID;
      // }
      if(e.get('supplier3Control').value === 'Empty'){
        this.part.Raw_Material[i].Supplier = 'Empty';
        this.part.Raw_Material[i].supplierID = 'Empty';
      }else{
        this.part.Raw_Material[i].Supplier = e.get('supplier3Control').value;
        this.part.Raw_Material[i].supplierID =this.matSelectSupplier.find(s=>s.Supplier_Name === e.get('supplier3Control').value).Supplier_ID;
      }

    });

  }
  boxName: any;
  polyboxName: any;
  innerboxName: any;

  savePackage(){
    if(this.packagingForm.get('cartonControl').value === 'Empty'){
      this.part.Box_Size = 'Empty'
      this.boxName = 'Empty'
      this.part.Box_Quantity = 0
    }else{
      this.part.Box_Size = this.matSelectCarton.find(r=>r.Material_Name === this.packagingForm.get('cartonControl').value).Material_ID;
      this.boxName =  this.matSelectCarton.find(r=>r.Material_Name === this.packagingForm.get('cartonControl').value).Material_Name;
      this.part.Box_Quantity = this.packagingForm.get('qtybox').value
    }

    if(this.packagingForm.get('PolyControl').value === 'Empty'){
      this.part.Polybag_Size = 'Empty'
      this.polyboxName = 'Empty'
      this.part.Polybag_Quantity = '0'
    }else{
      this.part.Polybag_Size = this.matSelectPolyBag.find(r=>r.Material_Name === this.packagingForm.get('PolyControl').value).Material_ID;
      this.polyboxName =   this.matSelectPolyBag.find(r=>r.Material_Name === this.packagingForm.get('PolyControl').value).Material_Name;
      this.part.Polybag_Quantity = this.packagingForm.get('qtypoly').value
    }

    if(this.packagingForm.get('InnerControl').value === 'Empty'){
      this.part.Inner_BoxSize = 'Empty'
      this.innerboxName = 'Empty'
      this.part.Quantity_InnerBox = '0'
    }else{
      this.part.Inner_BoxSize = this.matSelectInnerBag.find(r=>r.Material_Name === this.packagingForm.get('InnerControl').value).Material_ID;
      this.innerboxName =  this.matSelectInnerBag.find(r=>r.Material_Name === this.packagingForm.get('InnerControl').value).Material_Name
      this.part.Quantity_InnerBox =this.packagingForm.get('qtyinner').value
    }

    if(this.packagingForm.get('supplier6Control').value === 'Empty'){
      this.part.Supplier6 = 'Empty'
      this.part.supplier6ID = 'Empty'
    }else{
      this.part.Supplier6 = this.packagingForm.get('supplier6Control').value
      this.part.supplier6ID = this.matSelectSupplier.find(s=>s.Supplier_Name === this.packagingForm.get('supplier6Control').value).Supplier_ID;
    }

    this.part.Supplier1 = this.packagingForm.get('supplier1Control').value
    this.part.supplier1ID = this.matSelectSupplier.find(s=>s.Supplier_Name === this.packagingForm.get('supplier1Control').value).Supplier_ID;


  }
  saveProcess(){
    this.part.Sec_Pro_1 = this.secondaryForm.get('sec1').value;
    this.part.Sec_Pro_1_Cycle_Time = this.secondaryForm.get('sec1_Cycle').value;
    this.part.Sec_Pro_2 = this.secondaryForm.get('sec2').value;
    this.part.Sec_Pro_2_Cycle_Time = this.secondaryForm.get('sec2_Cycle').value;
    this.part.Usage1 = this.secondaryForm.get('usage1').value;
    this.part.Usage2 = this.secondaryForm.get('usage2').value;
    this.part.Sec_Cycle_Time = this.secondaryForm.get('cycleTime').value;
    if(this.secondaryForm.get('supplier4Control').value === 'Empty'){
      this.part.Supplier4 = 'Empty'
      this.part.supplier4ID = 'Empty'
    }else{
      this.part.Supplier4 = this.secondaryForm.get('supplier4Control').value
      this.part.supplier4ID = this.matSelectSupplier.find(s=>s.Supplier_Name === this.secondaryForm.get('supplier4Control').value).Supplier_ID;
    }
    if(this.secondaryForm.get('supplier5Control').value === 'Empty'){
      this.part.Supplier5 = 'Empty'
      this.part.supplier5ID = 'Empty'
    }else{
      this.part.Supplier5 = this.secondaryForm.get('supplier5Control').value
      this.part.supplier5ID = this.matSelectSupplier.find(s=>s.Supplier_Name === this.secondaryForm.get('supplier5Control').value).Supplier_ID;
    }



  }

  onFileSelected(event) {
    this.part.DrawingURL = event.target.files[0];
  }
  onFile2Selected(event) {
    this.part.PhotoURL = event.target.files[0];
  }

  view(url) {
    window.open(url, '_blank');
 }
 validate(s){
   return typeof(s) === 'string'? true:false;
 }

 saveCustomer(){
  this.part.Customer_Name = this.customerForm.get('customerControl').value;
  this.part.PO_No = this.customerForm.get('po').value;
  this.part.PPL_MOLD = this.customerForm.get('pplMold').value;
  this.part.PPL_CUS = this.customerForm.get('pplCus').value;
  this.part.Remarks = this.customerForm.get('Remark').value;
  this.part.Customer_Address = this.matSelectCustomer.find(s=>s.Customer_Name === this.customerForm.get('customerControl').value).Address;
  this.part.customerID =this.matSelectCustomer.find(s=>s.Customer_Name === this.customerForm.get('customerControl').value).Customer_ID
}
}
