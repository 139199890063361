export class LabelRecord{
  public PackingListNo: string = '';
  public PurchaseOrderNo: string = '';
  public PartNo: string = '';
  public Date: Date;
  public Detail: SplitDetail[] = []
  public BoxesDetail: BoxesNeededForEachSplit[] = []

}

export class SplitDetail{
  public Quantity : number = 0;
  public ReferenceLabel: string = '';
}

export class BoxesNeededForEachSplit{
  public Quantities=[]
  public NumberOfBox: number = 0;
  public Label: string = '';
}
