import { MaintenanceReport, ConditionReport } from './../Object_Classes/Mold/Mold';
import { AngularFireDatabase } from '@angular/fire/database';
import { v4 as uuidv4 } from 'uuid';
import { AngularFirestore } from '@angular/fire/firestore';
import { DateFormatService } from '../Utilities/date-format.service';
import { Customer } from '../Object_Classes/Customer/Customer';



export class MoldDB_Controller {
  constructor(private db: AngularFireDatabase, private firestore: AngularFirestore) {
  }

  private dateFormat = new DateFormatService();

  async getMouldMaintenanceReport(): Promise<MaintenanceReport[]> {
    let reportList : MaintenanceReport[]= [];
    var snapshot = await this.db.database.ref('MouldMaintenanceReport').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const report = new MaintenanceReport();
        report.createdBy = childSnapshot.child('Create_by').val();
        report.createdDate = new Date(childSnapshot.child('Create_date').val());
        report.mouldNumber = childSnapshot.child('Mould_Number').val();
        report.mouldPlate = childSnapshot.child('Mould_Plate').val();
        report.mouldSize = childSnapshot.child('Mould_Size').val();
        report.remark = childSnapshot.child('Remark_ChangedPart').val();
        report.coreServiceAfterUrl = childSnapshot.child('CoreAndCavity_After_Services').val();
        report.coreServiceBeforeUrl = childSnapshot.child('CoreAndCavity_Before_Services').val();
        report.topSideAfterUrl = childSnapshot.child('TopSide_After_Services').val();
        report.topSideBeforeUrl = childSnapshot.child('TopSide_Before_Services').val();
        reportList.push(report);
      })
      ;
    }

    return reportList;
  }

  async getMouldConditionReport(): Promise<ConditionReport[]> {
    let reportList : ConditionReport[]= [];
    var snapshot = await this.db.database.ref('MouldingConditionReport').once('value');
    if (snapshot.exists()) {
      snapshot.forEach(childSnapshot => {
        const report = new ConditionReport();
        report.createdBy = childSnapshot.child('Create_by').val();
        report.createdDate = new Date(childSnapshot.child('Create_date').val());
        report.mouldNumber = childSnapshot.child('Mould_Number').val();
        report.mouldTemperature = childSnapshot.child('Mould_Temperature').val();
        report.partNumber = childSnapshot.child('Part_Number').val();
        report.machineNumber = childSnapshot.child('Machine_Number').val();
        report.f10SettingUrl = childSnapshot.child('F10_FAST_SET_SETTING').val();
        report.f9SettingUrl = childSnapshot.child('F9_OTHER_SETTING_FUNCTION_TIME').val();
        report.f1RecordUrl = childSnapshot.child('F1_MODIFY_RECORD_CYCLE_TIME').val();
        reportList.push(report);
      })
      ;
    }
    return reportList;
  }




}
