import { PORawDB_controller } from 'src/app/Services/DB_Controller/PORawMaterialDB_controller';
import { ConfirmationDialogComponent } from './../../Shared/confirmation-dialog/confirmation-dialog.component';
import { Component, OnChanges, NgZone, OnInit } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { PartDB_controller } from '../../Services/DB_Controller/PartDB_controller';
import { Machine, RawMaterialUsed, ScheduleTracker } from '../../Services/Object_Classes/Machine/Machine';
import { HttpClient } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/auth';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { DOCUMENT } from '@angular/common';
import { Inject } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FinishDialogComponent } from 'src/app/Shared/finish-dialog/finish.component';
import { AlignDialogComponent } from 'src/app/Shared/align-dialog/align-dialog.component';
import { PinDialogComponent } from 'src/app/Shared/pin-dialog/pin-dialog.component';
import { DetailsScheduleComponent } from './PopUpSchedule/details-schedule/details-schedule.component';
import { AngularFireStorage } from '@angular/fire/storage';
import { NgxSpinnerService } from 'ngx-spinner';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { AddEventComponent } from '../scheduling/add-event/add-event.component';
import { AddSingleComponent } from '../scheduling/add-single-event/add-single-event.component';

@Component({
  selector: 'app-schedule',
  templateUrl: './schedule.component.html',
  styleUrls: ['./schedule.component.css'],
})


export class ScheduleComponent implements OnInit {
  Machine_No: string;
  now: number;
  is: any;
  subscribe: any;
  CodeSubmitted_flag: Boolean = false;
  Display_flag: Boolean = false;
  Button_flag: Boolean = false;
  AlertTimer: any;
  AlertCountDown: String;
  machinedb_Controller = new MachineDB_controller(this.db);
  partdb_Controller = new PartDB_controller(this.db, this.storage, this.firestore);
  poRawdb_Controller = new PORawDB_controller(this.db, this.firestore);

  flaskServertimerInterval: any;
  DatetimerInterval: any;
  ViewUpcoming = false;
  iss: any;
  machineFailureStatus = true;
  Presetting_flag: Boolean = false;
  Machine: Machine = new Machine();
  category = "SELECT ONE";
  Categories = ["All"]
  InterruptCodeListShowing = [];
  InterruptCodeList = []

  code: any;
  elem;
  email: any;
  oeeCalculate: any;

  machineInfo = {
    Name: '',
    Status: '',
    Machine_Code: '',
    currentSchedule: null,
    queueSchedule: null
  };
  constructor(
    private db: AngularFireDatabase,
    private angularFireAuth: AngularFireAuth,
    private storage: AngularFireStorage,
    private httpClient: HttpClient,
    private dialog: MatDialog,
    private zone: NgZone,
    private router: Router,
    private toast: ToastrService,
    private spinner: NgxSpinnerService,
    private dateFormat: DateFormatService,
    private firestore: AngularFirestore,
    @Inject(DOCUMENT) private document: any
  ) {

    this.spinner.show();
    this.firestore.collection('InterruptCodes').get().forEach(interruptCode => {
      if (!interruptCode.empty) {
        interruptCode.forEach(code => {
          this.InterruptCodeList.push(code.data());
        })
      }
    })
    this.angularFireAuth.authState.subscribe(auth => {
      this.email = auth.email;
    });
    this.getFlaskServerStatus();
    this.flaskServertimerInterval = setInterval(() => {
      //this.getMachineFailureStatus();
      this.getFlaskServerStatus();
    }, 5000)

    this.DatetimerInterval = setInterval(() => {
      this.now = Date.now();
    }, 1000)

    this.subscribe = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.subscribe.unsubscribe();
      } else {
        this.subscribe.unsubscribe();
        this.firestore.collection('Users').doc(authState.uid).get().forEach(user => {
          if (user.exists) {
            this.Machine_No = user.data().Machine;
            if (this.Machine_No) {
              this.getMachineInfo();
            } else {
              this.toast.warning('Please login again')
              this.router.navigate(['/login']);
            }
          }
        });
      }
    })
  }

  show() {
    this.ViewUpcoming = !this.ViewUpcoming;
  }

  sub: any;
  sub2: any;

  getMachineInfo() {
    this.firestore.collection('Machines').doc(this.Machine_No).get().forEach(mac => {
      if (mac.exists) {
        this.machineInfo = {
          Name: mac.data().Name,
          Status: mac.data().Status,
          Machine_Code: mac.data().Machine_Code,
          currentSchedule: null,
          queueSchedule: null
        }
        this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Current').get().forEach(schedules => {
          schedules.forEach(s => {
            var schedule = { id: s.id, ...s.data(), startDate: s.data().startDate.toDate(), endDate: s.data().endDate.toDate() };
            this.machineInfo.currentSchedule = schedule;
            this.sub = this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Current').doc(s.id).snapshotChanges().forEach(snap => {
              let data = snap.payload.data() as any;
              var info = { id: s.id, ...data, startDate: s.data().startDate.toDate(), endDate: s.data().endDate.toDate() };
              this.machineInfo.currentSchedule = info;
              if (this.machineInfo.currentSchedule.status === 'Stopped')
                this.getFlaskServerStatus();
            })
          })

          this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Queue', ref => ref.orderBy("startDate", "asc")).snapshotChanges().forEach(schedules => {

            schedules.forEach(s => {
              var schedule = { id: s.payload.doc.id, ...s.payload.doc.data(), startDate: s.payload.doc.data().startDate.toDate(), endDate: s.payload.doc.data().endDate.toDate() };
              if (this.machineInfo.queueSchedule) {
                let date = this.machineInfo.queueSchedule.startDate;
                if (s.payload.doc.data().startDate.toDate().getTime() < date.getTime()) {
                  this.machineInfo.queueSchedule = schedule;
                }
              } else {
                this.machineInfo.queueSchedule = schedule;
              }
            })
          })

        })
      }

      this.firestore.collection('Machines').doc(this.Machine_No).snapshotChanges().forEach(machine => {
        var payload = machine.payload.data() as any
        this.machineInfo.Machine_Code = payload.Machine_Code;
        this.machineInfo.Status = payload.Status;
        this.machineInfo.Name = payload.Name;
      })
    }).finally(() => {
      this.spinner.hide()
    })
  }

  SelectCat(cat) {
    this.category = cat;
    this.InterruptCodeListShowing = this.InterruptCodeList.filter(x => x.category === cat);
  }

  getFlaskServerStatus() {

    this.httpClient.post('http://127.0.0.1:5002/handshaking', { responseType: 'text' }).subscribe(
      response => {
        var splitted_response = response.toString().split("@@@");
        //False = machine without failure
        //True = machine with failure
        var machineFailureStatus = (splitted_response[1] == "true")

        if (this.machineInfo.Name) {
          let Stopped_Schedule = this.machineInfo.currentSchedule ? (this.machineInfo.currentSchedule.status === 'Stopped' ? this.machineInfo.currentSchedule : null) : null;
          let InProgress_Schedule = this.machineInfo.currentSchedule ? (this.machineInfo.currentSchedule.status === 'In Progress' ? this.machineInfo.currentSchedule : null) : null;

          if (splitted_response[0].match("Thread is stopped") || splitted_response[0].match("Thread is not exist")) {
            if (InProgress_Schedule != null) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.machineInfo.Status,
                code: this.machineInfo.Machine_Code,
                po: this.machineInfo.currentSchedule.poNo,
                scheduleNo: this.machineInfo.currentSchedule.jobOrderNo,
                scheduleId: this.machineInfo.currentSchedule.id,
                action: "Update Schedule Status to Stop due to thread is stopped or not exist"
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);
              this.firestore.collection("Machines").doc(this.Machine_No).collection("Schedule_Current").doc(this.machineInfo.currentSchedule.id).update({ status: 'Stopped' });
            }

            if (Stopped_Schedule != null) {
              if (this.machineInfo.Name && this.machineInfo.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;

              } else if (this.machineInfo.Name && !machineFailureStatus) {
                this.Active_Schedule_Status = true;
                this.Active_Schedule_Status = true;

                this.showToasterSuccess("The server is ready to start the task");
                this.Display_flag = false;
              } else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                this.Active_Schedule_Status = false;
              }
            } else {
              let Waiting_Schedule = this.machineInfo.queueSchedule;
              if (Waiting_Schedule != null && !machineFailureStatus) {
                this.Active_Schedule_Status = true;
              } else if (machineFailureStatus) {
                this.showToasterMachineWarning();
                this.Active_Schedule_Status = false;
              }
            }

          } else if (splitted_response[0].match("Thread is running")) {

            if (Stopped_Schedule != null) {
              if (this.machineInfo.Name && this.machineInfo.Machine_Code.match('-')) {
                this.showToasterWarning();
                this.Display_flag = true;
                this.Active_Schedule_Status = false;
              } else {
                this.Display_flag = false;
                this.Active_Schedule_Status = true;
              }
            } else {
              this.Display_flag = false;
            }
          }
          this.zone.run(async () => { this.machineInfo, this.spinner.hide(); })
        }
      },
      error => {
        this.zone.run(async () => { this.spinner.hide(); })
        this.showToasterError("Ensure the flask server is connected");
        this.Display_flag = false

        if (this.machineInfo.Name) {
          let InProgress_Schedule = this.machineInfo.currentSchedule ? (this.machineInfo.currentSchedule.status === 'In Progress' ? this.machineInfo.currentSchedule : null) : null;
          if (InProgress_Schedule != null) {
            const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
            const log = {
              date: new Date(),
              status: this.machineInfo.Status,
              code: this.machineInfo.Machine_Code,
              po: this.machineInfo.currentSchedule.poNo,
              scheduleNo: this.machineInfo.currentSchedule.jobOrderNo,
              scheduleId: this.machineInfo.currentSchedule.id,
              action: "Update Schedule Status to Stop due to flask server is not connected"
            }

            this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);
            this.firestore.collection("Machines").doc(this.Machine_No).collection("Schedule_Current").doc(this.machineInfo.currentSchedule.id).update({ status: 'Stopped' });
          }
          this.Active_Schedule_Status = false;

        }
      }
    );
  }

  showToasterSuccess(message: string) {
    this.toast.success(message, "", { timeOut: 2000, positionClass: 'toast-top-left' });
  }

  showToasterError(message: string) {
    this.toast.toastrConfig
    this.toast.error(message, "Error!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showRestartToasterWarning(message: string) {
    this.toast.toastrConfig
    this.toast.warning(message, "Warning!", { timeOut: 15000, positionClass: 'toast-top-center' });
  }

  showToasterWarning() {
    this.toast.warning("Enter the interrupt code for the machine stopped", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  showToasterMachineWarning() {
    this.toast.warning("The machine failure is not resolved.", "Warning!", { timeOut: 3000, positionClass: 'toast-top-left' });
  }

  getMachineFailureStatus() {

    this.httpClient.post('http://127.0.0.1:5002/machineFailureStatus', { responseType: 'text' }).subscribe(
      response => {
        if (response.toString().match("true")) {
          this.machineFailureStatus = true
        } else {
          this.machineFailureStatus = false
        }
      },
      error => {
        this.machineFailureStatus = true
      }
    )
  }

  setCounterTally() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(AlignDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        if (Number(result)) {

          const dialogConfig = new MatDialogConfig();
          dialogConfig.autoFocus = false;
          dialogConfig.height = 'auto';
          dialogConfig.width = 'auto';
          const position = {
            top: '5%'
          };
          dialogConfig.position = position;
          dialogConfig.disableClose = true;
          dialogConfig.data = 'Align the actual quantity to ' + result + '.\nThe changes will be reflected in the next production cycle.';
          this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(data => {
            if (data) {
              const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
              const log = {
                date: new Date(),
                status: this.machineInfo.Status,
                code: this.machineInfo.Machine_Code,
                po: this.machineInfo.currentSchedule.poNo,
                scheduleNo: this.machineInfo.currentSchedule.jobOrderNo,
                scheduleId: this.machineInfo.currentSchedule.id,
                action: "Tally Quantity",
                result: result
              }
              this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);
              this.httpClient.post('http://127.0.0.1:5002/tally', (result), { responseType: 'text' }).subscribe(
                data => { this.showToasterSuccess("The value will be updated on the next production cycle") },
                error => { }
              )
            }
          })
        } else {

        }
      }
    })
  }
  personInCharge: string;
  enterPinToFinishSchedule(machineNumber, Schedule: ScheduleTracker) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;

    this.dialog.open(PinDialogComponent, dialogConfig).afterClosed().subscribe(async result => {
      if (result) {
        if (Number(result)) {
          if (result == 1212) {
            this.showToasterSuccess("Identity validated! Proceed to further action!");
            this.ScheduleFinishDialog(machineNumber, Schedule);
          } else {
            this.showToasterError("Invalid PIN number");
          }

          // var snapshot = await this.db.database.ref('Passcode').once('value');
          // var isValidPin = false;
          // if (snapshot.exists()) {
          //   if (result == snapshot.child('Passcode1').val()) {
          //     this.personInCharge = snapshot.child('Person1').val();
          //     isValidPin = true;
          //   } else if (result == snapshot.child('Passcode2').val()) {
          //     this.personInCharge = snapshot.child('Person2').val();
          //     isValidPin = true;
          //   } else if (result == snapshot.child('Passcode3').val()) {
          //     this.personInCharge = snapshot.child('Person3').val();
          //     isValidPin = true;
          //   } else if (result == snapshot.child('Passcode4').val()) {
          //     this.personInCharge = snapshot.child('Person4').val();
          //     isValidPin = true;
          //   }

          //   if (isValidPin) {
          //     this.showToasterSuccess("Identity validated! Proceed to further action!");
          //     this.ScheduleFinishDialog(machineNumber, Schedule);
          //   } else {
          //     this.showToasterError("Invalid PIN number");
          //   }
          // }
        } else {

        }
      }
    })
  }

  ScheduleFinishDialog(machineNumber, schedule) {

    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    if (parseFloat(this.calculateActualQuantity(schedule)) < schedule.productionQuantity) {
      dialogConfig.data = 'The current production quantity (' + this.calculateActualQuantity(schedule) + ') is less than the expected production quantity!';
    } else {
      dialogConfig.data = 'The current production quantity is ' + this.calculateActualQuantity(schedule);
    }

    this.dialog.open(FinishDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection('Parts', ref => ref.where('itemCode', '==', schedule.itemCode)).get().forEach(item => {
          if (item.size > 0) {
            item.forEach(part => {
              schedule.Machine_Number = this.Machine_No;
              schedule.Machine_Number = this.Machine_No;
              schedule.cycleTime = part.data().cycleTime;
              let startDate = new Date(schedule.startDate);
              let endDate = new Date();

              let totalRunTime = Math.abs(endDate.getTime() - startDate.getTime()) / 3600000;
              let totalBreakTime = schedule.totalDownTime;
              schedule.availability = (totalRunTime - totalBreakTime) / totalRunTime;
              schedule.performance = (parseFloat(this.calculateActualQuantity(schedule))) / (totalRunTime - totalBreakTime);

              const addPOModel = {
                po: schedule.poNo,
                machineChosen: this.Machine_No,
                scheduleID: schedule.id,
                availability: Number.isFinite(schedule.availability) ? schedule.availability : 0,
                performance: Number.isFinite(schedule.performance) ? schedule.performance : 0,
                counter: schedule.counter,
                pic: this.email,
              };

              const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
              const info = {
                message: addPOModel.machineChosen + ' Job End by <b>' + this.email + '</b>\n' + schedule.jobOrderNo,
                date: new Date(),
                ...addPOModel
              };

              this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
              this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);
              this.httpClient.post('http://127.0.0.1:5002/endSchedule', (schedule), { responseType: 'text' }).subscribe(
                response => {

                  this.firestore.collection("Machines").doc(this.Machine_No).update({ Status: "OFF" });
                  const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
                  const log = {
                    date: new Date(),
                    status: 'OFF',
                    code: this.machineInfo.Machine_Code,
                    po: schedule.poNo,
                    scheduleNo: schedule.jobOrderNo,
                    scheduleId: schedule.id,
                    action: "End Schedule",
                    result: addPOModel,

                  }

                  console.log(response);
                  this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);
                  if (response.toString().match("Task ended")) {
                    this.showToasterSuccess("Scheduled task completed!");

                    this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Current').doc(schedule.id).get().forEach(e => {
                      if (e.exists) {
                        this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Done').doc(schedule.id).set({
                          ...schedule,
                          status: 'Done',
                          endDate: new Date()
                        })
                        this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Current').doc(schedule.id).delete()
                        if (this.sub)
                          this.sub.unsubscribe()
                        if (this.sub2)
                          this.sub2.unsubscribe()
                        this.getMachineInfo();

                      }
                    })

                  }
                },
                error => {
                  this.showToasterError("The scheduled task unable to finish due to the flask server is not connected.");
                }
              );
            })
          }
        })

      }
    })
  }

  ngOnInit() {
    this.elem = document.getElementById('full');
  }

  ngAfterViewInit(): void {

    if (this.elem.requestFullscreen) {
      this.elem.requestFullscreen();
    } else if (this.elem.mozRequestFullScreen) {
      /* Firefox */
      this.elem.mozRequestFullScreen();
    } else if (this.elem.webkitRequestFullscreen) {
      /* Chrome, Safari and Opera */
      this.elem.webkitRequestFullscreen();
    } else if (this.elem.msRequestFullscreen) {
      /* IE/Edge */
      this.elem.msRequestFullscreen();
    }

  }

  ngOnDestroy() {
    // Will clear when component is destroyed e.g. route is navigated away from.
    clearInterval(this.DatetimerInterval);
    clearInterval(this.flaskServertimerInterval);
  }

  restartWebApp() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = { top: '5%' };

    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Close and restart the current web application';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.httpClient.post('http://127.0.0.1:5002/RestartWebApp', { responseType: 'text' }).subscribe(
          data => {
            this.showToasterSuccess("Restarting the web application!")
          },
          error => {
            this.showRestartToasterWarning("Unable to locate the flask server, please the restart server manually! \n **(Close the web app -> click the ClickMe.sh)**");
          }
        );
      }
    })
  }

  Button_Status = false;
  Active_Schedule_Status = false;
  startProcess(schedule: any) {
    this.Button_flag = true;
    this.Button_Status = true;
    this.Active_Schedule_Status = false;

    this.firestore.collection('Parts', ref => ref.where('itemCode', '==', schedule.itemCode)).get().forEach(item => {
      if (!item.empty) {
        item.forEach(part => {
          schedule.Machine_Number = this.Machine_No;
          schedule.cycleTime = part.data().cycleTime;
          if (schedule.counter == 0 && schedule.status === 'Waiting') {
            this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Current').doc(schedule.id).set({
              ...schedule,
              startDate: new Date(),
              endDate: new Date(new Date().getTime() + (schedule.endDate.getTime() - schedule.startDate.getTime()))
            }).then(e => {
              this.firestore.collection('Machines').doc(this.Machine_No).collection('Schedule_Queue').doc(schedule.id).delete().then(e => {
                this.httpClient.post('http://127.0.0.1:5002/', (schedule), { responseType: 'text' }).subscribe(
                  data => {
                    this.machineInfo.currentSchedule = schedule;
                    this.machineInfo.queueSchedule = null;

                    const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
                    const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
                    const info = {
                      message: this.Machine_No + ' started by <b>' + this.email + '</b>\n',
                      date: new Date(),
                      jobOrder: schedule.jobOrderNo
                    };
                    const status = {
                      status: "In Progress",
                      updatedBy: this.email,
                      updatedDate: new Date(),
                    }

                    this.firestore.collection('PurchaseOrders').doc(schedule.poNo).collection('Parts').doc(schedule.id).update(status)
                    this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
                    this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

                    this.firestore.collection('Machines').doc(this.Machine_No).update({ "Machine Status": "ON" })
                    const log = {
                      date: new Date(),
                      status: 'ON',
                      code: this.machineInfo.Machine_Code,
                      po: schedule.poNo,
                      scheduleNo: schedule.jobOrderNo,
                      scheduleId: schedule.id,
                      action: "Start Process"
                    }
                    this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);
                    if (this.sub)
                      this.sub.unsubscribe()
                    if (this.sub2)
                      this.sub2.unsubscribe()
                    this.getMachineInfo();
                    this.Machine_restoreLogState(this.Machine_No, schedule.id)
                    this.Button_Status = false;
                    this.Button_flag = false
                  },
                  error => {
                    this.Button_Status = false;
                    this.Button_flag = false
                  }
                )
              })
            })
          } else {
            this.httpClient.post('http://127.0.0.1:5002/', (schedule), { responseType: 'text' }).subscribe(
              data => {
                this.machineInfo.currentSchedule = schedule;
                this.machineInfo.queueSchedule = null;
                this.machineInfo.Machine_Code = '-'

                const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
                const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
                const info = {
                  message: this.Machine_No + ' started by <b>' + this.email + '</b>\n',
                  date: new Date(),
                  jobOrder: schedule.jobOrderNo
                };
                const status = {
                  status: "In Progress",
                  updatedBy: this.email,
                  updatedDate: new Date(),
                }

                this.firestore.collection('PurchaseOrders').doc(schedule.poNo).collection('Parts').doc(schedule.id).update(status)
                this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
                this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

                this.firestore.collection('Machines').doc(this.Machine_No).update({ Status: "ON", Machine_Code: '-' })
                const log = {
                  date: new Date(),
                  status: 'ON',
                  code: this.machineInfo.Machine_Code,
                  po: schedule.poNo,
                  scheduleNo: schedule.jobOrderNo,
                  scheduleId: schedule.id,
                  action: "Start Process"
                }
                this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);


                this.Machine_restoreLogState(this.Machine_No, schedule.id)

                this.Button_Status = false;
                this.Button_flag = false
              },
              error => {
                this.Button_flag = false
                this.Button_Status = false;
              }
            )
          }

        })

      } else {
        this.showToasterError("Unable to start, please find administator");
        this.Button_flag = false;
        this.Button_Status = false;
        this.Active_Schedule_Status = true;
      }
    })
  }

  Machine_restoreLogState(machineNum, scheduleID) {
    this.firestore.collection('LogFile').doc(machineNum).collection(scheduleID).get().forEach(e => {
      if (e.size > 0) {
        e.forEach(item => {
          if (item.data().Status === "UNSOLVE") {
            let logFile = {
              RestoredTime: Date.now(),
              Status: "RESOLVED"
            }
            this.firestore.collection('LogFile').doc(machineNum).collection(scheduleID).doc(item.id).update(logFile);
          }
        })
      }
    })
  }

  submitCode(code, schedule, viewValue) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Submit this interrupt code (' + viewValue + ')?';
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.getFlaskServerStatus();

        this.firestore.collection("Machines").doc(this.Machine_No).update({ Machine_Code: code })
        this.machineInfo.Machine_Code = code;
        const status = {
          status: "Stopped",
          updatedBy: this.email,
          updatedDate: new Date(),
        }
        this.firestore.collection('PurchaseOrders').doc(schedule.poNo).collection('Parts').doc(schedule.id).update(status)


        const dateFormat = this.dateFormat.convertDateIntoYearMonthDay(new Date());
        const info = {
          message: "Machine " + this.Machine_No + ' Submit code by <b>' + this.email + '</b>\n',
          date: new Date(),
          jobOrder: schedule.jobOrderNo
        };

        this.firestore.collection('MachineLog').doc(dateFormat).set({ Date: new Date() });
        this.firestore.collection('MachineLog').doc(dateFormat).collection('PO').add(info);

        const currentTime = this.dateFormat.convertDateIntoYearMonthDay(new Date())
        const log = {
          date: new Date(),
          status: 'OFF',
          code: code,
          action: "Submit Interrupt Code",
          po: schedule.poNo,
          scheduleNo: schedule.jobOrderNo,
          scheduleId: schedule.id,
        }
        this.firestore.collection('MachinePerformanceLog').doc(this.Machine_No).collection(currentTime).add(log);

        this.setMachine_Logfile(this.Machine_No, this.machineInfo.currentSchedule.id, code);
        this.CodeSubmitted_flag = true;
        this.category = "SELECT ONE";
        this.InterruptCodeListShowing = [];
      }
    })
  }

  setMachine_Logfile(machineNum, scheduleID, code) {
    let logFile = {
      StoppedTime: Date.now(),
      InterruptCode: code,
      Status: "UNSOLVE"
    };

    this.firestore.collection('LogFile').doc(machineNum).collection(scheduleID).add(logFile);

  }


  stop() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%'
    };

    this.AlertTimerFunc();
  }

  AlertTimerFunc() {
    let counter: number = 900 //seconds
    this.AlertTimer = setInterval(() => {
      if (counter > 0) {
        counter--;
        let m: number = Math.floor(counter / 60);
        let s: number = counter % 60;

        let minutes = m < 10 ? "0" + m : String(m);
        let seconds = s < 10 ? "0" + s : String(s);

        this.AlertCountDown = minutes + ":" + seconds;
      } else {
        clearInterval(this.AlertTimer);
      }
    }, 1000)
  }

  viewScheduleDetail(schedule): void {
    const dialogRefupdatePart = this.dialog.open(DetailsScheduleComponent, {
      data: schedule,
      width: '70%',
      height: '30%'
    });
  }

  getProgress(schedule) {
    return (parseFloat(this.calculateActualQuantity(schedule)) / parseFloat(schedule.productionQuantity) * 100).toFixed(2);
  }

  getTotalRunTime(schedule) {
    if (schedule.status === 'Waiting')
      return 0;
    let startDate = new Date(schedule.startDate);
    let currentDate = new Date();
    var hours = Math.abs(currentDate.getTime() - startDate.getTime()) / 36e5;
    var int_part = Math.trunc(hours);
    var min = Number((hours - int_part)) * 60

    return hours.toFixed(0) + " hr " + min.toFixed(0) + " min";
  }

  getEstTime(schedule) {
    var hours = (schedule.cycleTime * (parseFloat(schedule.counter) / parseFloat(schedule.productionQuantity))) / 3600;

    return new Date(new Date().getTime()
      + hours * 60 * 60000);

  }

  calculateActualQuantity(schedule) {
    return ((parseFloat(schedule.counter) / 360) * 0.3).toFixed(2)
  }

  calculateNumberOfWood(schedule) {
    return (((parseFloat(schedule.counter) / 360) * 0.3) / schedule.length).toFixed(0)
  }

  addSchedule() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '95%';
    dialogConfig.maxWidth = '95vw';

    const position = {
      top: '1%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = this.Machine_No
    this.dialog.open(AddSingleComponent, dialogConfig).afterClosed().subscribe(result => {
      this.spinner.show();
      if (this.sub)
        this.sub.unsubscribe()
      if (this.sub2)
        this.sub2.unsubscribe()
      this.getMachineInfo();
    });
  }

}
