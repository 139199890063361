import { Chart } from 'chart.js';
import { Part } from './../../Services/Object_Classes/Part/Part';
import { DateFormatService } from 'src/app/Services/Utilities/date-format.service';
import { ToastrService } from 'ngx-toastr';
import { Component, OnInit, NgZone, ElementRef, ViewChild } from '@angular/core';
import { MachineDB_controller } from '../../Services/DB_Controller/MachineDB_controller';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { AngularFireDatabase } from '@angular/fire/database';
import { ScheduleTracker, Machine } from '../../Services/Object_Classes/Machine/Machine';
import { ModalService } from '../_modal';
import { ChangeDetectorRef } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AngularFirestore } from '@angular/fire/firestore';
import { firestore } from 'firebase';
import { PartServices } from 'src/app/Services/Utilities/part.service';
import 'chartjs-chart-radial-gauge';
// import { OEEHistoryComponent } from './oee-history/oee-history.component';
@Component({
  selector: 'app-monitor',
  templateUrl: './monitor.component.html',
  styleUrls: ['./monitor.component.css']
})
export class MonitorComponent implements OnInit {

  InterruptCodeList = []
 
  machineList = [];
  RunningScheduleDetail: ScheduleTracker = new ScheduleTracker;
  machineDB_controller: MachineDB_controller;
  code: any;
  elem;
  gaugelist = [];
  isFetching = true;

  codeStyleList = [{
    label: 'Running',
    color: 'rgba(0,128,0,1)'
  }, {
    label: 'SM',
    color: 'rgba(139,0,0,1)'
  }, {
    label: 'MP',
    color: 'rgba(0,0,139,1)'
  }, {
    label: 'NL',
    color: 'rgba(72,61,139,1)'
  }, {
    label: 'MT',
    color: 'rgba(128,0,128,1)'
  }, {
    label: 'WP',
    color: 'rgba(139,69,19,1)'
  }, {
    label: 'SMD',
    color: 'rgba(112,128,144,1)'
  }, {
    label: 'NM',
    color: 'rgba(255,255,0,1)'
  }, {
    label: 'MP',
    color: 'rgba(255,140,0，1)'
  }, {
    label: 'MH',
    color: 'rgba(205,92,92,1)'
  }, {
    label: 'AMSU',
    color: 'rgba(47,79,79,1)'
  }, {
    label: 'AMA',
    color: 'rgba(188,143,143,1)'
  }, {
    label: 'Left',
    color: 'rgba(0,0,0,0.8)'
  },];
  previousjobOEE = [];
  // monthOEE: number[] = [];
  // noJobHours: number[] = [];

  constructor(private db: AngularFireDatabase,
    private zone: NgZone,
    private modalService: ModalService,
    private toast: ToastrService,
    private ref: ChangeDetectorRef,
    private spinner: NgxSpinnerService,
    private partSrv: PartServices,
    private firestore: AngularFirestore,
    private dateService: DateFormatService,
    private dialog: MatDialog) {

    this.machineDB_controller = new MachineDB_controller(db);
  }

  OEEView = false;
  OEEChart: Chart;

  ngOnInit() {
    // this.partSrv.currentPart.subscribe(part => this.partlist = part);
    this.setup();
    // setTimeout(() => {
    //   this.isFetching = false;
    //   setTimeout(() => {
    //     this.getProductionPerformance()

    //   }, 10000);
    //   setInterval(() => { this.getProductionPerformance() }, 1800000)

    // }, 3000);
  }

  getTime(value) {
    return (new Date(new Date().getTime() + value * 60 * 60000))
  }

  getCode(value) {
    return this.InterruptCodeList.find(i => i.value === value) ? this.InterruptCodeList.find(i => i.value === value).viewValue : 'Others';
  }

  initializeChart() {
    this.spinner.show();
    this.db.database.ref('Machine').off('value');
    let index = 0;
    for (const machine of this.machineList) {
      let color = machine.AverageOEE > 66 ? "#5fe2a0" : machine.AverageOEE > 33 ? "#ffed06" : "#f53c56"
      new Chart('oeeChart' + index, {
        type: 'radialGauge',
        data: {
          labels: ["OEE"],
          datasets: [{
            backgroundColor: [color],
            borderWidth: 0,
            data: [machine.AverageOEE.toFixed(2)],
          }]
        },
        options: {
          maintainAspectRatio: false,
          // The percentage of the chart that is the center area
          centerPercentage: 80,
          domain: [0, machine.AverageOEE > 100 ? machine.AverageOEE : 100],
          // center value options
          centerArea: {
            // whether to display the center text value
            displayText: true,
            // font for the center text
            fontFamily: null,
            // color of the center text
            fontColor: null,
            // the size of the center text
            fontSize: null,
            // padding around the center area
            padding: 4,
            // an image to use for the center background
            backgroundImage: null,
            // a color to use for the center background
            backgroundColor: null,
            // the text to display in the center
            // this could be a string or a callback that returns a string
            // if a callback is provided it will be called with (value, options)
            text: function (value, option) {
              return value + "%"
            },
            // the text to display beneath the text specified above
            subText: "OEE",
          }
        }
      })
      index++;
    }
    this.spinner.hide();
  }

  productionPerformance = [];
  getProductionPerformance() {
    const dateList = [];
    const date = new Date();
    const currentDate = this.dateService.convertDateIntoYearMonthDay(new Date());
    date.setHours(date.getHours() - 12);
    const dateFormat = this.dateService.convertDateIntoYearMonthDay(date);
    const i = {
      label: currentDate,
      value: date,
    }
    dateList.push(i);
    if (currentDate !== dateFormat) {
      const i = {
        label: dateFormat,
        value: date,
      }
      dateList.unshift(i)
    }
    this.productionPerformance = [];

    for (const machine of this.machineList) {
      this.productionPerformance.push([]);

      for (const date of dateList) {
        const d = firestore.Timestamp.fromDate(date.value);
        this.firestore.collection('MachinePerformanceLog').
          doc(machine.Machine_No).
          collection(date.label, ref => ref.where('date', '>=', d).
            orderBy('date', 'asc')).snapshotChanges().subscribe(listner => {
              var no = machine.Machine_No.match(/(\d+)/);
              this.productionPerformance[parseFloat(no[0]) - 1] = [];
              listner.forEach(e => {
                const info = {
                  from: new Date(e.payload.doc.data().date.toDate()),
                  to: new Date(new Date().setHours(new Date().getHours() - 24)),
                  code: e.payload.doc.data().code,
                  status: e.payload.doc.data().status,
                  machine: no[0],
                }
                if (this.productionPerformance[parseFloat(no[0]) - 1].length > 0) {
                  const temp = this.productionPerformance[parseFloat(no[0]) - 1][this.productionPerformance[parseFloat(no[0]) - 1].length - 1]

                  if (temp.to.getTime() <= info.from.getTime()) {
                    temp.to = info.from;
                    this.productionPerformance[parseFloat(no[0]) - 1][this.productionPerformance[parseFloat(no[0]) - 1].length - 1] = temp;

                    if (temp.code !== info.code || temp.status !== info.status) {
                      this.productionPerformance[parseFloat(no[0]) - 1].push(info);
                    }
                  }
                } else {
                  this.productionPerformance[parseFloat(no[0]) - 1].push(info);

                }
              })
            })
      }
    }
    this.spinner.hide();

  }

  // ngAfterViewInit(): void {
  //   if (this.elem.requestFullscreen) {
  //     this.elem.requestFullscreen();
  //   } else if (this.elem.mozRequestFullScreen) {
  //     /* Firefox */
  //     this.elem.mozRequestFullScreen();
  //   } else if (this.elem.webkitRequestFullscreen) {
  //     /* Chrome, Safari and Opera */
  //     this.elem.webkitRequestFullscreen();
  //   } else if (this.elem.msRequestFullscreen) {
  //     /* IE/Edge */
  //     this.elem.msRequestFullscreen();
  //   }
  // }


  async getPart(schedule: ScheduleTracker): Promise<void> {
    if (schedule) {
      const result = this.partlist.find(e => e.Part_No === schedule.Machine_Schedule_Part_No);
      schedule.OpeningStockPart = result.Stock_Quantity;
      schedule.PartName = result.Part_Name;
      schedule.MouldRefNo = result.Mould_Reference_No;
      schedule.PartCavityNum = result.Number_Cavity.toString();
      if ((schedule.Exp_Qty - schedule.Acc_Qty) <= 0)
        schedule.HourLeft = 0;
      else
        schedule.HourLeft = parseFloat(schedule.PredefinedCycleTime) * ((schedule.Exp_Qty - schedule.Acc_Qty) / parseFloat(schedule.PartCavityNum)) / 3600;

    }

  }
  partlist: Part[] = [];
  subsribeService: any;

  setup() {
    this.spinner.show();
    this.firestore.collection('InterruptCodes').get().forEach(interruptCode => {
      if (!interruptCode.empty) {
        interruptCode.forEach(code => {
          this.InterruptCodeList.push(code.data());
        })
      }
    })
    this.machineList = [];
    this.firestore.collection('Machines').get().forEach(machine => {
      if (machine.size > 0) {
        machine.forEach(m => {
          const machine = { ...m.data(), AverageP:100, AverageQ: 100, AverageA: 100, AverageOEE: 100, schedules: [] };
          this.machineList.push(machine);
          this.firestore.collection('Machines').doc(m.id).collection('Schedule_Current').get().forEach(schedules => {
            schedules.forEach(s => {
              var schedule = { id: s.id, payload: s.data() };
              machine.schedules.push(schedule);
              this.firestore.collection('Machines').doc(m.id).collection('Schedule_Current').doc(s.id).snapshotChanges().forEach(snap => {
                var r = machine.schedules.find(e => e.id === snap.payload.id)
                r.payload = snap.payload.data();
              })
            })

            this.firestore.collection('Machines').doc(m.id).collection('Schedule_Queue', ref => ref.orderBy("startDate", "asc").limit(1)).get().forEach(schedules => {
              schedules.forEach(s => {
                var schedule = { id: s.id, payload: { ...s.data(), startDate: s.data().startDate.toDate(), endDate: s.data().endDate.toDate() } };
                machine.schedules.push(schedule);
              })
            })

          })
          
        })
        setTimeout(() => {
          this.initializeChart();
        }, 500);
      }
    })
    this.spinner.hide();


  }

  compareDate(a, b, isAsc: boolean) {
    a = new Date(a);
    b = new Date(b);
    return (a > b ? -1 : a < b ? 1 : 0) * (isAsc ? -1 : 1);
  }

  getSortedScheduleList(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker[] {
    let SortedScheduleList: ScheduleTracker[] = [];

    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Schdule_TrackList[i].Button_Status = false;
        Schdule_TrackList[i].Active_Schedule_Status = false;
        SortedScheduleList.push(Schdule_TrackList[i]);
      }
    }


    if (SortedScheduleList.length > 0) {
      SortedScheduleList.sort((a, b) => this.compareDate(new Date(a.Machine_Schedule_Start_Date), new Date(b.Machine_Schedule_Start_Date), true));
    }

    let InProgress_Schedule: ScheduleTracker;
    InProgress_Schedule = this.getInProgressSchedule(Schdule_TrackList)

    let Stopped_Schedule: ScheduleTracker;
    Stopped_Schedule = this.getStoppedSchedule(Schdule_TrackList)

    if (InProgress_Schedule != null) {
      InProgress_Schedule.Button_Status = false;
      InProgress_Schedule.Active_Schedule_Status = false
      SortedScheduleList.unshift(InProgress_Schedule)
    } else if (Stopped_Schedule != null) {
      Stopped_Schedule.Button_Status = false;
      Stopped_Schedule.Active_Schedule_Status = true
      SortedScheduleList.unshift(Stopped_Schedule)

    } else if (SortedScheduleList.length > 0) {
      SortedScheduleList[0].Active_Schedule_Status = true
    }


    return SortedScheduleList;
  }
  getWaitingSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Waiting_ScheduleList: ScheduleTracker[] = [];
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Waiting")) {
        Waiting_ScheduleList.push(Schdule_TrackList[i])
      }
    }

    if (Waiting_ScheduleList.length > 0) {
      let waiting_schedule: ScheduleTracker = Waiting_ScheduleList[0];
      for (var j = 1; j < Waiting_ScheduleList.length; j++) {
        if (waiting_schedule.Machine_Schedule_Start_Date > Waiting_ScheduleList[j].Machine_Schedule_Start_Date) {
          waiting_schedule = Waiting_ScheduleList[j]
        }
      }
      return waiting_schedule;
    } else {
      return null;
    }
  }

  getInProgressSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => this.compareDate(new Date(a.Machine_Schedule_Start_Date), new Date(b.Machine_Schedule_Start_Date), true));
    }
    let InProgress_Schedule: ScheduleTracker;
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("In Progress")) {

        InProgress_Schedule = Schdule_TrackList[i]
        return InProgress_Schedule
      }
    }
    return null;
  }

  getStoppedSchedule(Schdule_TrackList: ScheduleTracker[]): ScheduleTracker {
    let Stopped_Schedule: ScheduleTracker;

    if (Schdule_TrackList.length > 0) {
      Schdule_TrackList.sort((a, b) => this.compareDate(new Date(a.Machine_Schedule_Start_Date), new Date(b.Machine_Schedule_Start_Date), true));
    }
    for (var i = 0; i < Schdule_TrackList.length; i++) {
      if (Schdule_TrackList[i].Machine_Schedule_Status && Schdule_TrackList[i].Machine_Schedule_Status.match("Stopped")) {
        Stopped_Schedule = Schdule_TrackList[i]
        return Stopped_Schedule
      }
    }
    return null;
  }

  getColor(machine) {
    if (machine.schedules.length > 0) {
      if (machine.Status.match('ON') && machine.Machine_Code.match('-') && machine.schedules[0].payload.status === 'In Progress') {
        return 'running';
      }else if (machine.Status.match('ON') && machine.Machine_Code.match('-') && machine.schedules[0].payload.status === 'Stopped'){
        return 'warning blinkingBackground';
      }else{
        return 'off';
      }
    } else {
      if (machine.Status.match('ON') && machine.Machine_Code.match('-')) {
        return 'warning blinkingBackground';
      } else if (machine.Status.match('OFF') && machine.Machine_Code.match('-')) {
        return 'off';
      } else {
        return 'error';
      }
    }
  }

  Nan(value) {
    if (isNaN(value))
      return 0
    else
      return value
  }

  getStatus(value) {
    if (value === 'ON')
      return 'On-Color'
    else
      return 'Off-Color'
  }
  returnNumber(value) {
    return parseInt(value);
  }

  CutString(value: string) {
    return value.length > 14 ? '<br>' : ''
  }

  
  getOEEColor(value) {
    if (value > 66) {
      return "#5fe2a0";
    } else if (value > 33) {
      return "#ffed06"
    } else {
      return "#f53c56"
    }
  }


}
