import { PartServices } from './../../../Services/Utilities/part.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserInfoService } from './../../../Services/Utilities/user-info.service';
import { MatDialog, MatDialogConfig } from '@angular/material';
import { Component, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { MediaMatcher } from '@angular/cdk/layout';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';
import { AccountSettingsComponent } from '../../account-settings/account-settings.component';
import { PartDB_controller } from 'src/app/Services/DB_Controller/PartDB_controller';
import { AngularFireStorage } from '@angular/fire/storage';
import { ConfirmationDialogComponent } from 'src/app/Shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnDestroy {

  mobileQuery: MediaQueryList;
  private mobileQueryListener: () => void;
  today = Date.now();
  disabledNavigation = false;
  role: string;
  acccessRight: any[] = [];
  expand = false;
  constructor(
    changeDetectorRef: ChangeDetectorRef,
    media: MediaMatcher,
    private router: Router,
    private angularFireAuth: AngularFireAuth,
    private dialog: MatDialog,
    private toast: ToastrService,
    private storage: AngularFireStorage,
    private spinner: NgxSpinnerService,
    private userinfoSrv: UserInfoService,
    private partSrv: PartServices,
    private fireStore: AngularFirestore,


  ) {

    this.mobileQuery = media.matchMedia('(max-width: 800px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mobileQuery.addListener(this.mobileQueryListener);
    const service = this.angularFireAuth.authState.subscribe(async authState => {
      if (authState === null) {
        this.toast.warning('Session expired please login again')
        this.router.navigate(['/login']);
        service.unsubscribe()
      } else {
        await this.fireStore.collection("Users").doc(authState.uid).get().forEach(doc => {
          if (doc.exists) {
            const role = doc.data().Role;
            this.userinfoSrv.SetRole(role)
            const accessRights = []
            if(role === 'operator'){
              this.disabledNavigation = true;
              this.router.navigate(['home/operator']);
              service.unsubscribe()
              return;
            }
            else if (role !== 'Admin') {
              doc.data().Access.forEach(e => {
                const info = {
                  module: e.key,
                  right: e.val(),
                }
                accessRights.push(info);
              })
            }
            this.userinfoSrv.setAccessRight(accessRights);
            this.spinner.hide();

            if (role === 'Admin') {
              if (this.router.url === '/home') {
                this.router.navigate(['/home/MachineMonitoring'])
              }
            } else {
              if (this.router.url === '/home') {
                this.router.navigate(['/home/' + accessRights[0].module.replaceAll(' ', '')])
              } else {
                const url = this.router.url.split('/home/')[1];
                const result = this.acccessRight.find(a => a.module.replaceAll(' ', '') === url)
                if (!result) {
                  this.toast.warning('You have no right to access this module', 'Warning')
                  this.angularFireAuth.auth.signOut().then(e => {
                    this.router.navigate(['/login']);
                  });

                }
              }
            }

          }
        });
        service.unsubscribe()
      }
    })
    this.userinfoSrv.currentRole.subscribe(role => this.role = role);
    this.userinfoSrv.currentAccessRights.subscribe(access => this.acccessRight = access);

  }

  checkRight(module) {
    return this.userinfoSrv.checkRight(this.role, module, this.acccessRight);
  }

  ngOnDestroy(): void {
    this.mobileQuery.removeListener(this.mobileQueryListener);
  }
  logout() {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.autoFocus = false;
    dialogConfig.height = 'auto';
    dialogConfig.width = '50%';
    const position = {
      top: '5%'
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.data = 'Log out? ';

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.angularFireAuth.auth.signOut().then(()=>{
          this.userinfoSrv.SetRole('')
          this.userinfoSrv.setAccessRight([]);
          this.router.navigate(['/login']);
        });
      }
    })


  }

  sidebar() {
    this.expand = !this.expand;
  }

  accSettings() {
    this.dialog.open(AccountSettingsComponent, {
      height: '70%',
      width: '70%',
      disableClose: true
    })
  }

  getActive(value) {
    if (value === this.router.url)
      return true
    return false
  }



}

